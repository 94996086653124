/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import Login from '../pages/Login';
import ShareWith from '../component/ShareWith';
import { Helmet } from 'react-helmet';
import Comment from './comment';
import HomeTaboola from '../subcomponents/HomeTaboola';
import { useSelector } from 'react-redux';
import InjectScript from '../CustomScript';
import HelperScript from '../../utils/helper';
import ShareWithGallery from '../component/ShareWithGallery';
import ReactGA from "react-ga4";

function GalleryInnerComponents({ innerData = '' }, props) {
    const timeline = useSelector((state) => state?.PostReducer?.data?.timeline);
    const { 99: ArticleContent } = useSelector((state) => state?.widgetReducer?.data);

    const [postCommentModalView, setPostCommentModalView] = useState(false)
    const [comCount, setcomCount] = useState(0)

    const { 21: assetbaseUrl, 39: sharerMsg } = useSelector((state) => state?.SettingReducer?.data);
    const [, setGallarySubData] = useState();
    const [slidePosition, setSlidePosition] = useState(0)
    const showPostComment = () => {
        setPostCommentModalView(!postCommentModalView);
    }

    useEffect(() => {
        setGallarySubData(innerData);
        ReactGA.event({ category: 'Article Page', action: 'Gallery', label: innerData?.title });
    }, [innerData]);

    useEffect(() => {
        let y = (document.getElementById('myGalleyImg' + slidePosition).getBoundingClientRect().top + window.scrollY) - 85;
        if (slidePosition === 0) {
            y = 0;
        }
        window.scroll({
            top: y,
            behavior: 'smooth'
        });
    }, [slidePosition]);

    const setCountCom = (c) => {
        setcomCount(c);
    }

    function checkVisible(elm) {
        if (elm) {
            var rect = elm.getBoundingClientRect();
            var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight) + 85;
            return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
        } else {
            return false;
        }
    }
    const checkScrollPostion = (type) => {
        let nums = 0;
        innerData?.images?.map((element, index) => {
            if (checkVisible(document.getElementById('myGalleyImg' + (index + 1)))) {
                if (type) {
                    nums = (index);
                } else {
                    nums = (index + 2);
                }
            }
        });
        if (parseInt(innerData?.images?.length) < nums) {
            nums = 1;
        }
        if (nums < 0) {
            nums = 0;
        }
        return nums;
    }

    const scrollUpHandler = () => {
        const checkPostion = checkScrollPostion('up');
        if (checkPostion) {
            setSlidePosition(checkPostion);
        } else if (slidePosition > 0) {
            setSlidePosition(slidePosition - 1);
        }
    };

    const scrollDownHandler = () => {
        const checkPostion = checkScrollPostion();
        if (checkPostion) {
            setSlidePosition(checkPostion);
        } else if (innerData?.images?.length > slidePosition) {
            setSlidePosition(slidePosition + 1);
        }
    };

    const taboolacontent = '' +
        '' +
        '<div id="taboola-right-rail-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-rr'," +
        "container: 'taboola-right-rail-thumbnails'," +
        "placement: 'Right Rail Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '';

    const taboolarelatedcontent = '' +
        '' +
        '<div id="taboola-related-articles-widget-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-b'," +
        "container: 'taboola-related-articles-widget-thumbnails'," +
        "placement: 'Related Articles Widget Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        ' _taboola.push({flush: true});' +
        '</script>' +
        '';

        const bannerAds = ''+
        "<div id='div-gpt-ad-1670999306599-0' style='min-width: 728px; min-height: 90px;'>"+
        '<script>'+
        'window.googletag = window.googletag || {cmd: []};'+
        'googletag.cmd.push(function() {'+
        "googletag.defineSlot('/22678428348/SDPL_AP_BelowArticle_728x90', [728, 90], 'div-gpt-ad-1670999306599-0').addService(googletag.pubads());"+
        'googletag.pubads().enableSingleRequest();'+
        'googletag.enableServices();'+
        '});'+
        '</script>'+
        '<script>'+
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1670999306599-0'); });"+
        '</script>'+
        '</div>'+
        ''

        const galleryinner112 = " <div id='div-gpt-ad-1646039256024-0' style='min-width: 300px; min-height: 600px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_IP_Desktop_ATF', [300, 600], 'div-gpt-ad-1646039256024-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646039256024-0'); }); </script> </div>"
        const galleryinner113 = " <div id='div-gpt-ad-1646039283223-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_IP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646039283223-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646039283223-0'); }); </script> </div>"
        const galleryinner114 = " <div id='div-gpt-ad-1646039321335-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_IP_Desktop_BTF2_300X250', [300, 250], 'div-gpt-ad-1646039321335-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646039321335-0'); }); </script> </div>"
        const galleryinner115 = "<div id='div-gpt-ad-1646039562598-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_IP_Desktop_BTF3_300X250', [300, 250], 'div-gpt-ad-1646039562598-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646039562598-0'); }); </script> </div>"
        const galleryinner119 = "<div id='div-gpt-ad-1646039636298-0' style='min-width: 300px; min-height: 250px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_IP_Desktop_BTF4_300X250', [300, 250], 'div-gpt-ad-1646039636298-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646039636298-0'); }); </script> </div>"
        const galleryinner120 = " <div id='div-gpt-ad-1646039665433-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_IP_Desktop_BTF5_300X250', [300, 250], 'div-gpt-ad-1646039665433-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646039665433-0'); }); </script> </div>"
        const galleryinner121 = " <div id='div-gpt-ad-1646039699895-0' style='min-width: 300px; min-height: 250px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_IP_Desktop_BTF6_300X250', [300, 250], 'div-gpt-ad-1646039699895-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646039699895-0'); }); </script> </div>"
        // const galleryinner122 = "<div id='div-gpt-ad-1646039217085-0' style='min-width: 160px; min-height: 600px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_IP_Desktop_ATF_160X600', [160, 600], 'div-gpt-ad-1646039217085-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646039217085-0'); }); </script> </div>"
        const galleryinner122 = ''+
        "<div id='div-gpt-ad-1646039217085-0' style='min-width: 160px; min-height: 600px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_IP_Desktop_ATF_160X600', [160, 600], 'div-gpt-ad-1646039217085-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646039217085-0'); }); </script> </div>"+
            // '<div id="gpt-passback">'+
            // '<script>'+
            // 'window.googletag = window.googletag || {cmd: []};'+
            // 'googletag.cmd.push(function() {'+
            // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_160x600', [160, 600], 'gpt-passback').addService(googletag.pubads());"+
            // 'googletag.enableServices();'+
            // "googletag.display('gpt-passback');"+
            // '});'+
            // '</script>'+
            // '</div>'+
            '';

        const galleryinner213 = " <div id='div-gpt-ad-1646389027214-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_IP_Desktop_ATF_300x250', [300, 250], 'div-gpt-ad-1646389027214-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646389027214-0'); }); </script> </div>"
        // const galleryinner214 = "  <div id='div-gpt-ad-1646389062493-0' style='min-width: 300px; min-height: 50px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_IP_Desktop_BTF1_300X100', [[320, 50], [300, 100]], 'div-gpt-ad-1646389062493-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646389062493-0'); }); </script> </div>"
        const galleryinner214 = ""+
        "  <div id='div-gpt-ad-1646389062493-0' style='min-width: 300px; min-height: 50px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_IP_Desktop_BTF1_300X100', [[320, 50], [300, 100]], 'div-gpt-ad-1646389062493-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646389062493-0'); }); </script> </div>"+
            // ' <div id="gpt-passback">'+
            // '<script>'+
            // 'window.googletag = window.googletag || {cmd: []};'+
            // 'googletag.cmd.push(function() {'+
            // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x100', [300, 100], 'gpt-passback').addService(googletag.pubads());"+
            // 'googletag.enableServices();'+
            // "googletag.display('gpt-passback');"+
            // '});'+
            //     '</script>'+
            // '</div>'+
            ''
        const galleryinner215 = " <div id='div-gpt-ad-1646389110026-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_IP_Desktop_BTF2_300X250', [300, 250], 'div-gpt-ad-1646389110026-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646389110026-0'); }); </script> </div>"
        // const galleryinner216 = "<div id='div-gpt-ad-1646389135212-0' style='min-width: 300px; min-height: 50px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_IP_Desktop_BTF3_300X100', [[300, 100], [320, 50]], 'div-gpt-ad-1646389135212-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646389135212-0'); }); </script> </div>"
        const galleryinner216 = "" +
        "<div id='div-gpt-ad-1646389135212-0' style='min-width: 300px; min-height: 50px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_IP_Desktop_BTF3_300X100', [[300, 100], [320, 50]], 'div-gpt-ad-1646389135212-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646389135212-0'); }); </script> </div>"+
            // ' <div id="gpt-passback">'+
            // '<script>'+
            // 'window.googletag = window.googletag || {cmd: []};'+
            // 'googletag.cmd.push(function() {'+
            // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x100', [300, 100], 'gpt-passback').addService(googletag.pubads());"+
            // 'googletag.enableServices();'+
            // "googletag.display('gpt-passback');"+
            // '});'+
            // '</script>'+
            // '</div>'+
            '';
        const galleryinner217 = " <div id='div-gpt-ad-1646388873872-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388873872-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388873872-0'); }); </script> </div>"
        const galleryinner218 = " <div id='div-gpt-ad-1646388873872-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388873872-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388873872-0'); }); </script> </div>"

        const galleryinner219 = " <div id='div-gpt-ad-1646388873872-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388873872-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388873872-0'); }); </script> </div>"
        const galleryinner220 = " <div id='div-gpt-ad-1646388873872-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388873872-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388873872-0'); }); </script> </div>"
        const galleryinner261 = " <div id='div-gpt-ad-1646388873872-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388873872-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388873872-0'); }); </script> </div>"
        

    return (
        <>
            <section className="GalleryInner" id='myGalleyImg0'>
                <Helmet>
                    <title>{innerData?.seo_title} | Sandesh</title>
                    <meta name="title" content={innerData?.seo_title} />
                    <meta name="description" content={innerData?.description} />
                    <link rel="canonical" href={window.location.href} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content={innerData?.seo_title} />
                    <meta property="og:description" content={innerData?.description} />
                    <meta property="og:image" content={`${assetbaseUrl?.value}${innerData?.media}`} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location.href} />
                    <meta property="twitter:title" content={innerData?.seo_title} />
                    <meta property="twitter:description" content={innerData?.description} />
                    <meta property="twitter:image" content={`${assetbaseUrl?.value}${innerData?.media}`} />
                </Helmet>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-1    ">
                            <div className="left-icon-fixed displaynone">
                                <ShareWithGallery key={window.location.href} url={window.location.href} title={document.title} dataid={innerData?.id} />
                                <div className="left-icon-fixed-up-down mt-5">
                                    <p onClick={scrollUpHandler} className='left-icon-border mt-4'><i className="fas fa-chevron-up left-fixed-icon-border"></i></p>
                                    <p className='left-icon-border' onClick={scrollDownHandler}><i className="fas fa-chevron-down left-fixed-icon-border"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="shadow p-3 mb-3 bg-white rounded">
                                <p className='gallery-head-title'>{innerData?.title}</p>
                                <div className='post-content'>
                                    {/* <InjectScript script={innerData?.content?.replace('[[$ad]]', ArticleContent?.[0]?.script)} /> */}
                                    {ArticleContent?.[0]?.script ?
                                        <InjectScript script={innerData?.content?.replace('[[$ad]]', ArticleContent?.[0]?.script)
                                            .replace('[[$googlead]]', ' ')
                                            .replace('[[$alsoread]]', ' ')
                                        } />
                                        :
                                        <InjectScript script={
                                            innerData?.content?.replace('[[$ad]]', '')
                                                .replace('[[$googlead]]', ' ')
                                                .replace('[[$alsoread]]', ' ')
                                        } />
                                    }
                                </div>
                            </div>
                            {innerData && Object.keys(innerData)?.map((els) => {
                                return els === 'images' ? (
                                    innerData[els]?.map((element, index) => (
                                        <div className="card mb-3 scrollValue" id={`myGalleyImg${(index + 1)}`}>
                                            <span className="sticky-num">{index + 1}<em>/{innerData[els]?.length}</em></span>
                                            <img className="card-img-tops" src={`${assetbaseUrl?.value}${element?.media}`} alt="" />
                                        </div>
                                    ))
                                ) : ''
                            })}
                        </div>
                        <div className="col-md-3">
                            <div className="my-2">
                                <div className="ad-section">
                                    <HelperScript argSmall={213} argLarge={112} />
                                    {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={galleryinner213} />
                                    :
                                    <InjectScript script={galleryinner112} />
                                } */}
                                </div>
                                {/* } */}
                            </div>
                            <div className="my-2">
                                <div className="ad-section">
                                    <HelperScript argSmall={214} argLarge={113} />
                                    {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={galleryinner214} />
                                    :
                                    <InjectScript script={galleryinner113} />
                                } */}
                                </div>
                            </div>
                            <div className="my-2">
                                <div className="ad-section">
                                    <HelperScript argSmall={215} argLarge={114} />
                                    {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={galleryinner215} />
                                    :
                                    <InjectScript script={galleryinner114} />
                                } */}
                                </div>
                            </div>
                            <div className="my-2">
                                <div className="ad-section">
                                    <HelperScript argSmall={216} argLarge={115} />
                                    {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={galleryinner216} />
                                    :
                                    <InjectScript script={galleryinner115} />
                                } */}
                                </div>
                            </div>
                            <div className="my-2">
                                <div className="ad-section">
                                    <HelperScript argSmall={217} argLarge={119} />
                                    {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={galleryinner217} />
                                    :
                                    <InjectScript script={galleryinner119} />
                                } */}
                                </div>
                            </div>
                            <div className="my-2">
                                <div className="ad-section">
                                    <HelperScript argSmall={218} argLarge={120} />
                                    {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={galleryinner218} />
                                    :
                                    <InjectScript script={galleryinner120} />
                                } */}
                                </div>
                            </div>
                            <div className="my-2">
                                <div className="ad-section">
                                    <HelperScript argSmall={219} argLarge={121} />
                                    {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={galleryinner219} />
                                    :
                                    <InjectScript script={galleryinner121} />
                                } */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="left-icon-fixed">
                                <div className="row">
                                    <div className="">
                                        <div className="ad-section">
                                            <HelperScript argSmall={220} argLarge={122} />
                                            {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={galleryinner220} />
                                    :
                                    <InjectScript script={galleryinner122} />
                                } */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {
                        timeline?.length > 0 && timeline?.map((els, idx) => (
                            <div className='live-blog'>
                                <div className='text-left'>
                                    <p className='live-blog-time'>{els.date}</p>
                                    <div className='live-blog-title' dangerouslySetInnerHTML={{ __html: els?.message }}></div>
                                </div>
                            </div>
                        ))
                    }

                    {/* <div className='post-sharer' dangerouslySetInnerHTML={{ __html: sharerMsg?.value }} /> */}
                    <div className={`post-sharer article-${innerData?.id}`} ><InjectScript
                                                script={
                                                    window.innerWidth > 991
                                                        ? bannerAds
                                                        : ''
                                                }
                                            />
                                            </div>
                    <div className='d-flex justify-content-end social-post'>
                        <p className='social-share-text'>Share With:</p>
                        <ShareWith key={innerData?.id} url={innerData?.url} title={innerData?.title} dataid={innerData?.id} media={innerData?.media} />
                    </div>
                    <hr className='m-0' />
                    <div className="mb-3 mt-3 text-center">
                        {
                            innerData?.comment_status === 1 &&
                            <button onClick={() => { showPostComment() }} type="button" className="btn btn-color mt-3">Post a comment {comCount > 0 ? `(${comCount})` : null}</button>
                        }
                    </div>

                    <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-9">
                            <div className="single-blog mt-3">
                                {/* <span className="badge badge-warning">Related Gallery</span> */}
                                <div className='bottom-taboola' key={window.location.href}>
                                    <InjectScript type="taboola" script={taboolarelatedcontent} key={window.location.href} />
                                </div>
                            </div>
                            <HomeTaboola key={window.location.href} />

                        </div>
                        <div className="col-md-3">
                            <div className='my-4 sidebar-taboola' key={window.location.href}>
                                <InjectScript type="taboola" script={taboolacontent} key={window.location.href} />
                                <HelperScript argLarge={261} />
                                {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={galleryinner261} />
                                    :
                                    ''
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {localStorage.getItem('access_token') ? (
                <>
                    {postCommentModalView && <Comment postCommentModalchk={showPostComment} postCommentcount={setCountCom} postId={innerData?.id} innerDataProps={innerData && innerData} />}
                </>
            ) : (
                <>
                    {postCommentModalView && <Login />}
                </>
            )}
        </>
    )
}

export default GalleryInnerComponents;
