import React from 'react';
import { useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";
import CommonCardContent from '../CommonCardContent';

function Entertainment() {
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);
    const Entertainment = useSelector((state) => state?.HomeSecondaryReducer?.data?.Entertainment);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1200, min: 991 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 991, min: 515 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 515, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <section className="trending entertainment bg-gray">
                <div className="container">
                    <span className="badge badge-danger">Entertainment</span>
                    <Link onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Entertainment', label: 'Read More' })} to="/entertainment" className="readmore">Read More</Link>

                    <div className="row trendingheight">
                        <div className="col-md-12">
                            <Carousel
                                swipeable={true}
                                draggable={false}
                                infinite={false}
                                showDots={false}
                                autoPlay={false}
                                responsive={responsive}
                                autoPlaySpeed={5000000}
                                renderArrowsWhenDisabled={true}
                                // transitionDuration={10}
                                customTransition="transform 500ms ease"
                                ssr={true}
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                className="gallery-sec"
                            >
                                {/* <div className='scrollX'> */}
                                {
                                    Entertainment?.length > 0 && Entertainment?.map((els) => {
                                        return (
                                            <CommonCardContent url={els?.url} src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} />

                                            // <div key={els?.id} className="single-blog mx-2">
                                            //     <Link to={`/${els?.url}`} style={{ width: '400px', margin: '0px 13px 0px 0px' }} className="main-blogs">
                                            //         <img src={!els?.media ? sandeshske : `${assetbaseUrl?.value}${els?.media}`} alt="" className="img-responsive" />
                                            //         {els?.post_type === 3 || els?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{els?.time}</p> : ""}
                                            //         <div className="blog-details blog-detail-w-256px">
                                            //             <p className="ellipsis">{els?.title}</p>
                                            //             <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                            //         </div>
                                            //     </Link>
                                            // </div>
                                        )
                                    })
                                }
                                {/* </div> */}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Entertainment;
