/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sandeshske from '../../assets/sandeshske.png';
import HelperScript from '../../utils/helper';
import ReactGA from "react-ga4";
import CommonCardContent from '../CommonCardContent';
import CommonHeadCardContent from '../CommonHeadCardContent';
import InjectScript from '../CustomScript';

const Sport = () => {
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);
    const Sportnewslist = useSelector((state) => state?.HomeSecondaryReducer?.data?.Sportnews);
    // const { 9: sportright } = useSelector((state) => state?.widgetReducer?.data);

    let tmp = document.createElement("DIV");
    if (Sportnewslist?.length) {
        tmp.innerHTML = Sportnewslist[0]?.content;
    }
    tmp = tmp.textContent || tmp.innerText || "";

    const aboveSportMweb =
        '' +
        "<div id='div-gpt-ad-1657892911451-0' style='min-width: 300px; min-height: 100px;'>" +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/Mweb_HP_AboveSportsNews_300X100', [300, 100], 'div-gpt-ad-1657892911451-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1657892911451-0'); });" +
        '</script>' +
        '</div>' +
        // ' <div id="gpt-passback">'+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x100', [300, 100], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        '' +
        '';

    const sportHardcode171 =
        '' +
        "<div id='div-gpt-ad-1646387655638-0' style='min-width: 300px; min-height: 250px;'>" +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/Mweb_HP_Desktop_BTF4_300X250', [300, 250], 'div-gpt-ad-1646387655638-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.pubads().disableInitialLoad();' +
        'googletag.enableServices(); ' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646387655638-0'); });" +
        '</script>' +
        '' +
        '</div>' +
        '' +
        // '<script async="async" src="https://cdn.unibots.in/headerbidding/adScriptSandesh_new.js">' +
        // ' </script>' +
        // '' +
        '';
    const sportHardcode9 =
        '' +
        "<div id='div-gpt-ad-1645342941760-0' style='min-width: 300px; min-height: 600px;'>" +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []}; ' +
        'googletag.cmd.push(function() { ' +
        "googletag.defineSlot('/22678428348/SDPL_HP_Desktop_BTF_Sports', [300, 600], 'div-gpt-ad-1645342941760-0').addService(googletag.pubads()); " +
        'googletag.pubads().enableSingleRequest(); ' +
        'googletag.pubads().disableInitialLoad(); ' +
        'googletag.enableServices(); ' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1645342941760-0'); });" +
        '</script>' +
        '</div>' +
        // '<div id="gpt-passback">'+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x600', [300, 600], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        '' +
        // '<script async="async" src="https://cdn.unibots.in/headerbidding/adScriptSandesh_new.js">' +
        // ' </script>' +
        // '' +
        '' +
        '';


    return (
        <>
            {window.innerWidth < 991 ?
                <div className='' >
                    <div className="text-center">
                        <InjectScript script={aboveSportMweb} key={aboveSportMweb} />
                    </div>
                </div>
                : ''}
            <section className="sport bg-gray trendingheight">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-9 col-xl-9 removePaddingHover">
                            <span className="badge badge-warning">Sport News</span>
                            <Link to="/sports" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Sport News', label: 'Read More' })} className="readmore">Read More</Link>
                            <div className="single-blog">
                                <div className="row cursor-pointer mb-5 m-0">
                                    {
                                        Sportnewslist?.length > 0 &&
                                        <>
                                            <CommonHeadCardContent dataClass="col-md-7 p-0" url={Sportnewslist[0]?.url} sandeshske={sandeshske} media={Sportnewslist[0]?.media} mediaimg={`${assetbaseUrl?.value}${Sportnewslist[0]?.media}`} post_type={Sportnewslist[0]?.post_type} time={Sportnewslist[0]?.time} title={Sportnewslist[0]?.title} id={Sportnewslist[0]?.id} publish_date={Sportnewslist[0]?.publish_date} />

                                            <div className="col-md-5">
                                                <div className="blog-details  bg-gray">
                                                    <Link to={`/${Sportnewslist[0]?.url}`} className="w-100">
                                                        <p style={{ fontSize: '18px', marginBottom: '6px' }}>{Sportnewslist[0]?.title}</p>
                                                        {/* <span className="blog-time"> &nbsp;</span> */}
                                                        <hr className="my-1" />

                                                        <span className="ellipsis-ten" dangerouslySetInnerHTML={{ __html: tmp.substring(0, 650) + '...' }} ></span>
                                                        <hr className="my-1" />
                                                        <span className="blog-time">Read full news</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="row">
                                    {
                                        Sportnewslist?.length > 0 && Sportnewslist?.map((els, idx) => (
                                            <>
                                                {idx > 0 && <div className="col-md-3 nospaceing">
                                                    <CommonCardContent url={els?.url} src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                    {/* <div className="single-blog">
                                                        <Link to={`/${els?.url}`} className="main-blogs">
                                                            <img src={!els?.media ? sandeshske : `${assetbaseUrl?.value}${els?.media}`} alt="" className="img-responsive" />
                                                            {els?.post_type === 3 || els?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{els?.time}</p> : ""}
                                                            <div className="blog-details">
                                                                <p className="ellipsis">{els.title}</p>
                                                                <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                            </div>
                                                        </Link>
                                                    </div> */}
                                                </div>
                                                }
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-3 col-xl-3 text-center">
                            <div className="ad-side">
                                {/* {sportright?.[0]?.script && */}
                                <div className="ad-section">
                                    {/* <HelperScript argSmall={171} argLarge={9} /> */}
                                    <InjectScript
                                        // key={Math.random()}
                                        script={window.innerWidth > 991 ? sportHardcode9 : sportHardcode171}
                                    />
                                    {/* <InjectScript script={sportright?.[0]?.script} /> */}
                                </div>
                                {/* } */}
                                {/* <iframe style={{ height: '425px', filter: "none" }} src="https://static.foxnews.com/static/orion/html/embed/vaccine-finder.html" title="ad" ></iframe> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sport;
