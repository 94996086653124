import React from 'react';
import sandeshske from '../../assets/sandeshske.png';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";
import CommonContent from '../CommonContent';
import ShareCommon from '../component/ShareCommon';
import InjectScript from '../CustomScript';

const Blogs = () => {
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);
    const Lifestylelist = useSelector((state) => state?.HomeSecondaryReducer?.data?.Lifestyle);
    const Columnistlist = useSelector((state) => state?.HomeSecondaryReducer?.data?.Columnist);
    const Businesslist = useSelector((state) => state?.HomeSecondaryReducer?.data?.Business);

    const onShareClick = (e) => {
        e.preventDefault();
        document.getElementById('common-share-dropdown').classList.remove('d-none');
        // e.stopPropagation();
        e.preventDefault();
        return false;
    }

    const belowBusinessMweb =
        '' +
        "<div id='div-gpt-ad-1657877625751-0' style='min-width: 300px; min-height: 100px;'>" +
        '<script>' +
        ' window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/Mweb_HP_BTF_BelowBusiness_300x100', [300, 100], 'div-gpt-ad-1657877625751-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1657877625751-0'); });" +
        '</script>' +
        '</div>' +
        // ' <div id="gpt-passback">'+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x100', [300, 100], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        '' +
        '';

    return (
        <>
            <section className="blogs">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-4 col-xl-4 gujarat-responsive-space-sm">
                            <div className="single-blog">
                                <span className="badge badge-info">Business</span>
                                <Link to="/business" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Business', label: 'Read More' })} className="readmore">Read More</Link>
                                {
                                    Businesslist?.length > 0 &&
                                    <>
                                        <Link to={`/${Businesslist[0]?.url}`} className="main-blogs share-blog">
                                            <div className='skeleton-sub'>
                                                <img src={!Businesslist?.[0]?.media ? sandeshske : `${assetbaseUrl?.value}${Businesslist?.[0]?.media}`} alt="" className="img-responsive" />
                                            </div>
                                            {Businesslist?.[0]?.post_type === 3 || Businesslist?.[0]?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{Businesslist?.[0]?.time}</p> : ""}
                                            <div className="blog-details">
                                                <p className="ellipsis">{Businesslist[0]?.title}</p>
                                            </div>
                                            <div class="dropleft">
                                                <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
                                                    <i class="fa fa-share-alt"></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <ShareCommon id="common-share-dropdown" url={Businesslist[0].url} media={`${assetbaseUrl?.value}${Businesslist?.[0]?.media}`} title={Businesslist[0]?.title} dataid={Businesslist[0]?.id} />
                                                </div>
                                            </div>
                                        </Link>
                                    </>
                                }
                                <div>
                                    {Businesslist?.length > 0 && Businesslist?.map((els, idx) => (
                                        <>
                                            {(idx > 0 && idx <= 3) && <div className='row col-12 p-0 m-0'>
                                                <CommonContent url={els?.url} src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} category={els?.tagline} title={els?.title} />
                                            </div>}
                                        </>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                        {window.innerWidth < 991 ?
                            <div className='w-100' >
                                <div className="text-center">
                                    <InjectScript script={belowBusinessMweb} key={belowBusinessMweb} />
                                </div>
                            </div>
                            : ''}
                        <div className="col-md-12 col-lg-4 col-xl-4 gujarat-responsive-space-sm">
                            <div className="single-blog">
                                <span className="badge badge-danger">Writers</span>
                                <Link to="/opinion" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Opinion', label: 'Read More' })} className="readmore">Read More</Link>
                                {
                                    Columnistlist?.length > 0 &&
                                    <>
                                        <Link to={`/${Columnistlist[0]?.url}`} className="main-blogs w-100 share-blog">
                                            <div className='skeleton-sub'>
                                                <img src={!Columnistlist?.[0]?.media ? sandeshske : `${assetbaseUrl?.value}${Columnistlist?.[0]?.media}`} alt="" className="img-responsive" />
                                            </div>
                                            {Columnistlist?.[0]?.post_type === 3 || Columnistlist?.[0]?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{Columnistlist?.[0]?.time}</p> : ""}
                                            <div className="blog-details">
                                                <p className="ellipsis">{Columnistlist[0]?.title}</p>
                                            </div>
                                            <div class="dropleft">
                                                <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
                                                    <i class="fa fa-share-alt"></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <ShareCommon id="common-share-dropdown" url={Businesslist[0].url} media={`${assetbaseUrl?.value}${Businesslist?.[0]?.media}`} title={Businesslist[0]?.title} dataid={Businesslist[0]?.id} />
                                                </div>
                                            </div>
                                        </Link>
                                    </>
                                }
                                {Columnistlist?.length > 0 && Columnistlist?.map((els, idx) => (
                                    <>
                                        {(idx > 0 && idx <= 3) &&
                                            <CommonContent url={els?.url} src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} category={els?.tagline} title={els?.title} />
                                        }
                                    </>
                                ))
                                }
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 col-xl-4">
                            <div className="single-blog">
                                <span className="badge badge-warning">Lifestyle</span>
                                <Link to="/lifestyle" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Lifestyle', label: 'Read More' })} className="readmore">Read More</Link>
                                {
                                    Lifestylelist?.length > 0 &&
                                    <>
                                        <Link to={`/${Lifestylelist[0]?.url}`} className="main-blogs share-blog">
                                            <div className='skeleton-sub'>
                                                <img src={!Lifestylelist?.[0]?.media ? sandeshske : `${assetbaseUrl?.value}${Lifestylelist?.[0]?.media}`} alt="" className="img-responsive" />
                                            </div>
                                            {Lifestylelist[0]?.post_type === 3 || Lifestylelist[0]?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{Lifestylelist[0]?.time}</p> : ""}
                                            <div className="blog-details">
                                                <p className="ellipsis">{Lifestylelist[0]?.title}</p>
                                            </div>
                                            <div class="dropleft">
                                                <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
                                                    <i class="fa fa-share-alt"></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <ShareCommon id="common-share-dropdown" url={Businesslist[0].url} media={`${assetbaseUrl?.value}${Businesslist?.[0]?.media}`} title={Businesslist[0]?.title} dataid={Businesslist[0]?.id} />
                                                </div>
                                            </div>
                                        </Link>
                                    </>
                                }
                                <div>
                                    {Lifestylelist?.length > 0 && Lifestylelist?.map((els, idx) => (
                                        <>
                                            {(idx > 0 && idx <= 3) && <div className='row col-12 p-0 m-0'>
                                                <CommonContent url={els?.url} src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} category={els?.tagline} title={els?.title} />
                                            </div>}
                                        </>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Blogs
