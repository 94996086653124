/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import sandeshske from '../../assets/sandeshske.png';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { gameZopFetchData } from '../../redux/actions/commonAction';
import HelperScript from '../../utils/helper'
import ReactGA from "react-ga4";
import CommonContent from '../CommonContent';
import CommonHeadCardContent from '../CommonHeadCardContent';
import InjectScript from '../CustomScript';

const World = () => {

    const dispatch = useDispatch();
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);
    const Worldlist = useSelector((state) => state?.HomeSecondaryReducer?.data?.World);
    const [, setgameZopData] = useState();
    // const { 8: worldright } = useSelector((state) => state?.widgetReducer?.data);

    useEffect(() => {
        dispatch(gameZopFetchData()).then((res) => {
            setgameZopData(res);
        });
    }, []);

    const aboveWorldMweb =
        '' +
        "<div id='div-gpt-ad-1657863978555-0' style='min-width: 300px; min-height: 100px;'>" +
        '<script>' +
        ' window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/Mweb_HP_Aboveworld_300', [300, 100], 'div-gpt-ad-1657863978555-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657863978555-0'); });" +
        '</script>' +
        '</div>' +
        // ' <div id="gpt-passback">'+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x100', [300, 100], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        '' +
        '';


    const worldHardcode170 =
        '' +
        " <div id='div-gpt-ad-1646387827925-0' style='min-width: 300px; min-height: 250px;'> " +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() { ' +
        "googletag.defineSlot('/22678428348/Mweb_HP_Desktop_BTF2_300X250', [300, 250], 'div-gpt-ad-1646387827925-0').addService(googletag.pubads()); " +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.pubads().disableInitialLoad(); ' +
        'googletag.enableServices();  ' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646387827925-0'); }); " +
        '</script>' +
        '</div>' +
        // '' +
        // '<script async="async" src="https://cdn.unibots.in/headerbidding/adScriptSandesh_new.js">' +
        // ' </script>' +
        // '' +
        '' +
        '';
    const worldHardcode8 =
        '' +
        "<div id='div-gpt-ad-1645340942727-0' style='min-width: 300px; min-height: 600px;'>" +
        '<script>' +
        ' window.googletag = window.googletag || {cmd: []}; ' +
        'googletag.cmd.push(function() {  ' +
        "googletag.defineSlot('/22678428348/SDPL_HP_Desktop_BTF_300X250', [300, 600], 'div-gpt-ad-1645340942727-0').addService(googletag.pubads());  " +
        'googletag.pubads().enableSingleRequest();  ' +
        'googletag.pubads().disableInitialLoad(); ' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        " googletag.cmd.push(function() { googletag.display('div-gpt-ad-1645340942727-0'); }); " +
        '</script>' +
        '</div>' +
        // '' +
        // '<script async="async" src="https://cdn.unibots.in/headerbidding/adScriptSandesh_new.js">' +
        // ' </script>' +
        // '' +
        '' +
        '';

        const taboolaRightWorld ="" +
        ""+
        '<div id="taboola-side-homepage-thumbnails-1x1"></div>'+
        '<script type="text/javascript">'+
        'window._taboola = window._taboola || [];'+
        '_taboola.push({'+
            "mode: 'thumbnails-home-1x1',"+
            "container: 'taboola-side-homepage-thumbnails-1x1',"+
            "placement: 'Side Homepage Thumbnails 1x1 b',"+
            "target_type: 'mix'"+
        '});'+
        '</script>' +
        '';

        const weblastworldsection = ""+
       "<div id='div-gpt-ad-1683976634522-0' style='min-width: 728px; min-height: 90px;'>" +
       '<script>' +
       ' window.googletag = window.googletag || {cmd: []}; ' +
       'googletag.cmd.push(function() {  ' +
       "googletag.defineSlot('/22678428348/SDPL_HP_BelowWorld-728x90', [728, 90], 'div-gpt-ad-1683976634522-0').addService(googletag.pubads());  " +
       'googletag.pubads().enableSingleRequest();  ' +
       'googletag.pubads().disableInitialLoad(); ' +
       'googletag.enableServices();' +
       '});' +
       '</script>' +
       '' +
       '<script>' +
       " googletag.cmd.push(function() { googletag.display('div-gpt-ad-1683976634522-0'); }); " +
       '</script>' +
       '</div>' +
       ''

    return (
        <>
            {window.innerWidth < 991 ? <div className='' >
                <div className="text-center">
                    <InjectScript script={aboveWorldMweb} key={aboveWorldMweb} />
                </div>
            </div> : ''}
            <section className="world ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                            <div className="single-blog">
                                <span className="badge badge-warning">World</span>
                                <Link to="/world" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'World', label: 'Read More' })} className="readmore">Read More</Link>
                            </div>
                            <div className="row">
                                <div className="col-md-7 col-lg-7 col-xl-7 removePaddingLeft removePaddingHover">
                                    {
                                        Worldlist?.length > 0 &&
                                        <>
                                            <CommonHeadCardContent dataClass="margin-bottom margin-bottom-guj" url={Worldlist[0]?.url} sandeshske={sandeshske} media={Worldlist[0]?.media} mediaimg={`${assetbaseUrl?.value}${Worldlist[0]?.media}`} post_type={Worldlist[0]?.post_type} time={Worldlist[0]?.time} title={Worldlist[0]?.title} id={Worldlist[0]?.id} publish_date={Worldlist[0]?.publish_date} />
                                            <CommonContent url={Worldlist[1]?.url} src={`${assetbaseUrl?.value}${Worldlist[1]?.media}`} post_type={Worldlist[1]?.post_type} time={Worldlist[1]?.time} publish_date={Worldlist[1]?.publish_date} category={Worldlist[1]?.tagline} title={Worldlist[1]?.title} />
                                            <CommonContent url={Worldlist[2]?.url} src={`${assetbaseUrl?.value}${Worldlist[2]?.media}`} post_type={Worldlist[2]?.post_type} time={Worldlist[2]?.time} publish_date={Worldlist[2]?.publish_date} category={Worldlist[2]?.tagline} title={Worldlist[2]?.title} />

                                            {/* <div className='row col-12 p-0 m-0'>
                                                <Link to={`/${Worldlist[1]?.url}`} className="related-blogs w-100">
                                                    <p className='img-16-9-blogs'>
                                                        <div className='col padding-mobile'>
                                                            {Worldlist[1]?.media === null ?
                                                                <img src="http://placehold.jp/50/cccccc/525252/800x450.png?text=Sandesh" alt="" className="img-responsive" /> :
                                                                <img src={!Worldlist[1]?.media ? sandeshske : `${assetbaseUrl?.value}${Worldlist[1]?.media}`} alt="" className="img-responsive" />
                                                            }
                                                            {Worldlist[1]?.post_type === 3 || Worldlist[1]?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{Worldlist[1]?.time}</p> : ""}
                                                            <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{Worldlist[1]?.publish_date}</p>
                                                        </div>
                                                    </p>
                                                    <div className='col-8 p-0'>

                                                        <div className="blog-details-national-new">
                                                            <span><b className="color-red">{Worldlist[1]?.tagline}</b></span>
                                                            <p className="ellipsis">{Worldlist[1]?.title}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className='row col-12 p-0 m-0'>
                                                <Link to={`/${Worldlist[2]?.url}`} className="related-blogs w-100">
                                                    <p className='img-16-9-blogs'>
                                                        <div className='col padding-mobile'>
                                                            {Worldlist[2]?.media === null ?
                                                                <img src="http://placehold.jp/50/cccccc/525252/800x450.png?text=Sandesh" alt="" className="img-responsive" /> :
                                                                <img src={!Worldlist[2]?.media ? sandeshske : `${assetbaseUrl?.value}${Worldlist[2]?.media}`} alt="" className="img-responsive" />
                                                            }
                                                            {Worldlist[2]?.post_type === 3 || Worldlist[2]?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{Worldlist[2]?.time}</p> : ""}
                                                            <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{Worldlist[2]?.publish_date}</p>
                                                        </div>
                                                    </p>
                                                    <div className='col-8 p-0'>

                                                        <div className="blog-details-national-new">
                                                            <span><b className="color-red">{Worldlist[2]?.tagline}</b></span>
                                                            <p className="ellipsis">{Worldlist[2]?.title}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div> */}
                                        </>
                                    }
                                </div>
                                <div className="col-md-5 col-lg-5 col-xl-5 removePaddingRight">
                                    {/* <div className='row col-12 p-0 m-0'>
                                        <a href="#." className="related-blogs">
                                            <p className='img-16-9-blogs'>
                                                <div className='col'>
                                                    <img src="https://i0.wp.com/images.sandesh.com/2021/12/imran-khan-f.jpg?resize=800,450" className="img-responsive" />
                                                    <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>1 કલાક પહેલા</p>
                                                </div>
                                            </p>
                                            <div className='col-8 p-0'>

                                                <div className="blog-details-national-new">
                                                    <span><b className="color-red">મહામારીનો ફફડાટ</b></span>
                                                    <p>ઇમરાન ખાનની ધમકી, રાજીનામું આપ્યા બાદ વધુ ખતરનાક થઇશ ઇમરાન ખાનની</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='row col-12 p-0 m-0'>
                                        <a href="#." className="related-blogs">
                                            <p className='img-16-9-blogs'>
                                                <div className='col'>
                                                    <img src="https://i0.wp.com/images.sandesh.com/2022/01/WHO-Chief-Sandesh.jpg?resize=800,450" className="img-responsive" />
                                                    <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>1 કલાક પહેલા</p>
                                                </div>
                                            </p>
                                            <div className='col-8 p-0'>

                                                <div className="blog-details-national-new">
                                                    <span><b className="color-red">મહામારીનો ફફડાટ</b></span>
                                                    <p>શું સાચું છે કે દુનિયામાં બધાને થશે ઓમિક્રોન? WHOએ શું કહ્યું? શું સાચું છે કે</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='row col-12 p-0 m-0'>
                                        <a href="#." className="related-blogs">
                                            <p className='img-16-9-blogs'>
                                                <div className='col'>
                                                    <img src="https://i0.wp.com/images.sandesh.com/2022/01/China-COVID-Test-Sandesh.jpg?resize=800,450" className="img-responsive" />
                                                    <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>1 કલાક પહેલા</p>
                                                </div>
                                            </p>
                                            <div className='col-8 p-0'>

                                                <div className="blog-details-national-new">
                                                    <span><b className="color-red">મહામારીનો ફફડાટ</b></span>
                                                    <p>ચીન કોરોના ટેસ્ટ માટે પ્રાઇવેટ પાર્ટમાંથી સેમ્પલ લેશે ચીન કોરોના ટેસ્ટ માટે પ્રાઇવેટ </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='row col-12 p-0 m-0'>
                                        <a href="#." className="related-blogs">
                                            <p className='img-16-9-blogs'>
                                                <div className='col'>
                                                    <img src="https://i0.wp.com/images.sandesh.com/2022/01/Russia-Ukraine-Sandesh.jpg?resize=800,450" className="img-responsive" />
                                                    <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>1 કલાક પહેલા</p>
                                                </div>
                                            </p>
                                            <div className='col-8 p-0'>

                                                <div className="blog-details-national-new">
                                                    <span><b className="color-red">મહામારીનો ફફડાટ</b></span>
                                                    <p>રશિયા આ દેશ પર હુમલો કરવા તૈયાર, અમેરિકાનું દૂતાવાસ ખાલી રશિયા આ દેશ</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='row col-12 p-0 m-0'>
                                        <a href="#." className="related-blogs">
                                            <p className='img-16-9-blogs'>
                                                <div className='col'>
                                                    <img src="https://i0.wp.com/images.sandesh.com/2022/01/Russia-Ukraine-Sandesh.jpg?resize=800,450" className="img-responsive" />
                                                    <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>1 કલાક પહેલા</p>
                                                </div>
                                            </p>
                                            <div className='col-8 p-0'>

                                                <div className="blog-details-national-new">
                                                    <span><b className="color-red">મહામારીનો ફફડાટ</b></span>
                                                    <p>રશિયા આ દેશ પર હુમલો કરવા તૈયાર, અમેરિકાનું દૂતાવાસ ખાલી રશિયા આ દેશ</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div> */}
                                    {
                                        Worldlist?.length > 0 && Worldlist?.map((els, idx) => (
                                            <>
                                                {idx > 2 &&
                                                    <CommonContent url={els?.url} src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.tagline} title={els?.title} />
                                                }
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                            {window.innerWidth > 991 ? <div className='' >
                <div className="text-center">
                    <InjectScript script={weblastworldsection} key={weblastworldsection} />
                </div>
            </div> : ''}
                        </div>
                        <div className="col-md-6 ad-side offset-md-3 col-lg-3 offset-lg-0 col-xl-3 offset-xl-0 text-center">
                            {/* {worldright?.[0]?.script && */}
                            <div className="ad-section">
                                {/* <HelperScript argSmall={170} argLarge={8} /> */}
                                <InjectScript
                                    // key={Math.random()}
                                    key={window.location.pathname}
                                    type="taboola"
                                    // script={window.innerWidth > 991 ? worldHardcode8 : worldHardcode170}
                                    script={taboolaRightWorld}
                                />
                                {/* <InjectScript script={worldright?.[0]?.script} /> */}
                            </div>
                            {/* } */}
                            {/* <a href={`${gameZopData?.data?.url}`} target='_blank' rel="noreferrer" title={`${gameZopData?.data?.text}`}>
                                <img style={{ height: '488px' }} src={`${assetbaseUrl?.value}${gameZopData?.data?.image}`} alt='' />
                            </a> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default World
