import React, { useEffect } from 'react';
const InjectScript = React.memo(({ script, type }) => {
  const divRef = React.useRef(null);

  useEffect(() => {
    if (divRef.current === null) {
      return;
    }
    // create a contextual fragment that will execute the script
    // beware of security concerns!!
    const doc = document
      .createRange()
      .createContextualFragment(script)

    // clear the div HTML, and append the doc fragment with the script 
    divRef.current.innerHTML = '';
    setTimeout(() => {
      if (divRef.current && doc) {
        divRef.current.appendChild(doc);
      }
    }, (type === 'taboola') ? 5000 : 0);
  })

  return <div key={Math.random()} ref={divRef} />
})

export default InjectScript;