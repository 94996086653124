/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// shikhar-dhawan.webp
import HomeTaboola from '../subcomponents/HomeTaboola';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { searchResult } from '../../redux/actions/searchResultAction';
import HelperScript from '../../utils/helper';
import InjectScript from '../CustomScript';

function SearchResult() {
    const search_limit = 50;
    const [InputSearchValue, setInputSearchValue] = useState('');
    const [CheckGETresult, setCheckGETresult] = useState('');
    const Childmenu = useSelector((state) => state?.ChildmenuReducer);

    // const { 126: Right } = useSelector((state) => state?.widgetReducer?.data);
    // const { 127: Leftone } = useSelector((state) => state?.widgetReducer?.data);
    // const { 128: leftsecond } = useSelector((state) => state?.widgetReducer?.data);

    const [activeTabType, setActiveTabType] = useState(0);
    const searchValue = useSelector((state) => state?.searchValue?.data);
    const { data: searchData } = useSelector((state) => state?.searchResult?.data);
    const loading = useSelector((state) => state?.searchResult?.loading)

    // const searchValue = useSelector((state) => state?.searchValue?.data);
    const { 21: assetbaseUrl } = useSelector(
        (state) => state?.SettingReducer?.data
    );

    let dispatch = useDispatch();
    useEffect(() => {
        if (searchValue) {
            setInputSearchValue(searchValue);
        }
    }, [searchValue]);
    useEffect(() => {
        if (InputSearchValue) {
            dispatch(searchResult('?type=' + activeTabType + `&keyword=${InputSearchValue}&limit=${search_limit}&page=1`))
        } else if (!InputSearchValue) {
            dispatch(searchResult('?type=' + activeTabType))
        }
    }, [activeTabType])

    const searchClickHandler = () => {
        if (InputSearchValue) {
            dispatch(searchResult('?type=' + activeTabType + `&keyword=${InputSearchValue}&limit=${search_limit}&page=1`))
        } else if (!InputSearchValue) {
            dispatch(searchResult('?type=' + activeTabType))
        }
        setCheckGETresult(1);
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // console.log('InputSearchValue', InputSearchValue);
            searchClickHandler();
        }, 1000)
        // const timer = setInterval(() => {
        //     // InputSearchValue
        //     dispatch(searchValueHandler(InputSearchValue))
        //     if (InputSearchValue) {
        //         // navigate('/search')
        //     }
        // }, 3000);
        return () => {
            // clearInterval(timer);
            clearTimeout(delayDebounceFn);
        }
    }, [InputSearchValue]);

    const handleKeyDown = (e) => {
        setCheckGETresult();
        if (e.key === "Enter") {
            searchClickHandler()
        }
    };


    const bubbleAutoSearch = (e) => {
        setInputSearchValue(e);
        setTimeout(() => {
            dispatch(searchResult('?type=' + activeTabType + `&keyword=${e}&limit=${search_limit}&page=1`))
            // searchClickHandler();
            setCheckGETresult(1);
        }, 100);
    };

    const searchafter3 =
        '' +
        "<div id='div-gpt-ad-1657965000873-0' style='min-width: 300px; min-height: 100px;'>" +
        '<script>' +
        'window.googletag = window.googletag || { cmd: [] };' +
        'googletag.cmd.push(function () {' +
        "googletag.defineSlot('/22678428348/Mweb_Searchpage_After3article_300X100', [300, 100], 'div-gpt-ad-1657965000873-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '' +
        ' <script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1657965000873-0'); });" +
        '</script>' +
        '</div>' +
        '' +
        '';

    let contentGallery = [];
    let content = [];

    searchData?.length > 0 && searchData?.map((els, idx) => {
        if ((idx + 1) % 4) {
            content.push(
                <Link to={`/${els?.url}`}>
                    <div className="row list-start mt-4 flex-md-row ">
                        <div className="col-3 p-0">
                            <div className='skeleton-sub'>
                                <img className="img-fluid" alt="" src={assetbaseUrl?.value + els?.media} key={Math.random()} />
                                {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                            </div>
                        </div>
                        <div className="col col-9">
                            <div className="post-title search-title-color blog-details">
                                <span><b className="color-red">{els?.tagline}</b></span>
                                <p className="ellipsis">{els?.title}</p>
                            </div>
                            <span className="blog-time search-title-color"><i className="far fa-clock"></i>{els?.publish_date}</span>
                        </div>
                    </div>
                </Link>
            )
        } else {
            content.push(
                window.innerWidth < 991 ?
                    <div className="text-center">
                        <InjectScript script={searchafter3} key={searchafter3} />
                    </div> : ''
            );
        }
    })

    searchData?.length > 0 && searchData?.map((els, idx) => {
        if ((idx + 1) % 4) {
            contentGallery.push(
                <div className="col-md-6">
                    <Link to={`/${els?.url}`}>
                        <div className="single-blog mx-2">
                            <img style={{ width: '100%' }} src={assetbaseUrl?.value + els?.media} className="img-responsive" alt="" />
                            <div className="blog-details">
                                <span><b className="color-red">{els?.tagline}</b></span>
                                <p className="search-title-color ellipsis">{els?.title}</p>
                                <span className="blog-time search-title-color"><i className="far fa-clock"></i>{els?.publish_date}</span>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        } else {
            contentGallery.push(
                window.innerWidth < 991 ?
                    <div className="text-center">
                        <InjectScript script={searchafter3} key={searchafter3} />
                    </div> : ''
            );
        }
    })


    return (
        <>
            <section className="result">
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-md-3 mb-3">
                            <div className="text-center left-fixed">
                                <div className='ad-side-head '>
                                    <div className="ad-side m-0">
                                        {/* {Right?.[0]?.script &&  */}
                                        <div className="ad-section">
                                            <HelperScript argSmall={221} argLarge={126} />
                                            {/* <InjectScript script={Right?.[0]?.script} /> */}
                                        </div>
                                        {/* // } */}
                                        {/* <img src={business} alt="" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 searchpage">
                            {/* <i className="fas fa-search"></i> */}
                            <div className="input-group mb-3">
                                <input type="search" placeholder='Search....' autoFocus onKeyDown={handleKeyDown} style={{ boxShadow: 'none' }} value={InputSearchValue} onChange={(e) => setInputSearchValue(e.target.value)} className='search-result-focus input form-control' />
                                <div className="input-group-append">
                                    <span onClick={searchClickHandler} style={{ cursor: 'pointer' }} className="input-group-text" id="basic-addon2"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <nav className="nav nav-pills my-3">
                                {Childmenu?.data?.length > 0 && Childmenu?.data?.map((els, idx) => (
                                    <>
                                        <a onClickCapture={() => bubbleAutoSearch(els?.tag?.name)} className={`search-result-bubbles text-sm-center`} href={`#${els?.tag?.url}`}>{els?.tag?.name} </a>
                                    </>
                                ))}

                            </nav>
                            <div style={{ opacity: (loading) ? '0.3' : 1 }}>
                                <div className="guj-metro-carousel mb-2 d-flex">
                                    <div className="nav-item bg-gray">
                                        <a className={`nav-link guj-metro-carousel-tabs ${activeTabType === 0 ? "active-search-result-tab" : ''}`} data-toggle="tab" href='#' onClick={() => setActiveTabType(0)}>All</a>
                                    </div>
                                    <div className="nav-item bg-gray">
                                        <a className={`nav-link guj-metro-carousel-tabs ${activeTabType === 1 ? "active-search-result-tab" : ''}`} data-toggle="tab" href='#' onClick={() => setActiveTabType(1)}>News</a>
                                    </div>
                                    <div className="nav-item bg-gray">
                                        <a className={`nav-link guj-metro-carousel-tabs ${activeTabType === 2 ? "active-search-result-tab" : ''}`} data-toggle="tab" href='#' onClick={() => setActiveTabType(2)}>Photos</a>
                                    </div>
                                    <div className="nav-item bg-gray">
                                        <a className={`nav-link guj-metro-carousel-tabs ${activeTabType === 3 ? "active-search-result-tab" : ''}`} data-toggle="tab" href='#' onClick={() => setActiveTabType(3)}>Videos</a>
                                    </div>

                                    {/* <li onClick={() => setActiveTabType(0)} className="nav-item">
                                    <a className="nav-link active" href="#all" data-toggle="tab">બધા</a>
                                </li>
                                <li onClick={() => setActiveTabType(1)} className="nav-item">
                                    <a className="nav-link" href="#news" data-toggle="tab">સમાચાર</a>
                                </li>
                                <li onClick={() => setActiveTabType(2)} className="nav-item">
                                    <a className="nav-link" href="#videos" data-toggle="tab">વિડિઓઝ</a>
                                </li>
                                <li onClick={() => setActiveTabType(3)} className="nav-item">
                                    <a className="nav-link" href="#photos" data-toggle="tab">ફોટા</a>
                                </li> */}

                                    {/* <li className="nav-item">
                                    <a className="nav-link" href="#webstories" data-toggle="tab">વેબ વાર્તાઓ</a>
                                </li> */}
                                </div>
                                <div className="tab-content clearfix">

                                    <Helmet>
                                        <title>Search | Sandesh </title>
                                        <meta name="description" content="Search" />
                                    </Helmet>
                                    {(CheckGETresult && (InputSearchValue.length)) ? <>
                                        <h2 className="d-none text-capitalize d-md-block">'{InputSearchValue}' - {searchData?.length} સમાચાર પરિણામો</h2>
                                    </> : <>
                                    </>}
                                    {activeTabType === 0 &&
                                        <div className="tab-pane active" id="all">
                                            {content}
                                        </div>
                                    }
                                    {activeTabType === 1 &&
                                        <div className="tab-pane active" id="news">
                                            {content}

                                            {/* {searchData?.length > 0 && searchData?.map((els) => {
                                                return (
                                                    <Link to={`/${els?.url}`}>
                                                        <div className="row list-start mt-4 flex-md-row ">
                                                            <div className="col-3 p-0">
                                                                <div className='skeleton-sub'>
                                                                    <img className="img-fluid" alt="" src={assetbaseUrl?.value + els?.media} key={Math.random()} />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                </div>
                                                            </div>
                                                            <div className="col col-9 ">
                                                                <div className="post-title search-title-color blog-details">
                                                                    <span><b className="color-red">{els?.tagline}</b></span>
                                                                    <p className="ellipsis">{els?.title}</p>
                                                                </div>
                                                                <span className="blog-time search-title-color"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })} */}
                                        </div>
                                    }
                                    {activeTabType === 2 &&
                                        <div className="tab-pane" id="videos">
                                            <div className="row substory">
                                                {/* <div>
                                                <div className="row substory">
                                                    {searchData?.length > 0 && searchData?.map((els) => {
                                                        return (
                                                            <div className="col-md-6">
                                                                <Link to={`/${els?.url}`}>
                                                                    <div className="single-blog mx-2">
                                                                        <img style={{ width: '100%' }} src={assetbaseUrl?.value + els?.media} className="img-responsive" alt="" />
                                                                        <div className="blog-details">
                                                                            <span><b className="color-red">{els?.tagline}</b></span>
                                                                            <p className="search-title-color ellipsis">{els?.title}</p>
                                                                            <span className="blog-time search-title-color"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div> */}
                                                {contentGallery}
                                            </div>
                                        </div>
                                    }
                                    {activeTabType === 3 &&
                                        <div className="tab-pane active" id="photos">
                                            {content}
                                            {/* <div>
                                                <div className="row substory">
                                                    {searchData?.length > 0 && searchData?.map((els) => {
                                                        return (<div className="col-md-6">
                                                            <Link to={`/${els?.url}`}>
                                                                <div className="single-blog mx-2">
                                                                    <a href="#." className="main-blogs">
                                                                        <div className='skeleton-sub'>
                                                                            <img style={{ width: '100%' }} src={assetbaseUrl?.value + els?.media} className="img-responsive" alt="" key={Math.random()} />
                                                                            {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                        </div>
                                                                        <div className="blog-details">
                                                                            <span><b className="color-red">{els?.tagline}</b></span>
                                                                            <p className="search-title-color ellipsis">{els?.title}</p>
                                                                            <span className="blog-time search-title-color"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        )
                                                    })}
                                                </div>
                                            </div> */}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3  mb-3">
                            <div className="text-center left-fixed">
                                <div className='ad-side-head '>
                                    <div className="ad-side m-0">
                                        {/* <img src="https://i.imgur.com/B7MT0G8.png" alt="" /> */}
                                        {/* {Leftone?.[0]?.script && */}
                                        <div className="ad-section">
                                            <HelperScript argSmall={222} argLarge={127} />
                                            {/* <InjectScript script={Leftone?.[0]?.script} /> */}
                                        </div>
                                        {/* } */}
                                    </div>
                                    <div className="ad-side mt-4">
                                        {/* {leftsecond?.[0]?.script &&  */}
                                        <div className="ad-section">
                                            <HelperScript argSmall={223} argLarge={128} />
                                            {/* <InjectScript script={leftsecond?.[0]?.script} /> */}
                                        </div>
                                        {/* } */}
                                        {/* <iframe
                                            title='Currency Converter'
                                            src='https://s.tradingview.com/embed-widget/market-overview/'
                                            width="100%"
                                            height="300"
                                            frameBorder="0"
                                            scrolling="no"
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeTaboola key={window.location.href} />
            </section>
        </>
    )
}

export default SearchResult;
