/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react'
import Astro from '../subcomponents/Astro.js';
import Gallery from '../subcomponents/Gallery.js';
import GallerySpotted from '../subcomponents/GallerySpotted.js';
import Gujarat from '../subcomponents/Gujarat.js';
// import GujaratSamagra from '../subcomponents/GujaratSamagra.js';
import Lifestyle from '../subcomponents/Lifestyle.js';
import Entertainment from '../subcomponents/Entertainment.js';
import National from '../subcomponents/National.js';
import Sport from '../subcomponents/Sport.js';
import SubHome from '../subcomponents/SubHome.js';
import Trending from '../subcomponents/Trending.js';
import Videos from '../subcomponents/Videos.js';
import World from '../subcomponents/World.js';
import HomeTaboola from '../subcomponents/HomeTaboola.js';
import Blogs from '../subcomponents/Blogs.js';
import AdTwo from '../subcomponents/AdTwo.js';
import AdTwo_video from '../subcomponents/AdTwo_Video.js';
import AdTwo_photo from '../subcomponents/AdTwo_photo.js';
import { useDispatch } from 'react-redux';
import { homePrimaryFetchData } from '../../redux/actions/homePrimaryAction';
import { homeSecondaryFetchData } from '../../redux/actions/homeSecondaryAction';
import { Helmet } from 'react-helmet';
import HelperScript from '../../utils/helper';
import Election from '../subcomponents/Election.js';


// import AdThree from '../subcomponents/AdThree.js';
// import Business from '../subcomponents/Business.js';
// import Technology from '../subcomponents/Technology.js';
// import Supplement from '../subcomponents/Supplement.js';

// import Footer from '../subcomponents/Footer.js';




const Home = () => {
  const dispatch = useDispatch();
  // const { 41: HomeHeader } = useSelector((state) => state?.widgetReducer?.data);
  // const { 45: HomeFooter } = useSelector((state) => state?.widgetReducer?.data);


  useEffect(() => {
    // const timer = setTimeout(() => {
    dispatch(homePrimaryFetchData());
    dispatch(homeSecondaryFetchData());
    // dispatch(invrelFetchData());
    // dispatch(GujaratSamagraCityFetchData());
    // dispatch(regulatoryFetchData());
    // }, 0);
    // return () => {
    //   clearInterval(timer);
    // }
  }, [])
  return (

    <>
      <Helmet>
        <title>Gujarati News | Breaking News in Gujarati | Headline in Gujarati | Sandesh</title>
      </Helmet>
      {/* {HomeHeader?.[0]?.script &&  */}
      <div className="ad-section">
        <HelperScript argSmall={188} argLarge={41} />
        {/* <InjectScript script={HomeHeader?.[0]?.script} /> */}
      </div>
      {/* } */}
      <div className='home-over-hide'>


        <SubHome />
        {/* <Election /> */}
        <Gujarat />
        {/* <GujaratSamagra/> */}
        <AdTwo />
        <National />
        <AdTwo_video />
        <Videos />
        <World />
        <GallerySpotted />        
        <Trending />
        <AdTwo_photo />
        <Gallery />
        <Entertainment />
        <Blogs />
        <Sport />
        <Astro />
        <Lifestyle />
      </div>
      {/* <ScrollButton /> */}

      {/* <AdTwo /> */}
      {/* <Supplement /> */}
      {/* <Business /> */}
      {/* <AdThree /> */}
      {/* <Technology /> */}

      <HomeTaboola key={window.location.href} />
      {/* {HomeFooter?.[0]?.script &&  */}
      <div className="ad-section">
        <HelperScript argSmall={192} argLarge={45} />
        {/* <InjectScript script={HomeFooter?.[0]?.script} /> */}
      </div>
      {/* } */}
    </>
  )
}

export default Home
