/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import sandeshske from '../../assets/sandeshske.png';
import { Helmet } from 'react-helmet';
import ShareWith from '../component/ShareWith';
import Share from '../component/Share';
import HomeTaboola from '../subcomponents/HomeTaboola';
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PostFetchData } from '../../redux/actions/postAction'
import { homePrimaryFetchData } from '../../redux/actions/homePrimaryAction';
import InjectScript from '../CustomScript';
import Comment from './comment';
import $ from 'jquery';
import Login from '../pages/Login';
import HelperScript from '../../utils/helper';
import { searchValue } from '../../redux/actions/searchValue';
import VideosInnerComponent from '../../components/pages/VideosInnerComponent';
import GalleryInnerComponents from '../../components/pages/GalleryInnerComponents';
import ReactGA from "react-ga4";
import TaboolaComponent from '@taboola/taboola-react-web'
import CommonContent from "../CommonContent";
import { open_app } from '../../redux/actions/commonAction';

const Post = (porps) => {
    // const { url } = useParams();

    const { pathname } = useLocation();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [postCommentModalView, setPostCommentModalView] = useState(false)
    const [innerData, setInnerData] = useState('')
    const [comCount, setcomCount] = useState(0)

    const postData = useSelector((state) => state?.PostReducer?.data);
    const { loading } = useSelector((state) => state?.PostReducer);
    const mostviews = useSelector((state) => state?.PostReducer?.data?.mostview);
    const timeline = useSelector((state) => state?.PostReducer?.data?.timeline);
    const innerDataRelated = useSelector((state) => state?.PostReducer?.data?.related)
    const homePrimary = useSelector((state) => state?.HomePrimaryReducer.data);
    const { 21: assetbaseUrl, 39: sharerMsg } = useSelector((state) => state?.SettingReducer?.data);

    // const { 37: firstright } = useSelector((state) => state?.widgetReducer?.data);
    // const { 38: secondright } = useSelector((state) => state?.widgetReducer?.data);
    // const { 39: thirdright } = useSelector((state) => state?.widgetReducer?.data);
    // const { 99: fourthright } = useSelector((state) => state?.widgetReducer?.data);

    // const { 42: postheader } = useSelector((state) => state?.widgetReducer?.data);
    // const { 46: PostFooter } = useSelector((state) => state?.widgetReducer?.data);

    // const { 99: ArticleContent } = useSelector((state) => state?.widgetReducer?.data);

    // const ArticleContent =''+
    // '<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>'+
    // '<script>'+
    // 'window.googletag = window.googletag || {cmd: []};'+
    // 'googletag.cmd.push(function() {'+
    // " googletag.defineSlot('/22678428348/Adsolut_1x1_articlepage', [1, 1], 'div-gpt-ad-1688719657185-0').addService(googletag.pubads());"+
    // 'googletag.pubads().enableSingleRequest();'+
    // '  googletag.enableServices();'+
    // '});'+
    // '</script>'+
    // "<div id='div-gpt-ad-1688719657185-0'>"+
    // '<script>'+
    // "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1688719657185-0'); });"+
    // '  </script>'+
    // '</div>'+
    // ''

    // const ArticleContent ='<script async id="AV64a7bfbdf9c0a48b4707ce05" type="text/javascript" src="https://tg1.playstream.media/api/adserver/spt?AV_TAGID=64a7bfbdf9c0a48b4707ce05&AV_PUBLISHERID=64a7bde7a348a28e5b017388"></script>'
    const ArticleContent = ''

    const showPostComment = () => {
        setPostCommentModalView(!postCommentModalView);
    }

    const setCountCom = (c) => {
        setcomCount(c);
    }
    useEffect(() => {
        setInnerData(null);
        const response = dispatch(PostFetchData(window.location.pathname))
        response
            .then((res) => {
                if (res?.status === false) {
                    navigate("/");
                }
            })
        // .then((res) => {
        //     if (res?.status === false) {
        //         navigate("/");
        //     }
        //     if (res?.data?.post_type === 2) {
        //         navigate('/' + res?.data?.url)
        //     }
        //     if (res?.data?.post_type === 3) {
        //         navigate('/' + res?.data?.url)
        //     }
        //     if (res?.data?.post_type === 4) {
        //         navigate('/' + res?.data?.url)
        //     }
        // })
    }, [window.location.pathname]);

    const dateFormat = (date) => {
        const newDate = new Date(date);
        return newDate.toLocaleString();
    }
    useEffect(() => {
        dispatch(homePrimaryFetchData());
    }, [])
    useEffect(() => {

        setInnerData(postData);
        setcomCount(postData?.comment_count);

        ReactGA.event({ category: 'Article Page', action: 'Post', label: postData?.title });
    }, [postData])


    const searchbyLine = () => {
        dispatch(searchValue(innerData?.byliner))
        if (innerData?.byliner) {
            navigate('/search')
        }
    }
    const onShareClick = (e) => {
        e.preventDefault();
        document.getElementById('dd').classList.remove('d-none');
        return false;
    }

    document.addEventListener('scroll', function () {
        const lastKnownScrollPosition = window.scrollY;
        const div1 = document.getElementById('postHeader');

        const c = (lastKnownScrollPosition - 200) * 100 / (document.getElementsByClassName('bottom-taboola')[0].offsetTop - window.innerHeight);
        document.getElementById('scrollwidth').style.width = c + '%';

        if (lastKnownScrollPosition > 140) {
            div1.classList.add('fix-title')
        } else {
            div1.classList.remove('fix-title')
        }
    });


    const taboolacontent = '' +
        '' +
        '<div id="taboola-right-rail-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-rr'," +
        "container: 'taboola-right-rail-thumbnails'," +
        "placement: 'Right Rail Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '';

    const taboolarelatedcontent = '' +
        '' +
        '<div id="taboola-related-articles-widget-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-b'," +
        "container: 'taboola-related-articles-widget-thumbnails'," +
        "placement: 'Related Articles Widget Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '';

    const taboolafootercontent = '' +
        '' +
        '<div id="taboola-below-article-feed-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'alternating-thumbnails-a'," +
        "container: 'taboola-below-article-feed-thumbnails'," +
        "placement: 'Below Article Feed Thumbnails'," +
        "target_type: 'mix'" +

        '});' +
        '</script>' +
        '' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        ' _taboola.push({flush: true});' +
        '</script>' +
        '';

    // const postWebBody =
    //     '' +
    //     "<div id='div-gpt-ad-1657798998776-0' style='min-width: 300px; min-height: 250px;'>" +
    //     '<script>' +
    //     'window.googletag = window.googletag || {cmd: []};' +
    //     'googletag.cmd.push(function() {' +
    //     "googletag.defineSlot('/22678428348/Desktop_AP_Belowpointers_728x90', [300, 250], 'div-gpt-ad-1657798998776-0').addService(googletag.pubads());" +
    //     'googletag.pubads().enableSingleRequest();' +
    //     'googletag.enableServices();' +
    //     '});' +
    //     '</script>' +
    //     '' +
    //     '<script>' +
    //     "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657798998776-0'); });" +
    //     '</script>' +
    //     '</div>' +
    //     '' +
    //     '';
    const postWebBody =
        '' +
        // '<div class="container">' +
        // '<div class="row text-center">' +
        // '<div class="col-md-12 text-center">' +
        // '' +
        // '<div id="taboola-article-1x1-thumbs"></div>'+
        // '<script type="text/javascript">' +
        // 'window._taboola = window._taboola || [];' +
        // '_taboola.push({' +
        // "mode: 'thumbnails-article-1x1'," +
        // "container: 'taboola-article-1x1-thumbs'," +
        // "placement: 'Article-1x1-thumbs'," +
        // "target_type: 'mix'" +

        // '});' +
        // '</script>' +
        // '' +
        // '<script type="text/javascript">' +
        // 'window._taboola = window._taboola || [];' +
        // ' _taboola.push({flush: true});' +
        // '</script>' +


        // '<script defer id="vidcrunchJS713845546" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_IBV_300x250_160922.js"></script>'+
        '<div class="col-md-6 text-center">' +
        "<div id='div-gpt-ad-1669868905240-0' style='min-width: 300px; min-height: 250px;'>" +
        '' +
        "<script>" +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669868905240-0'); });" +
        "</script>" +
        '' +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/SDPL_AP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1669868905240-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '' +
        '';



    const postMobileBody =
        '' +
        // '<script defer id="vidcrunchJS713845546" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_IBV_300x250_160922.js"></script>'+
        // "<div id='div-gpt-ad-1657799444352-0' style='min-width: 300px; min-height: 250px;'>" +
        // '<script>' +
        // 'window.googletag = window.googletag || {cmd: []};' +
        // 'googletag.cmd.push(function() {' +
        // "                googletag.defineSlot('/22678428348/Mweb_AP_Inbetweenarticle_300X250', [300, 250], 'div-gpt-ad-1657799444352-0').addService(googletag.pubads());" +
        // 'googletag.pubads().enableSingleRequest();' +
        // 'googletag.enableServices();' +
        // '});' +
        // ' </script>' +
        // '' +
        // '<script>' +
        // "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657799444352-0'); });" +
        // '</script>' +
        // '</div>' +
        // '<div id="gpt-passback">'+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        '' +
        '';

    const post184 = "<div id='div-gpt-ad-1646388497278-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_AP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388497278-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388497278-0'); }); </script> </div>"
    // const post37 = "<div id='div-gpt-ad-1646037607193-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_AP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646037607193-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037607193-0'); }); </script> </div>"
    const post37 = '' +
        "<div id='div-gpt-ad-1646037607193-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_AP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646037607193-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037607193-0'); }); </script> </div>" +
        // '<div id="gpt-passback">'+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        ''
    // const post185 = "<div id='div-gpt-ad-1646388527217-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_AP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646388527217-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388527217-0'); }); </script> </div>"
    const post185 = "" +
        "<div id='div-gpt-ad-1646388527217-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_AP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646388527217-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388527217-0'); }); </script> </div>" +
        '<div id="gpt-passback">' +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());" +
        'googletag.enableServices();' +
        "googletag.display('gpt-passback');" +
        '});' +
        '</script>' +
        '</div>' +
        '';
    // const post38 = "<div id='div-gpt-ad-1646037657342-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_AP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646037657342-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037657342-0'); }); </script> </div>"
    const post38 = '' +
        "<div id='div-gpt-ad-1646037657342-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_AP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646037657342-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037657342-0'); }); </script> </div>" +
        // '<div id="gpt-passback">'+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        ''

    const postIBVtag = '' +
        "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>" +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '<script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });" +
        '</script>' +
        '</div>' +
        ''


    // const postIBVtag = ''+
    // "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>"+
    // '<script>'+
    // 'window.googletag = window.googletag || {cmd: []};'+
    // 'googletag.cmd.push(function() {'+
    // "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());"+
    // 'googletag.pubads().enableSingleRequest();'+
    // 'googletag.enableServices();'+
    // '});'+
    // '</script>'+
    // '<script>'+
    // "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });"+
    // '</script>'+
    // '</div>'+
    // ''

    const bannerAds = '' +
        "<div id='div-gpt-ad-1670999306599-0' style='min-width: 728px; min-height: 90px;'>" +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/SDPL_AP_BelowArticle_728x90', [728, 90], 'div-gpt-ad-1670999306599-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '<script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1670999306599-0'); });" +
        '</script>' +
        '</div>' +
        ''

    // const demodemo = '<script async data-content.cms-type="video" data-content.cms-id="650295af94b94287b00df057" id="AV64b900bfb4e7a00f990a5386" type="text/javascript" src="https://tg1.aniview.com/api/adserver/spt?AV_TAGID=64b900bfb4e7a00f990a5386&AV_PUBLISHERID=64b7db400732c3e3010b9204"></script>';
    const demodemo = '<script async src="https://cdn.unibotscdn.com/clientdata/js/sandesh_head.js"></script>'+
    '<div id="div-ub-sandesh">'+
    '<script>'+
       'window.unibots = window.unibots || { cmd: [] };'+
       'unibots.cmd.push(()=>{ unibotsPlayer("sandesh"); });'+
   '</script>'+
'</div>'

    const returnfalse = async (name,category_url) => {
        await dispatch(open_app(name,category_url));
    }

    return (
        <>
            {!loading &&
                <a
                    onClick={() => returnfalse(innerData?.id, innerData?.category_url)}
                    // target='_blank'
                    rel="noreferrer"
                    href={`https://linkapp.sandesh.com/?link=https://sandesh.com${window.location.pathname}&apn=com.sandesh.news&isi=1200153846&ibi=com.sandesh.news&ifl=https://sandesh.com/d__${window.location.pathname}&efr=1`}
                    className="open_app_header"
                >OPEN APP</a>
            }
            {(innerData?.post_type === 1 && !loading) && <>
                {/* {postheader?.[0]?.script && */}
                <div className="ad-section">
                    {/* <HelperScript argSmall={189} argLarge={42} /> */}
                    {/* <InjectScript script={postheader?.[0]?.script} /> */}
                </div>
                {/* } */}
                {/* {!innerData ? navigate('/') : */}
                <section id="postId" className="post pt-0">
                    <Helmet>
                        <title>{innerData?.seo_title} | Sandesh</title>
                        <meta name="title" content={innerData?.seo_title} />
                        <meta name="description" content={innerData?.description} />
                        <link rel="canonical" href={window.location.href} />

                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:title" content={innerData?.seo_title} />
                        <meta property="og:description" content={innerData?.description} />
                        <meta property="og:image" content={`${assetbaseUrl?.value}${innerData?.media}`} />

                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content={window.location.href} />
                        <meta property="twitter:title" content={innerData?.seo_title} />
                        <meta property="twitter:description" content={innerData?.description} />
                        <meta property="twitter:image" content={`${assetbaseUrl?.value}${innerData?.media}`} />
                    </Helmet>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-9 col-xl-9 gujarat-responsive-space-sm">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div key={innerData?.id} className={`story article-${innerData?.id}`}>
                                            {/* <Header 
                                             style={{
                                                position: 'sticky',
                                                zIndex: 1,
                                               
                                                backgroundColor: "white"
                                              }}> */}
                                            <div id="postHeader" className='story-title'><span id='scrollwidth'></span>{innerData?.title}</div>
                                            <h1 className='story-title'>{innerData?.title}</h1>
                                            {/* </Header> */}
                                            <div >
                                                {innerData?.byliner_status === "1" ? <p className="byline-text"> <Link className="text-capitalize text-secondary mr-1" to={`/${innerData?.category_url}`}> {innerData?.category_name}</Link>
                                                    | {innerData?.byliner &&
                                                        <span className="curser-pointer" onClickCapture={searchbyLine}>By {innerData?.byliner} |  </span>
                                                    }
                                                    {innerData?.publish_date}</p> : ''}
                                                <div className="caption">
                                                    {/* <img src={`${assetbaseUrl?.value}${innerData?.media}`} alt="" /> */}
                                                    {/* {!innerData?.media ? */}
                                                    <img src={!innerData?.media ? sandeshske : `${assetbaseUrl?.value}${innerData?.media}`} alt="" />
                                                    {/* <InjectScript  key={Math.random()} script={demodemo} /> */}
                                                    
                                                    {/* // <img src={`${assetbaseUrl?.value}${innerData?.media}`} alt="" /> */}
                                                    {/* } */}
                                                    {innerData?.tagline ? <p className="button-hover colorspan-top-post">{innerData?.tagline}</p> : ""}
                                                    <div className='cap-post-head-business px-0 m-0 post-share row'>

                                                        <div className="dropup">
                                                            <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2"><i className="fas fa-share"></i> Share</button>
                                                            <div id="dd" className="dropup-content">
                                                                <Share id="dd" dataid={innerData?.id} url={innerData?.url} title={innerData?.title} media={innerData?.media} />
                                                                {/* <button onClick={(e) => { e.stopPropagation(); return false; }} className="dropbtn ml-2"><i className="fas fa-share"></i> Share</button>
                                                        <div className="dropup-content">
                                                            <Share url={innerData?.url} title={innerData?.title} media={innerData?.media} /> */}
                                                                {/* <a aria-label="Facebook" href={`${'https://www.facebook.com/sharer/sharer.php?display=page&u=' + encodeURIComponent(window.location.href) + '&description=' + encodeURIComponent(document.title)}`} target="_blank" rel="noreferrer" ><i className="fab fa-facebook "></i></a>
                                                            <a aria-label="Twitter" href={`${'https://twitter.com/share?url=' + encodeURIComponent(window.location.href)}`} target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
                                                            <a aria-label="Pinterest" href={`${'https://www.pinterest.com/pin/create/link/?url=' + encodeURIComponent(window.location.href) + '&media=https%3A%2F%2Fwww.infilon.com%2Fassets%2Fimages%2Fblogs%2F2022-infilon.jpeg&description=' + encodeURIComponent(document.title)}`} target="_blank" rel="noreferrer"> <i className="fab fa-pinterest"></i></a>
                                                            <a href="#." onClick={() => { navigator.clipboard.writeText(`https://sandesh.com/${innerData?.url}`) }} ><i className="fas fa-copy"></i></a> */}
                                                            </div>
                                                        </div>
                                                        {/* <a className='ml-3'><i className="far fa-comments"></i> 0 Comments </a> */}

                                                    </div>
                                                    {/* <p className='cap-post-head-news row'><div className='mr-2'><i className="far fa-comments"></i> 0 Comments </div><div className='mr-2'><i className="far fa-clock"></i> 0 February 19, 2021</div><div> <i className="fas fa-user"></i> User</div></p> */}
                                                </div>

                                            </div>
                                            {/* <div className='post-content' dangerouslySetInnerHTML={{ __html: innerData?.content }} /> */}
                                            <div className={`post-content article-${innerData?.id}`}>
                                            <InjectScript  key={Math.random()} script={demodemo} />

                                                {/* <InjectScript script={innerData?.content?.replace('[[$ad]]', ArticleContent?.[0]?.script)} /> */}
                                                <InjectScript
                                                    key={Math.random()}
                                                    script={
                                                        innerData?.content?.replace('[[$ad]]', ArticleContent?.[0]?.script ? ArticleContent?.[0]?.script : '')
                                                            .replace('[[$alsoread]]', innerData?.alsoread)
                                                            .replace('[[$googlead]]', window.innerWidth > 991 ? postWebBody : postMobileBody)
                                                    }
                                                />
                                                {/* <span dangerouslySetInnerHTML={{ __html: innerData?.alsoread }} /> */}

                                            </div>

                                            {
                                                timeline?.length > 0 && timeline?.map((els, idx) => (
                                                    <div className='live-blog'>
                                                        <div className='text-left'>
                                                            <p className='live-blog-time'>{els.date}</p>
                                                            <div className='live-blog-title' dangerouslySetInnerHTML={{ __html: els?.message }}></div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {/* <div className={`post-sharer article-${innerData?.id}`} dangerouslySetInnerHTML={{ __html: sharerMsg?.value }} /> */}
                                            <div className={`post-sharer article-${innerData?.id}`} ><InjectScript
                                                script={
                                                    window.innerWidth > 991
                                                        ? bannerAds
                                                        : ''
                                                }
                                            />
                                            </div>
                                            <div className='d-flex justify-content-end social-post'>
                                                <p className='social-share-text'>Share With:</p>
                                                <ShareWith key={innerData?.id} dataid={innerData?.id} url={innerData?.url} title={innerData?.title} media={innerData?.media} />
                                                {/* <p className='social-icon'>
                                                <a aria-label="Facebook" href={`${'https://www.facebook.com/sharer/sharer.php?display=page&u=' + encodeURIComponent(window.location.href) + '&description=' + encodeURIComponent(document.title)}`} target="_blank" rel="noreferrer" ><i className="fab fa-facebook "></i></a>
                                            </p>
                                            <p className='social-icon'>
                                                <a aria-label="Twitter" href={`${'https://twitter.com/share?url=' + encodeURIComponent(window.location.href)}`} target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
                                            </p>
                                            <p className='social-icon'>
                                                <a aria-label="Pinterest" href={`${'https://www.pinterest.com/pin/create/link/?url=' + encodeURIComponent(window.location.href) + `&media=${assetbaseUrl?.value}${innerData?.media}&description= ` + encodeURIComponent(document.title)}`} target="_blank" rel="noreferrer"> <i className="fab fa-pinterest"></i></a>
                                            </p>
                                            <p className='social-icon'>
                                                <a href="javascript:void(0)" onClick={() => { navigator.clipboard.writeText(`https://sandesh.com/${innerData?.url}`) }} ><i className="fas fa-copy"></i></a>
                                            </p> */}
                                            </div>
                                            {/* <div className='comment-section-post d-flex justify-content-between'>
                                        <b>Comment:</b>
                                        <p className='icon-bell'><a href="#."><i className="fa fa-bell"></i></a></p>
                                    </div> */}
                                            <hr className='m-0 mb-2' />
                                            {/* {window.innerWidth < 991 ?
                                            <div className='mt-3'>
                                            <InjectScript script={post184} />
                                            </div>
                                            :''
                                        } */}
                                            {/* <InjectScript
                                                script={
                                                    window.innerWidth < 991
                                                        ? postIBVtag
                                                        : ''
                                                }
                                            /> */}
                                            {window.innerWidth < 991 ? <HelperScript argSmall={184} /> : ''}
                                            <div className="mb-3 mt-3 text-center">
                                                {/* <textarea placeholder='Write a comment' className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea> */}
                                                {
                                                    innerData?.comment_status === 1 &&
                                                    <button onClick={() => { showPostComment() }} type="button" className="btn btn-color mt-3">Post a comment {comCount > 0 ? `(${comCount})` : null}</button>
                                                }

                                                {/* {innerData?.comment_status === 1 ? <button onClick={() => { showPostComment() }} type="button" className="btn btn-color mt-3">Post a comment ({innerData?.comment_count})</button> : '' }  */}
                                            </div>
                                            {/* <AdTwo /> */}
                                            {/* <div className="single-blog mt-3">
                                            <span className="badge badge-warning">Related Articles</span>
                                        </div> */}
                                            {/* <div className="row related_articles mb-3">
                                            {
                                                innerDataRelated?.length > 0 && innerDataRelated?.map((els, idx) => (
                                                    <>
                                                        {innerData?.id !== els?.id ?
                                                            <div className="col-md-12 col-lg-4 col-xl-4 py-3">
                                                                <Link to={`/${els?.url}`}>
                                                                    <div className="img-post">
                                                                        <img src={!els?.media ? sandeshske : `${assetbaseUrl?.value}${els?.media}`} alt="" style={{ width: '100%' }} />
                                                                        <div className="content">
                                                                            <p className="m-0 ellipsis">{els?.title}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div> : ""
                                                        }
                                                    </>
                                                ))
                                            }
                                        </div> */}
                                            {/* <AdTwo /> */}
                                            <div className='bottom-taboola' >
                                                {/* Related Article Script */}
                                                <InjectScript type="taboola" key={window.location.href} script={taboolarelatedcontent} />
                                                {/* Related Article NPM
                                                <TaboolaComponent
                                                    mode="thumbnails-b"
                                                    containerId="taboola-related-articles-widget-thumbnails"
                                                    placement="Related Articles Widget Thumbnails'"
                                                    targetType="mix"
                                                    pageType="article"
                                                    publisherId="sandeshdigital-sandesh"
                                                /> */}
                                            </div>


                                            {
                                                (window.innerWidth > 991) ?
                                                    <div className='my-4 footer-taboola taboola' key={window.location.pathname}>
                                                        {/* Post Bottom Taboola With NPM
                                                        <TaboolaComponent
                                                            mode="alternating-thumbnails-a"
                                                            containerId="taboola-below-article-feed-thumbnails"
                                                            placement="Below Article Feed Thumbnails'"
                                                            targetType="mix"
                                                            pageType="article"
                                                            publisherId="sandeshdigital-sandesh"
                                                        /> */}
                                                        {/* Bottom Taboola Web  script */}
                                                        <InjectScript type="taboola" key={window.location.pathname} script={taboolafootercontent} />
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="insta-head-responsive col-12 col-md-12 col-lg-3 col-xl-3">
                                <div className='ad-side-head '>
                                    <div className="ad-side text-center mt-0">
                                        {/* {firstright?.[0]?.script && */}
                                        <div className="ad-section">
                                            {/* <HelperScript argSmall={184} argLarge={37} /> */}
                                            {
                                                window.innerWidth < 991 ?
                                                    ''
                                                    :
                                                    <InjectScript script={post37} />
                                            }
                                            {
                                                window.innerWidth < 991 ?
                                                    <InjectScript type="taboola" script={taboolafootercontent} key={window.location.href} />
                                                    : ''
                                            }

                                            {/* <InjectScript script={firstright?.[0]?.script} /> */}
                                        </div>
                                        {/* } */}

                                        {/* <p>જાહેરાત</p> */}
                                        {/* <img src="https://i.imgur.com/12sog8C.png" alt="" /> */}
                                    </div>
                                    {window.innerWidth > 991 ? <div className="shadow rightBar p-x2 mb-2 bg-f7f7f7 rounded">
                                        <div className="featured ">
                                            <a href="javascript:void(0)" className="title">Top 10 News
                                                {/*      <i className="fas fa-chevron-right"></i> */}
                                            </a>
                                            <div className="scroll s-story">
                                                {
                                                    innerData?.toptennews?.length > 0 && innerData?.toptennews?.map((els, idx) => (
                                                        <>
                                                            <CommonContent url={els?.url} dataclass='top10' bgcolorclass='bgcolorcard' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                            {/* <CommonContent id="common-share-dropdown" dataid={els?.id} url={els?.url} dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} publish_date={els?.publish_date} category={els?.category} title={els?.title} /> */}

                                                            <hr className='border-bt' />
                                                        </>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div> : ''}
                                    <div className="ad-side text-center mt-0">
                                        {/* <p>જાહેરાત</p> */}
                                        {/* {secondright?.[0]?.script &&  */}
                                        <div className="ad-section">
                                            {/* <HelperScript argSmall={185} argLarge={38} /> */}
                                            {
                                                window.innerWidth < 991 ?
                                                    <InjectScript script={post185} />
                                                    :
                                                    <InjectScript script={post38} />
                                            }
                                            {/* <InjectScript script={secondright?.[0]?.script} /> */}
                                        </div>
                                        {/* } */}
                                        {/* <img src="https://i.imgur.com/uakoBY9.png" alt="" /> */}
                                    </div>
                                    {

                                        <div className="single-blog shared mt-3">
                                            <div className="row px-3">
                                                <span className=" col badge badge-danger colortitle">Most Viewed</span>
                                                {/* <span className="col badge badge-danger colortitle">Most Shared</span> */}
                                            </div>
                                            <div className="shadow text-center mb-3 bg-white rounded">
                                                <Link to={`/${mostviews?.url}`} className="main-blogs">
                                                    <img src={`${assetbaseUrl?.value}${mostviews?.media}`} className="img-responsive" />
                                                    <span className="button-hover colorspan-botton">{mostviews?.tagline}</span>
                                                    <div className="blog-details">
                                                        <p className="ellipsis">{mostviews?.title}</p>
                                                        <span className="blog-time"><i className="far fa-clock"></i>{mostviews?.publish_date}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                    <div className="ad-side text-center">
                                        {/* <iframe style={{ height: '400px' }} src="https://static.foxnews.com/static/orion/html/embed/vaccine-finder.html" title="demo" /> */}
                                        {/* {thirdright?.[0]?.script &&  */}
                                        <div className="ad-section">
                                            {/* <HelperScript argSmall={186} argLarge={39} /> */}
                                            {/* <InjectScript script={thirdright?.[0]?.script} /> */}
                                        </div>
                                        {/* // } */}
                                    </div>
                                    <div className="ad-side mt-4">
                                        {/* <p>જાહેરાત</p> */}
                                        {/* {fourthright?.[0]?.script && <div className="ad-section">
                                            <InjectScript script={fourthright?.[0]?.script} />
                                        </div>} */}
                                        {/* <img src={business} alt="" /> */}
                                    </div>

                                    <div className='my-4 sidebar-taboola' key={'taboolacontent'}>
                                        {/* RightSide Taboola Script */}
                                        <InjectScript type="taboola" script={taboolacontent} key={window.location.href} />
                                        {/* RightSide Taboola NPM
                                        <TaboolaComponent
                                            // canonicalUrl={window.location.href}
                                            mode="thumbnails-rr"
                                            containerId="taboola-right-rail-thumbnails"
                                            placement="Right Rail Thumbnails'"
                                            targetType="mix"
                                            pageType="article"
                                            publisherId="sandeshdigital-sandesh"
                                        /> */}
                                        {/* <HelperScript argLarge={261} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            (window.innerWidth < 991) ?
                                <div className='my-4 footer-taboola taboola' key={'taboolafootercontentmobile'}>
                                    {/* Bottom Taboola Mobile Script */}
                                    {/* <InjectScript type="taboola" script={taboolafootercontent} key={window.location.href} /> */}
                                    {/* Bottom Taboola Mobile NPM
                                    <TaboolaComponent
                                        // canonicalUrl={window.location.href}
                                        mode="alternating-thumbnails-a"
                                        containerId="taboola-below-article-feed-thumbnails"
                                        placement="Below Article Feed Thumbnails'"
                                        targetType="mix"
                                        pageType="article"
                                        publisherId="sandeshdigital-sandesh"
                                    /> */}
                                </div>
                                : ''
                        }

                        <div className="commnet-sidebox">
                            <i className="fab fa-times" />
                        </div>

                    </div>

                </section>
                {/* {PostFooter?.[0]?.script &&  */}
                <div className="ad-section" >
                    {/* <HelperScript argSmall={193} argLarge={46} /> */}
                    {/* <InjectScript script={PostFooter?.[0]?.script} /> */}
                </div >
                {/* } */}
                {
                    localStorage.getItem('access_token') ? (
                        <>
                            {postCommentModalView && <Comment postCommentModalchk={showPostComment} postCommentcount={setCountCom} postId={innerData?.id} innerDataProps={innerData && innerData} />}
                        </>
                    ) : (
                        <>
                            {postCommentModalView && <Login />}
                        </>
                    )
                }

            </>
            }
            {
                (innerData?.post_type === 2 && !loading) && <>
                    <GalleryInnerComponents innerData={innerData} />
                    {/* <GalleryComponent /> */}
                </>
            }
            {
                ((innerData?.post_type === 3 || innerData?.post_type === 4) && !loading) &&
                (<>
                    <VideosInnerComponent url={innerData?.url} />
                </>)
            }

            {
                (loading) && (<>
                    <div className='container post PostLoader'>
                        <div className='row'>
                            <div className='col-md-9'>
                                <nav aria-label="breadcrumb" className='breadcrumbNav'>
                                    <ol className="breadcrumb breadcrumb-bg m-0">
                                        <li className="breadcrumb-item text-capitalize active" aria-current="page"><a className=" breadcrumb-item active" href="javascript:void(0)"> &nbsp; </a></li>
                                    </ol>
                                </nav>

                                <div className="story">
                                    <h1 className="story-title"> &nbsp; </h1>
                                    <p className="byline-text"> &nbsp; </p>
                                    <div className="caption">
                                        <img src={sandeshske} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='post_height_scroll'></div>
                </>)
            }
        </>
    )
}

export default Post
