import { HOMEPRIMARY_FETCH_DATA_SUCCESS, HOMEPRIMARY_HAVE_ERROR, HOMEPRIMARY_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function homePrimaryAction(action, payload) {
    switch (action) {
        case HOMEPRIMARY_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case HOMEPRIMARY_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case HOMEPRIMARY_FETCH_DATA_SUCCESS:
            return {
                type: action,
                homePrimary: payload,
            };

        default:
            return;
    }
}

export function homePrimaryFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(homePrimaryAction(HOMEPRIMARY_ARE_LOADING, true));
                if (localStorage.getItem('SandeshHome')) {
                    dispatch(homePrimaryAction(HOMEPRIMARY_FETCH_DATA_SUCCESS, JSON.parse(localStorage.getItem('SandeshHome'))));
                }
                Get(HttpService.homePrimary).then((result) => {
                    localStorage.setItem('SandeshHome', JSON.stringify(result.data));
                    dispatch(homePrimaryAction(HOMEPRIMARY_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(homePrimaryAction(HOMEPRIMARY_HAVE_ERROR, true));
        }
    }
}
