/* eslint-disable no-script-url */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
// import HTMLFlipBook from 'react-pageflip';
// import epaper from '../../assets/epaper.jpg';
import HomeTaboola from "../../subcomponents/HomeTaboola";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { epaperCategoryFetchData } from "../../../redux/actions/EPaperCategory";
import { sharecount } from '../../../redux/actions/commonAction';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
import HelperScript from '../../../utils/helper';
import InjectScript from "../../CustomScript";

const EpaperCategoryNewsPerDate = () => {

  const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "-");
  const { city } = useParams();
  const [activePage, setActivePage] = useState(0);
  const [subClippedData, setSubClippedData] = useState([]);
  const [zoomClass,] = useState("");
  const [dateSearch, setDateSearch] = useState(currentDate);
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data
  );
  const myTitle = useSelector(
    (state) => state.EPaperCategory?.data?.data?.name
  );
  const epaperData = useSelector(
    (state) => state.EPaperCategory?.data?.data?.sub
  );
  const epaperPdfData = useSelector(
    (state) => state.EPaperCategory?.data?.data?.main
  );

  let dispatch = useDispatch();
  useEffect(() => {
    setActivePage(0);
    const str = `${city}&date=${dateSearch}`;
    dispatch(epaperCategoryFetchData(str));
  }, [dateSearch, city]);

  useEffect(() => {
    if (epaperData?.length > 0 && epaperData?.length) {
      const subClipData = epaperData?.filter(
        (els, index) => Number(activePage) === index
      );
      if (
        subClipData &&
        subClipData?.[0]?.subdata &&
        subClipData?.[0]?.subdata !== ""
      ) {
        setSubClippedData(JSON.parse(subClipData?.[0]?.subdata));
      } else {
        setSubClippedData([]);
      }
    } else {
      setSubClippedData([]);
    }
  }, [activePage, epaperData]);

  const sizeClipHandler = (arg, cord) => {
    let sizeCord = JSON.parse(arg);
    if (cord === 'w') {
      sizeCord[cord] = ((sizeCord[cord] * 100) / sizeCord['ow']);
    }
    if (cord === 'x') {
      sizeCord[cord] = ((sizeCord[cord] * 100) / sizeCord['ow']);
    }
    if (cord === 'h') {
      sizeCord[cord] = ((sizeCord[cord] * 100) / sizeCord['oh']);
    }
    if (cord === 'y') {
      sizeCord[cord] = ((sizeCord[cord] * 100) / sizeCord['oh']);
    }
    return sizeCord[cord];
  };

  const [zoomImg, setZoomImg] = useState('');
  const [zoomImgPopup, setzoomImgPopup] = useState(false);

  const ZoomModalOpen = (VidDataId, VidDataTitle, VidDataTime) => {
    ReactGA.event({ category: 'E-Paper Zoom', action: myTitle, label: dateSearch })

    setZoomImg(VidDataId);
    setTimeout(() => {
      document.getElementById("ZoomModal").classList.add('active-modal');
      document.body.style.overflow = 'hidden';
    }, 50);
  };

  const ZoomInOut = () => {
    setzoomImgPopup(!zoomImgPopup);
  };

  const ZoomModalClose = () => {
    setZoomImg('');
    document.body.style.overflow = 'auto';
    document.getElementById("ZoomModal").classList.remove('active-modal');
  };

  let epaperDownload = 'https://assets.sandesh.com/' + epaperPdfData?.[0]?.pdf;
  if (epaperDownload) {
    epaperDownload = epaperDownload.replace('i0.wp.com/', '');
  }

  const downloadLogApi = () => {
    ReactGA.event({ category: 'E-Paper Download', action: myTitle, label: dateSearch })
    dispatch(sharecount('download', epaperPdfData?.[0]?.id));
  }

  const epaper258 =
    '' +
    "<div id='div-gpt-ad-1652870098534-0' style='min-width: 300px; min-height: 600px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/SDPL_Epaper_Inner_300X600', [300, 600], 'div-gpt-ad-1652870098534-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1652870098534-0'); });" +
    '</script>' +
    '</div>' +
    // '<div id="gpt-passback">'+
    // '<script>'+
    // 'window.googletag = window.googletag || {cmd: []};'+
    // 'googletag.cmd.push(function() {'+
    // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x600', [300, 600], 'gpt-passback').addService(googletag.pubads());"+
    // 'googletag.enableServices();'+
    // "googletag.display('gpt-passback');"+
    // '});'+
    // '</script>'+
    // '</div>'+
    '' +
    '';
  const epaper259 =
    '' +
    "<div id='div-gpt-ad-1652870128848-0' style='min-width: 300px; min-height: 250px;'> " +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/SDPL_Epaper_Inner_300X250', [300, 250], 'div-gpt-ad-1652870128848-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1652870128848-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';
    // const epaper260 = ' <div id="div-ub-sandeshepaper"> <script> window.unibots = window.unibots || { cmd: [] }; unibots.cmd.push(()=>{ unibotsPlayer("sandeshepaper"); }); </script> </div>'
    const epaper260 = ''+
    '<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>'+
    '<script>'+  
    'window.googletag = window.googletag || {cmd: []};'+
     ' googletag.cmd.push(function() {'+
     " googletag.defineSlot('/22678428348/Unibot_1x1_Epaper', [1, 1], 'div-gpt-ad-1688794850170-0').addService(googletag.pubads());"+
    'googletag.pubads().enableSingleRequest();'+
    'googletag.enableServices();'+
  '});'+
  '</script>'+
  ''+
  "<div id='div-gpt-ad-1688794850170-0'>"+
  '<script>'+
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1688794850170-0'); });"+
  '</script>'+
'</div>'+
''

    
    const epaperRightside = ''+
"<div id='div-gpt-ad-1661932269110-0' style='min-width: 300px; min-height: 250px;'>"+
    '<script>'+
    'window.googletag = window.googletag || {cmd: []};'+
    'googletag.cmd.push(function() {'+
     " googletag.defineSlot('/22678428348/SDPL_Epaper_Inner_RightBottom_300X250', [300, 250], 'div-gpt-ad-1661932269110-0').addService(googletag.pubads());"+
      'googletag.pubads().enableSingleRequest();'+
      'googletag.enableServices();'+
    '});'+
  '</script>'+
  ""+
'<script>'+
  "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1661932269110-0'); });"+
'</script>'+
'</div>'+
// '<div id="gpt-passback">'+
//   '<script>'+
//     'window.googletag = window.googletag || {cmd: []};'+
//     'googletag.cmd.push(function() {'+
//     "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());"+
//     'googletag.enableServices();'+
//     "googletag.display('gpt-passback');"+
//     '});'+
//   '</script>'+
// '</div>'+
''

    const epaperLeftside = ''+
"<div id='div-gpt-ad-1661923371102-0' style='min-width: 300px; min-height: 600px;'>"+
    '<script>'+
    'window.googletag = window.googletag || {cmd: []};'+
    'googletag.cmd.push(function() {'+
     " googletag.defineSlot('/22678428348/SDPL_Epaper_Inner_Left_300X600', [300, 600], 'div-gpt-ad-1661923371102-0').addService(googletag.pubads());"+
      'googletag.pubads().enableSingleRequest();'+
      'googletag.enableServices();'+
    '});'+
  '</script>'+
  ""+
'<script>'+
  "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1661923371102-0'); });"+
'</script>'+
'</div>'+
// '<div id="gpt-passback">'+
// '<script>'+
// 'window.googletag = window.googletag || {cmd: []};'+
// 'googletag.cmd.push(function() {'+
// "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x600', [300, 600], 'gpt-passback').addService(googletag.pubads());"+
// 'googletag.enableServices();'+
// "googletag.display('gpt-passback');"+
// '});'+
// '</script>'+
// '</div>'+
''

const vidEpaperInner = '' +
    "<div id='div-gpt-ad-1669618153561-0' style='min-width: 300px; min-height: 250px;'>"+
    '<script>'+
    'window.googletag = window.googletag || {cmd: []};'+
    'googletag.cmd.push(function() {'+
    "googletag.defineSlot('/22678428348/SDPL_Epaper_IP_Right_300x250', [300, 250], 'div-gpt-ad-1669618153561-0').addService(googletag.pubads());"+
    'googletag.pubads().enableSingleRequest();'+
    'googletag.enableServices();'+
    '});'+
    '</script>'+
    '<script>'+
      "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669618153561-0'); });"+
    '</script>'+
    '</div>'+
  ''

  const clippedimgad = ''+
  // 'after bhuj ads'+
  "<div id='div-gpt-ad-1683184024897-0' style='min-width: 300px; min-height: 250px;'>" +
  '<script>' +
  'window.googletag = window.googletag || { cmd: [] };' +
  'googletag.cmd.push(function () {' +
  "googletag.defineSlot('/22678428348/Mweb_Epaper_AboveBelowclippedimg_300x250', [300, 250], 'div-gpt-ad-1683184024897-0').addService(googletag.pubads());" +
  'googletag.pubads().enableSingleRequest();' +
  'googletag.pubads().disableInitialLoad();' +
  'googletag.enableServices();' +
  '});' +
  '</script>' +
  '' +
  '<script>' +
  "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1683184024897-0'); });" +
  '</script>' +
  '</div>' +
  '' +
  '';
  return (
    <>
      <Helmet>
        <title>{(myTitle) ? myTitle : ''} - E-Paper | Sandesh</title>
      </Helmet>
      <div className="container">
        <div className="demoPage">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {!epaperData?.length ? '' : (<div className="col-md-6 mt-3 page_btn">
                <select
                  value={activePage}
                  onChange={(e) => setActivePage(Number(e.target.value))}
                  className="form-control form-control-lg page_menu"
                >
                  {epaperData?.length > 0 &&
                    epaperData?.map((els, index) => {
                      return <option value={index}>Page {index + 1}</option>;
                    })}
                </select>
              </div>)}
              {window.innerWidth > 991 ? <div className="mb-4"><InjectScript script={vidEpaperInner} /></div> : ''}
              {!epaperData?.length ? '' : (<div className="featured ">
                <div className="scroll p-story">
                  {epaperData?.length > 0 &&
                    epaperData?.map((els, index) => {
                      // let myImgPreview = 'https://esandesh.gumlet.io/' + els?.photo;
                      let myImgPreview = 'https://resize-img.sandesh.com/assets.sandesh.com/' + els?.photo;
                      const mystring = "s-";
                      const ind1 = myImgPreview.lastIndexOf('/');
                      myImgPreview = myImgPreview.substring(0, ind1 + 1) + mystring + myImgPreview.substring(ind1 + 1);
                      return (
                        <div className="img-in-txt margin-bottom-guj leftScrollEpaper">
                          <a href="javascript:void(0)">
                            <img
                              key={Math.random()}
                              onClick={() => setActivePage(index)}
                              className="epaper-news-img"
                              src={myImgPreview}
                              alt=""
                            />
                            <div className=" content-epaper blog-details content-epaper2">
                              <p className="epaper-bottom-text row px-3 page_no">
                                Page {index + 1}
                              </p>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>)}
              <div className="my-3">
                <InjectScript
                  key={window.location.pathname}
                  script={window.innerWidth > 991 ? epaperLeftside : ''}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-5 col-xl-6 gujarat-responsive-space-sm">
              <h1 className="nameEpaper">{myTitle}</h1>
              <div className="row">
                {!epaperData?.length ? '' : (
                  <div className="col-md-7 mt-3">
                    <ul className="pagination justify-content-center mt-0 new_pagination">
                      <li
                        className="page-item"
                        onClick={() => {
                          activePage > 0 && setActivePage(Number(activePage) - 1);
                        }}
                      >
                        <a
                          className="page-link next_page"
                          href="javascript:void(0)"
                          aria-label="Previous"
                        >
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                      {epaperData?.length > 0 &&
                        epaperData?.map((els, index) => {
                          return (
                            <>
                              {activePage - 2 <= index && activePage + 3 > index && (
                                <li
                                  onClick={() => setActivePage(Number(index))}
                                  key={Math.random()}
                                  class={`page-item ${activePage === index ? "active" : ""
                                    }`}
                                >
                                  <span
                                    class={`page-link ${activePage === index
                                      ? "new_pageitem"
                                      : "new_pageitem1"
                                      }`}
                                  >
                                    {index + 1}
                                  </span>
                                </li>
                              )}
                            </>
                          );
                        })}
                      <li
                        className="page-item"
                        onClick={() => {
                          activePage < epaperData?.length - 1 &&
                            setActivePage(Number(activePage) + 1);
                        }}
                      >
                        <a className="page-link next_page" href="javascript:void(0)" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                    <a
                      // onClickCapture={() => { ZoomModalOpen('https://esandesh.gumlet.io/' + epaperData[activePage]?.photo.split('?')[0]); }}
                      onClickCapture={() => { ZoomModalOpen('https://assets.sandesh.com/' + epaperData[activePage]?.photo.split('?')[0]); }}
                      // target="_blank"
                      // href={assetbaseUrl?.value + epaperData[activePage]?.photo}
                      className="text-uppercase cursor-pointer new_zoom pb-2 " rel="noreferrer"
                    >
                      <i className="fa fa-search-plus "></i> &nbsp;Zoom
                    </a>
                  </div>)
                }
                <div class={`mt-3 ${!epaperData?.length ? 'col-md-6 offset-3 removeInputmg' : 'col-md-5'}`}>
                  <ul className="pagination mt-0 justify-content-end">
                    <input
                      onChange={(e) => setDateSearch(e.target.value)}
                      placeholder="Selected date"
                      type="date"
                      min="1997-01-01"
                      value={epaperPdfData?.[0]?.date ? epaperPdfData?.[0]?.date : dateSearch}
                      max={currentDate}
                      id="date-picker-example"
                      className="form-control datepicker date_input"
                    ></input>
                    {/* {!epaperData?.length ? '' : (
                      <a
                        target="_blank"
                        onClick={() => downloadLogApi()}
                        rel="noreferrer"
                        href={epaperDownload}
                        className="text-uppercase city_name1 "
                      >
                        Download
                      </a>
                    )} */}
                  </ul>
                </div>
              </div>

              {!epaperData?.length ? <div className="NoEPaper">No E-Paper Found</div> : ''}

              <div className="subcategory_image">
                <div
                  style={zoomClass ? { ...zoomClass } : {}}
                  id="carouselExampleControls"
                  className="carousel slide "
                //   data-ride="carousel"
                >

                  {!epaperData?.length ? '' : (<div className="carousel-inner">
                    {epaperData?.length > 0 &&
                      epaperData?.map((els, index) => {
                        return (
                          <div
                            key={index}
                            class={`carousel-item ${index === activePage ? "active" : ""
                              }`}
                          >

                            {subClippedData?.length > 0 && (
                              <div className="clipImages">
                                {subClippedData?.length > 0 &&
                                  subClippedData?.map((els) => (
                                    <Link
                                      target="_blank"
                                      rel="noreferrer"
                                      // to={{ pathname: '/epaper/sub?name=' + myTitle + '&path=' + els?.name + '&date=' + dateSearch }}
                                      to={'/epaper/sub?name=' + myTitle + '&path=' + els?.name + '&date=' + dateSearch }
                                      // href={assetbaseUrl?.value + els?.name}
                                      key={Math.random()}
                                      alt="heartrate"
                                      shape="rect"
                                      style={{
                                        width: `${sizeClipHandler(els?.size, "w")}%`,
                                        height: `${sizeClipHandler(els?.size, "h")}%`,
                                        left: `${sizeClipHandler(els?.size, "x")}%`,
                                        top: `${sizeClipHandler(els?.size, "y")}%`,
                                      }}
                                    > &nbsp; </Link>
                                  ))}
                              </div>
                            )}

                            <img
                              // useMap="#clipImage"
                              key={Math.random()}
                              src={'https://resize-img.sandesh.com/assets.sandesh.com/' + els?.photo}
                              // src={'https://esandesh.gumlet.io/' + els?.photo}
                              className="d-block w-100"
                              alt=""
                            />
                          </div>
                        );
                      })}
                  </div>)}
                  {!epaperData?.length ? '' : (
                    <><button
                      className="carousel-control-prev"
                      type="button"
                      data-target="#carouselExampleControls"
                      // data-slide="prev"
                      onClick={() => {
                        activePage > 0 && setActivePage(activePage - 1);
                      }}
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Previous</span>
                    </button><button
                      className="carousel-control-next"
                      type="button"
                      data-target="#carouselExampleControls"
                      // data-slide="next"
                      onClick={() => {
                        activePage < epaperData?.length - 1 &&
                          setActivePage(activePage + 1);
                      }}
                    >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Next</span>
                      </button></>)}
                </div>
              </div>
              <div className="col-12 my-3 text-center">
                {/* <HelperScript argLarge={260} argSmall={260} /> */}
                <InjectScript script={epaper260} />
              </div>
              <div>
                {!epaperData?.length ? '' : (
                  <div className="cropimage viewClipped mt-5 mb-5">
                    {window.innerWidth < 991 && <InjectScript script={clippedimgad} />}
                    {subClippedData?.length > 0 && (
                      <b className="text-uppercase city_name1">Clipped Images</b>
                    )}
                    <div className="row mt-5">
                      {subClippedData?.length > 0 &&
                        subClippedData?.map((els) => {
                          return (
                            <div
                              key={Math.random()}
                              className="col-md-12 col-lg-4 col-xl-4 "
                            >
                              <div className="single-blog">
                                <Link
                                  // to={{ pathname: '/epaper/sub?name=' + myTitle + '&path=' + els?.name + '&date=' + dateSearch }}
                                  to={'/epaper/sub?name=' + myTitle + '&path=' + els?.name + '&date=' + dateSearch}
                                  target="_blank" rel="noreferrer"
                                >
                                  {/* <ClippedImage name={els?.name} /> */}
                                  <img
                                    key={Math.random()}
                                    className="epaper-news-img new1"
                                    src={'https://resize-img.sandesh.com/assets.sandesh.com/' + els?.name}
                                    // src={'https://esandesh.gumlet.io/' + els?.name}
                                    alt=""
                                  />
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>)}
                  <div id="epaper_clipped" class="epaper_clipped"></div>

              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2">
            {window.innerWidth > 991 ? <div className="my-3"><InjectScript script={epaper258} /></div> : ''}
             {/* <div className="my-3">
                <InjectScript
                  key={window.location.pathname}
                  script={epaper258}
                />
              </div> */}
               {window.innerWidth > 991 ? <div className="my-3"><InjectScript script={epaper259} /></div> : ''}
              {/* <div className="my-3">
                <HelperScript argLarge={259} />
                <InjectScript
                  key={window.location.pathname}
                  script={epaper259}
                />
              </div> */}
               {window.innerWidth > 991 ? <div className="my-3"><InjectScript script={epaperRightside} /></div> : ''}

              {/* <div className="my-3">
                <HelperScript argLarge={259} />
                <InjectScript
                  key={window.location.pathname}
                  script={epaperRightside}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div id="ZoomModal" className="videoSidebar">
        <div className="closebtn-zoom-epaper" onClick={() => ZoomModalClose()} >
          <i className="fas fa-times"></i>
        </div>

        <div className="zoombtn-inner-epaper" onClick={() => ZoomInOut()} >
          <i className={`fas fa-${zoomImgPopup ? 'minus' : 'plus'}`}></i> ZOOM
        </div>

        {/* <img className="epaper-zoom-modal-page" src="https://assets.sandesh.com/epaper/2022/03/03/21416462617691.jpg" alt="" /> */}
        <div className={`${zoomImgPopup ? '' : 'minimize'} epaperZoomer bg-white`}>
          <img
            key={Math.random()} className={`epaper-zoom-modal-page test ${zoomImgPopup ? '' : 'minimize'}`} src={zoomImg} alt="" />
        </div>
      </div>
      <HomeTaboola key={window.location.href} />
    </>
  );
};

export default EpaperCategoryNewsPerDate;
