/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { useSelector } from 'react-redux';
import sandeshske from '../../assets/sandeshske.png';
import { Link } from 'react-router-dom';
import HelperScript from '../../utils/helper'
import ReactGA from "react-ga4";
import CommonContent from '../CommonContent';
import CommonHeadCardContent from '../CommonHeadCardContent';
import InjectScript from '../CustomScript';

const National = () => {

    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);
    const Game = useSelector((state) => state?.HomeSecondaryReducer?.data?.Game);
    const Nationallist = useSelector((state) => state?.HomeSecondaryReducer?.data?.National);
    // const { 7: NationalRight } = useSelector((state) => state?.widgetReducer?.data);
    const businessWidget3 = '' +
        '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div>' +
        '<div class="tradingview-widget-copyright"><a href="https://in.tradingview.com/markets/stocks-india/" rel="noopener" target="_blank"><span class="blue-text">Stock Market Today</span></a> by TradingView</div>' +
        '<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js" async>' +
        '{ "colorTheme": "light","dateRange": "12M","exchange": "BSE","showChart": false,"locale": "in","largeChartUrl": "",' +
        ' "isTransparent": false,"showSymbolLogo": true,"showFloatingTooltip": false, "width": "400","height": "407"} </script> </div>' +
        '' +
        '';

    const dmscript = '' +
    "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>"+
    '<script>'+
    'window.googletag = window.googletag || {cmd: []};'+
    'googletag.cmd.push(function() {'+
    "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());"+
    'googletag.pubads().enableSingleRequest();'+
    'googletag.enableServices();'+
    '});'+
    '</script>'+
    '<script>'+
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });"+
    '</script>'+
    '</div>'+
        '';

        const afterStockWidgetweb = '<script type="text/javascript" language="javascript">'+
         'var aax_size="300x250";'+
         ' var aax_pubname = "sandeshnews23-21";'+
          'var aax_src="302";'+
           '</script>'+
            '<script type="text/javascript" language="javascript" src="http://c.amazon-adsystem.com/aax2/assoc.js"></script>'+
        // "<div id='div-gpt-ad-1669617015113-0' style='min-width: 300px; min-height: 250px;'>"+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/22678428348/SDPL_HP_Belowstockwidget_300x250', [300, 250], 'div-gpt-ad-1669617015113-0').addService(googletag.pubads());"+
        // 'googletag.pubads().enableSingleRequest();'+
        // 'googletag.enableServices();'+
        // '});'+
        // '</script>'+
        // '<script>'+
        // "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617015113-0'); });"+
        // '</script>'+
        // '</div>'+
        ''

        const amazonads = ''+
        '<a target="_blank" href="https://www.amazon.in/b?_encoding=UTF8&tag=sandeshnews23-21&linkCode=ur2&linkId=73da12e7d1240b6c63a3c7d5485c79f8&camp=3638&creative=24630&node=3561110031">Mobile Test</a>'+
        ''

        const afterStockWidgetmobile = ''+
        "<div id='div-gpt-ad-1672903013941-0' style='min-width: 300px; min-height: 250px;'>"+
        '<script>'+
        'window.googletag = window.googletag || {cmd: []};'+
        'googletag.cmd.push(function() {'+
        "googletag.defineSlot('/22678428348/Mweb_HP_BelowStockWidget_300x250', [300, 250], 'div-gpt-ad-1672903013941-0').addService(googletag.pubads());"+
        'googletag.pubads().enableSingleRequest();'+
        'googletag.enableServices();'+
        '});'+
        '</script>'+
        '<script>'+
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1672903013941-0'); });"+
        '</script>'+
        '</div>'+
        ''
    return (
        <>
            <section className="national bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                            <div className="single-blog">
                                <span className="badge badge-warning">India</span>
                                <Link to="/india" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'India', label: 'Read More' })} className="readmore">Read More</Link>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 removePaddingLeft removePaddingHover">

                                    {
                                        Nationallist?.length > 0 &&
                                        <>
                                            <CommonHeadCardContent url={Nationallist[0]?.url} dataClass="margin-bottom" sandeshske={sandeshske} media={Nationallist[0]?.media} mediaimg={`${assetbaseUrl?.value}${Nationallist[0]?.media}`} post_type={Nationallist[0]?.post_type} time={Nationallist[0]?.time} title={Nationallist[0]?.title} id={Nationallist[0]?.id} publish_date={Nationallist[0]?.publish_date} />

                                            <CommonContent url={Nationallist[1]?.url} src={`${assetbaseUrl?.value}${Nationallist[1]?.media}`} post_type={Nationallist[1]?.post_type} time={Nationallist[1]?.time} publish_date={Nationallist[1]?.publish_date} category={Nationallist[1]?.tagline} title={Nationallist[1]?.title} />

                                            {/* <Link to={`/${Nationallist[1]?.url}`} className="related-blogs w-100">
                                                    <p className='img-16-9-blogs'>
                                                        <div className='col'>
                                                            <img src={!Nationallist[1]?.media ? sandeshske : `${assetbaseUrl?.value}${Nationallist[1]?.media}`} className="img-responsive" />
                                                            {Nationallist?.[1]?.post_type === 3 || Nationallist?.[1]?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{Nationallist?.[1]?.time} </p> : ""}
                                                            <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{Nationallist[1]?.publish_date}</p>
                                                        </div>
                                                    </p>
                                                    <div className='col-8 p-0'>
                                                        <div className="blog-details-national-new">
                                                            <span><b className="color-red">{Nationallist[1]?.tagline}</b></span>
                                                            <p className="ellipsis">{Nationallist[1]?.title}</p>
                                                        </div>
                                                    </div>
                                                </Link> */}
                                        </>
                                    }
                                </div>
                                <div className="col-md-5 col-lg-5 col-xl-5 removePaddingRight">
                                    {
                                        Nationallist?.length > 0 && Nationallist?.map((els, idx) => (
                                            <>
                                                {idx > 1 &&
                                                    <CommonContent url={els?.url} src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.tagline} title={els?.title} />
                                                }
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 offset-md-3 col-lg-3 offset-lg-0 col-xl-3 offset-xl-0">
                            <div className="text-center ad-side-head">
                                {/* {NationalRight?.[0]?.script && */}
                                {/* <div className="ad-section">
                                    <HelperScript argSmall={169} argLarge={7} />
                                </div> */}
                                <div className='mb-3' >
                                    <div className="text-center businesswidget business-home">

                                        <InjectScript script={businessWidget3} key={businessWidget3} />
                                    </div>
                                </div>
                                {/* } */}
                                {/* {(Game?.length > 0) && <div>
                                    <a href={Game[0]?.url} target="_blank  ">
                                        <img src={Game[0]?.wall} alt={Game[0]?.text} className="img-responsive" />
                                    </a>
                                    <p className="game-title">{Game[0]?.text}</p>
                                </div>} */}
                                 <div className='dm-national-script'>
                                    {/* <InjectScript script={afterStockWidget} /> */}
                                    <InjectScript script={window.innerWidth > 991 ? afterStockWidgetweb : afterStockWidgetweb} />
                                    <InjectScript script={amazonads} />
                                    {/* <InjectScript script={dmscript} /> */}
                                </div>
                                {/* <Image src="https://i.imgur.com/9TvXkvh.png" alt="" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default National
