export const ITEMS_HAVE_ERROR = "ITEMS_HAVE_ERROR";
export const ITEMS_ARE_LOADING = "ITEMS_ARE_LOADING";
export const ITEMS_FETCH_DATA_SUCCESS = "ITEMS_FETCH_DATA_SUCCESS";

export const LOGIN_HAVE_ERROR = "LOGIN_HAVE_ERROR";
export const LOGIN_ARE_LOADING = "LOGIN_ARE_LOADING";
export const LOGIN_FETCH_DATA_SUCCESS = "LOGIN_FETCH_DATA_SUCCESS";

export const FOOTER_HAVE_ERROR = "FOOTER_HAVE_ERROR";
export const FOOTER_ARE_LOADING = "FOOTER_ARE_LOADING";
export const FOOTER_FETCH_DATA_SUCCESS = "FOOTER_FETCH_DATA_SUCCESS";

// export const CONTACTUS_HAVE_ERROR= "CONTACTUS_HAVE_ERROR";
// export const CONTACTUS_ARE_LOADING= "CONTACTUS_ARE_LOADING";
// export const CONTACTUS_FETCH_DATA_SUCCESS= "CONTACTUS_FETCH_DATA_SUCCESS";

export const PAGE_HAVE_ERROR = "PAGE_HAVE_ERROR";
export const PAGE_ARE_LOADING = "PAGE_ARE_LOADING";
export const PAGE_FETCH_DATA_SUCCESS = "PAGE_FETCH_DATA_SUCCESS";


export const INVREL_HAVE_ERROR = "INVREL_HAVE_ERROR";
export const INVREL_ARE_LOADING = "INVREL_ARE_LOADING";
export const INVREL_FETCH_DATA_SUCCESS = "INVREL_FETCH_DATA_SUCCESS";



export const GALLARY_HAVE_ERROR = "GALLARY_HAVE_ERROR";
export const GALLARY_ARE_LOADING = "GALLARY_ARE_LOADING";
export const GALLARY_FETCH_DATA_SUCCESS = "GALLARY_FETCH_DATA_SUCCESS";


export const GALLARY_INNER_HAVE_ERROR = "GALLARY_INNER_HAVE_ERROR";
export const GALLARY_INNER_ARE_LOADING = "GALLARY_INNER_ARE_LOADING";
export const GALLARY_INNER_FETCH_DATA_SUCCESS = "GALLARY_INNER_FETCH_DATA_SUCCESS";

export const SETTING_HAVE_ERROR = "SETTING_HAVE_ERROR";
export const SETTING_ARE_LOADING = "SETTING_ARE_LOADING";
export const SETTING_FETCH_DATA_SUCCESS = "SETTING_FETCH_DATA_SUCCESS";

export const HOMESECONDARY_HAVE_ERROR = "HOMESECONDARY_HAVE_ERROR";
export const HOMESECONDARY_ARE_LOADING = "HOMESECONDARY_ARE_LOADING";
export const HOMESECONDARY_FETCH_DATA_SUCCESS = "HOMESECONDARY_FETCH_DATA_SUCCESS";

export const HOMEPRIMARY_HAVE_ERROR = "HOMEPRIMARY_HAVE_ERROR";
export const HOMEPRIMARY_ARE_LOADING = "HOMEPRIMARY_ARE_LOADING";
export const HOMEPRIMARY_FETCH_DATA_SUCCESS = "HOMEPRIMARY_FETCH_DATA_SUCCESS";

export const POST_HAVE_ERROR = "POST_HAVE_ERROR";
export const POST_ARE_LOADING = "POST_ARE_LOADING";
export const POST_FETCH_DATA_SUCCESS = "POST_FETCH_DATA_SUCCESS";

export const VIDEOINNER_HAVE_ERROR = "VIDEOINNER_HAVE_ERROR";
export const VIDEOINNER_ARE_LOADING = "VIDEOINNER_ARE_LOADING";
export const VIDEOINNER_FETCH_DATA_SUCCESS = "VIDEOINNER_FETCH_DATA_SUCCESS";

export const CATEGORY_HAVE_ERROR = "CATEGORY_HAVE_ERROR";
export const CATEGORY_ARE_LOADING = "CATEGORY_ARE_LOADING";
export const CATEGORY_FETCH_DATA_SUCCESS = "CATEGORY_FETCH_DATA_SUCCESS";

export const ENTERTAINMENT_HAVE_ERROR = "ENTERTAINMENT_HAVE_ERROR";
export const ENTERTAINMENT_ARE_LOADING = "ENTERTAINMENT_ARE_LOADING";
export const ENTERTAINMENT_FETCH_DATA_SUCCESS = "ENTERTAINMENT_FETCH_DATA_SUCCESS";

export const CATEGORYASTRO_HAVE_ERROR = "CATEGORYASTRO_HAVE_ERROR";
export const CATEGORYASTRO_ARE_LOADING = "CATEGORYASTRO_ARE_LOADING";
export const CATEGORYASTRO_FETCH_DATA_SUCCESS = "CATEGORYASTRO_FETCH_DATA_SUCCESS";


export const GUJARATSAMAGRACITY_HAVE_ERROR = "GUJARATSAMAGRA_HAVE_ERROR";
export const GUJARATSAMAGRACITY_ARE_LOADING = "GUJARATSAMAGRA_ARE_LOADING";
export const GUJARATSAMAGRACITY_FETCH_DATA_SUCCESS = "GUJARATSAMAGRA_FETCH_DATA_SUCCESS";

export const VIDEO_HAVE_ERROR = "VIDEO_HAVE_ERROR";
export const VIDEO_ARE_LOADING = "VIDEO_ARE_LOADING";
export const VIDEO_FETCH_DATA_SUCCESS = "VIDEO_FETCH_DATA_SUCCESS";



export const LOGING_HAVE_ERROR = "LOGING_HAVE_ERROR";
export const LOGING_ARE_LOADING = "LOGING_ARE_LOADING";
export const LOGING_FETCH_DATA_SUCCESS = "LOGING_FETCH_DATA_SUCCESS";


export const CITY_HAVE_ERROR = "CITY_HAVE_ERROR";
export const CITY_ARE_LOADING = "CITY_ARE_LOADING";
export const CITY_FETCH_DATA_SUCCESS = "CITY_FETCH_DATA_SUCCESS";


export const CITY_RESULT_HAVE_ERROR = "CITY_RESULT_HAVE_ERROR";
export const CITY_RESULT_ARE_LOADING = "CITY_RESULT_ARE_LOADING";
export const CITY_RESULT_FETCH_DATA_SUCCESS = "CITY_RESULT_FETCH_DATA_SUCCESS";

export const TITHI_HAVE_ERROR = "TITHI_HAVE_ERROR";
export const TITHI_ARE_LOADING = "TITHI_ARE_LOADING";
export const TITHI_FETCH_DATA_SUCCESS = "TITHI_FETCH_DATA_SUCCESS";

export const CHILDMENU_HAVE_ERROR = "CHILDMENU_HAVE_ERROR";
export const CHILDMENU_ARE_LOADING = "CHILDMENU_ARE_LOADING";
export const CHILDMENU_FETCH_DATA_SUCCESS = "CHILDMENU_FETCH_DATA_SUCCESS";

export const HOROSCOPE_HAVE_ERROR = "HOROSCOPE_HAVE_ERROR";
export const HOROSCOPE_ARE_LOADING = "HOROSCOPE_ARE_LOADING";
export const HOROSCOPE_FETCH_DATA_SUCCESS = "HOROSCOPE_FETCH_DATA_SUCCESS";

export const WIDGET_HAVE_ERROR = "WIDGET_HAVE_ERROR";
export const WIDGET_ARE_LOADING = "WIDGET_ARE_LOADING";
export const WIDGET_FETCH_DATA_SUCCESS = "WIDGET_FETCH_DATA_SUCCESS";


export const SEARCH_RESULT_HAVE_ERROR = "SEARCH_RESULT_HAVE_ERROR";
export const SEARCH_RESULT_ARE_LOADING = "SEARCH_RESULT_ARE_LOADING";
export const SEARCH_RESULT_FETCH_DATA_SUCCESS = "SEARCH_RESULT_FETCH_DATA_SUCCESS";

export const EPAPER_CATEGORY_HAVE_ERROR = "EPAPER_CATEGORY_HAVE_ERROR";
export const EPAPER_CATEGORY_ARE_LOADING = "EPAPER_CATEGORY_ARE_LOADING";
export const EPAPER_CATEGORY_FETCH_DATA_SUCCESS = "EPAPER_CATEGORY_FETCH_DATA_SUCCESS";


export const EPAPER_MENU_HAVE_ERROR = "EPAPER_MENU_HAVE_ERROR";
export const EPAPER_MENU_ARE_LOADING = "EPAPER_MENU_ARE_LOADING";
export const EPAPER_MENU_FETCH_DATA_SUCCESS = "EPAPER_MENU_FETCH_DATA_SUCCESS";


export const INPUT_VALUE_HAVE_ERROR = "INPUT_VALUE_HAVE_ERROR";
export const INPUT_VALUE_ARE_LOADING = "INPUT_VALUE_ARE_LOADING";
export const INPUT_VALUE_FETCH_DATA_SUCCESS = "INPUT_VALUE_FETCH_DATA_SUCCESS";

export const EPAPERIMAGE_HAVE_ERROR = "EPAPERIMAGE_HAVE_ERROR";
export const EPAPERIMAGE_ARE_LOADING = "EPAPERIMAGE_ARE_LOADING";
export const EPAPERIMAGE_FETCH_DATA_SUCCESS = "EPAPERIMAGE_FETCH_DATA_SUCCESS";


export const COMMENT_HAVE_ERROR = "COMMENT_HAVE_ERROR";
export const COMMENT_ARE_LOADING = "COMMENT_ARE_LOADING";
export const COMMENT_FETCH_DATA_SUCCESS = "COMMENT_FETCH_DATA_SUCCESS";


export const VIDEOCATEGORY_HAVE_ERROR = "VIDEOCATEGORY_HAVE_ERROR";
export const VIDEOCATEGORY_ARE_LOADING = "VIDEOCATEGORY_ARE_LOADING";
export const VIDEOCATEGORY_FETCH_DATA_SUCCESS = "VIDEOCATEGORY_FETCH_DATA_SUCCESS";



export const SPORTSCORE_HAVE_ERROR = "SPORTSCORE_HAVE_ERROR";
export const SPORTSCORE_ARE_LOADING = "SPORTSCORE_ARE_LOADING";
export const SPORTSCORE_FETCH_DATA_SUCCESS = "SPORTSCORE_FETCH_DATA_SUCCESS";


export const MARQUEE_HAVE_ERROR = "MARQUEE_HAVE_ERROR";
export const MARQUEE_ARE_LOADING = "MARQUEE_ARE_LOADING";
export const MARQUEE_FETCH_DATA_SUCCESS = "MARQUEE_FETCH_DATA_SUCCESS";