/* eslint-disable no-unused-vars */
import React from 'react'
// import Taboola from '../../assets/Taboola.jpg'
import InjectScript from '../CustomScript';
import TaboolaComponent from '@taboola/taboola-react-web'


const HomeTaboola = () => {
    document.addEventListener('scroll', function () {
        function checkVisible(elm) {
            if (elm) {
                var rect = elm.getBoundingClientRect();
                var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
            } else {
                return false;
            }
        }
        if (checkVisible(document.getElementsByClassName('taboola')[0])) {
            document.getElementById('footer_bottom').classList.add('fixed');
        } else {
            document.getElementById('footer_bottom').classList.remove('fixed');
        }

    }, {
        passive: true
    });

    const content = '' +
        // "<script>" +
        // 'window._taboola = window._taboola || [];' +
        // "_taboola.push({notify:'newPageLoad'});" +
        // "_taboola.push({article:'auto', url: '" + window.location.href + "'});" +
        // "</script>" +
        '' +
        // '<div id="taboola-feed-below-home-page-thumbnails"></div>' +
        '' +
        '<script type="text/javascript">' +
        // 'setTimeout(() => {' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'alternating-thumbnails-h'," +
        "container: 'taboola-feed-below-home-page-thumbnails'," +
        "placement: 'Feed below Home page Thumbnails'," +
        "target_type: 'mix'" +
        '}); ' +
        // "}, 100);" +
        '</script>' +
        '' +
        '<script type="text/javascript">' +
        // 'window._taboola = window._taboola || [];' +
        // ' _taboola.push({flush: true});' +
        // "alert('12');"+
        '</script>' +
        '' +
        '';

    return (
        <>
            <div className='container my-4 mb-10 taboola'>
                {/* Category Footer Taboola With script */}
                <div id="taboola-feed-below-home-page-thumbnails"></div>
                <InjectScript type='taboola' script={content} key={window.location.href} />
                {/* Category Taboola With NPM
                <TaboolaComponent
                    mode="alternating-thumbnails-h"
                    containerId="taboola-feed-below-home-page-thumbnails"
                    placement="Feed below Home page Thumbnails'"

                    targetType="mix"
                    pageType="category"
                    publisherId="sandeshdigital-sandesh"
                /> */}
                {/* <p className='text-center my-5'> <img src={Taboola} alt="" /> </p> */}
            </div>
        </>
    );
}

export default HomeTaboola
