import { MARQUEE_FETCH_DATA_SUCCESS, MARQUEE_HAVE_ERROR, MARQUEE_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function marqueeAction(action, payload) {
    switch (action) {
        case MARQUEE_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case MARQUEE_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case MARQUEE_FETCH_DATA_SUCCESS:
            return {
                type: action,
                marquee: payload,
            };

        default:
            return;
    }
}

export function marqueeFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(marqueeAction(MARQUEE_ARE_LOADING, true));
                // if (localStorage.getItem('SandeshHomeMarquee')) {
                //     dispatch(marqueeAction(MARQUEE_FETCH_DATA_SUCCESS, JSON.parse(localStorage.getItem('SandeshHomeMarquee'))));
                // }
                Get(HttpService.marquee).then((result) => {
                    // localStorage.setItem('SandeshHomeMarquee', JSON.stringify(result.data));
                    dispatch(marqueeAction(MARQUEE_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(marqueeAction(MARQUEE_HAVE_ERROR, true));
        }
    }
}
