/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const Rss = () => {
    const [data, setapiData] = useState({});

    useEffect(() => {
        async function fetchData() {
            const myMetroData = await Get(HttpService.rssURL);
            setapiData(myMetroData?.data);
        };
        fetchData();
    }, []);

    return (
        <>
            <section className='rss'>
                <div className='container'>
                    <Helmet>
                        <title>Sitemap | Sandesh</title>
                    </Helmet>

                    {/* <div className='rss-title mt-3'>
                        <p>RSS Feeds</p>
                    </div> */}
                    {/* <hr /> */}
                    <div className='row my-3'>
                        <div className='col-md-2'>

                        </div>
                        <div className='col-md-8'>
                            <h1>Sitemap</h1>
                            <div className='rss-feed-sec-title pt-0'>
                                <span>
                                    List of categories:
                                </span>
                                <div className='row'>
                                    {(data?.category?.length > 0) ? data?.category?.map((tl) => {
                                        return (
                                            <div className='col-md-3 rss-feed my-1 col-6'>
                                                <Link to={`/${tl?.url}`}><i className="fa fa-link mr-2"></i>{tl?.name}</Link>
                                            </div>
                                        );
                                    }) : <div className='col-md-12 text-center'>Please wait...</div>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2'>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Rss;