/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import $ from 'jquery';
import { Link } from 'react-router-dom'
import { LogingFetchData, SignupFetchData, SigninFetchData } from '../../redux/actions/loginAction'
import { useDispatch, useSelector } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import { googleLogin, facebookLogin, forgetPassword, updatePassword } from '../../redux/actions/commonAction';
import Helmet from 'react-helmet';
// import { PbaseUrl } from '../../utils/httpService';
// import {
//     LoginSocialGoogle,
//     LoginSocialFacebook
// } from "reactjs-social-login";

const Login = (props) => {
    const dispatch = useDispatch();
    const [userId, setUserId] = useState();
    const [visiblesignup, setVisiblesignup] = useState(false);
    const [visibleConsignup, setVisibleConsignup] = useState(false);
    const [Visibleforget, setVisibleforget] = useState(false);
    const [visiblelogin, setVisiblelogin] = useState(false);
    const [userExistClick, setUserExistClick] = useState('');
    // const [error, setError] = useState('');
    const [uemail, setUemail] = useState('');
    const [umobile, setUmobile] = useState('');
    const [userLoginData, setUserLoginData] = useState();
    const [message, setMessage] = useState()
    const [user, setUser] = useState({ userid: '', userEmail: '', password: '', fullName: '', policy: false, yearTerms: false, confirmPassword: '', mobile: '', otp: '' });
    const [apiCall, setAPICall] = useState(false);
    const checkUserId = useSelector((state) => state?.LogingReducer);
    const [profile] = useState();

    const responseFacebook = async (response) => {
        if (response?.hasOwnProperty('error')) {
            return true;
        } else {
            dispatch(facebookLogin(response)).then((res) => {
                if (res) {
                    localStorage.setItem("socialLogin", 'Facebook');
                    $('.login-main-box').removeClass('active');
                    $('.signin-section').find('.signinform').hide();
                    $('.signin-section').find('.signupform').hide();
                    $('.signin-section').find('.main-form').show();
                    props.success('success');
                }
            });
        }
    }

    const responseGoogle = async (response) => {
        if (response?.hasOwnProperty('error')) {
            return true;
        } else {
            dispatch(googleLogin(response)).then((res) => {
                if (res) {
                    localStorage.setItem("socialLogin", 'Google');
                    $('.login-main-box').removeClass('active');
                    $('.signin-section').find('.signinform').hide();
                    $('.signin-section').find('.signupform').hide();
                    $('.signin-section').find('.main-form').show();
                    props.success('success');
                }
            });
        }
    }

    useEffect(() => {
    }, [profile]);

    useEffect(() => {
        if (apiCall) {
            if (checkUserId?.data?.userid) {
                setUserId(checkUserId?.data?.userid)
                setAPICall(false)
            } else {
                $('.signin-section').find('.main-form').hide();
                $('.signin-section').find('.signinform').hide();
                $('.signin-section').find('.signupform').show();
                $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
                $('.signin-section').find('.signupform').find('.secondary-link').show();
                $('.signin-section').find('.signupform').find('input[name=mobile]').val(user.userEmail);
            }
        }
    }, [checkUserId, apiCall]);
    // React.useEffect(() => {
    //     $(document).ready(function () {
    //         $('section.login input[type=text]').val('');
    //     })
    //     $(document).on("click", ".signbtn", function () {
    //         $('.login-main-box').addClass('active');
    //         $('.profile-section').hide();
    //     });
    //     $(document).on("click", ".closebtn", function () {
    //         $('.login-main-box').removeClass('active');
    //         $('.signin-section').find('.signinform').hide();
    //         $('.signin-section').find('.signupform').hide();
    //         $('.signin-section').find('.main-form').show();
    //     });

    //     $(document).on("click", ".contibtn", function (e) {
    //         e.preventDefault();
    //         var myuser = $(this).closest('.main-form').find('input.checkno').val();
    //         if (myuser == 1234567890) {
    //             $('.signin-section').find('.main-form').hide();
    //             $('.signin-section').find('.signupform').hide();
    //             $('.signin-section').find('.signinform').show();
    //             $('.signin-section').find('.signinform').find('.secondary-link').show();
    //             $('.signin-section').find('.signinform').find('input[name=emailId]').val(myuser);
    //         } else {
    //             //            signupform
    //             $('.signin-section').find('.main-form').hide();
    //             $('.signin-section').find('.signinform').hide();
    //             $('.signin-section').find('.signupform').show();
    //             $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
    //             $('.signin-section').find('.signupform').find('.secondary-link').show();
    //             $('.signin-section').find('.signupform').find('input[name=mobile]').val(myuser);
    //         }
    //     });

    //     // $(document).on("click", ".profile", function () {
    //     //     $('.signin-section').find('.main-form').hide();
    //     //     $('.signin-section').hide();
    //     //     $('.signin-section').find('.signinform').hide();
    //     //     $('.profile-section').show();
    //     // });
    //     var modal = document.querySelector('.uppermodel')
    //     window.addEventListener('keydown', function (event) {
    //         if (event.key === 'Escape') {
    //             modal.style.display = 'none'
    //         }
    //     })
    // }, [])
    const checkUserIsExist = async (e) => {
        e.preventDefault();
        const loginConfig = { master: user.userEmail }
        setUserExistClick('clicked');
        if (user.userEmail && user.userEmail.includes("@")) {
            // setUser({ ...user, userEmail: user.userEmail })
            setUemail(user.userEmail);
            // console.log("user", user.userEmail)
        }
        else if (user.userEmail && !user.userEmail.includes("@")) {
            setUmobile(user.userEmail);
            // console.log("user1", user.mobile);
        }
        setUser({ ...user, mobile: umobile })
        setUser({ ...user, userEmail: uemail })
        // setUser({ ...user, userEmail: uemail, mobile: umobile });
        // console.log("userr", user.mobile);
        // var emailRegex = document.getElementById('txtEmail');


        // var email = document.getElementById('txtEmail');
        // var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
        // if (email.value === "") {
        //     alert("  Please enter your Email or Phone Number  ");
        // }
        // else if (!mailFormat.test(email.value)) {
        //     alert("  Email Address / Phone number is not valid, Please provide a valid Email or phone number ");
        //     return false;
        // }

        // if (user.userEmail.includes('@')) {
        //     // user.userEmail
        //     // console.log("fal",true)
        //     setUser({ ...user, userEmail: e.target.value })
        //     console.log("fal", user.userEmail);
        // }
        // else {
        //     // console.log("fal",false)
        //     setUser({ ...user,userEmail: '', mobile: e.target.value  })
        //     console.log("fal", user.mobile)
        // }
        const response = await dispatch(LogingFetchData(loginConfig))
        if (response?.status && response?.type === 'login') {
            setAPICall(true);
            $('.signin-section').find('.otpform').hide();
            $('.signin-section').find('.main-form').hide();
            $('.signin-section').find('.signupform').hide();
            $('.signin-section').find('.signinform').show();
            // $('.signin-section').find('.main-form').hide();
            // $('.signin-section').find('.signupform').hide();
            // $('.signin-section').find('.signinform').hide();
            // $('.signin-section').find('.otpform').show();
        } else {
            // setUserExistClick('clicked');
            setUserId(response?.userid);
            if (response?.type === 'register') {
                $('.signin-section').find('.main-form').hide();
                $('.signin-section').find('.signupform').hide();
                $('.signin-section').find('.signinform').hide();
                $('.signin-section').find('.otpform').show();
            } else {
                setMessage(response?.message)
                setTimeout(() => {
                    setMessage(undefined)
                }, 5000);
            }
            // $('.signin-section').find('.main-form').hide();
            // $('.signin-section').find('.signinform').hide();
            // $('.signin-section').find('.signupform').show();
            // $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
            // $('.signin-section').find('.signupform').find('.secondary-link').show();
            // $('.signin-section').find('.signupform').find('input[name=mobile]').val(user.userEmail);
        }
        setUserExistClick('');
    }

    const closeModal = () => {
        $('.login-main-box').removeClass('active');
        $('.signin-section').find('.signinform').hide();
        $('.signin-section').find('.signupform').hide();
        $('.signin-section').find('.main-form').show();
    }
    // const [recaptchaVal, setRecaptchaVal] = useState('');

    const SignupHandler = async (e) => {
        e.preventDefault();
        // if (user.mobile.length === 10) {
        // if (!user.mobile || user.mobile.trim() === "") {
        //     return "Mobile number is Required";
        // } 
        // else
        // if (user?.mobile.match(/^[6-9]\d{9}$/)) {
        // if (user?.mobile.match(/^[6-9]\d{9}$/)) {
        // return "Enter a valid mobile number.";
        // setError('Enter a valid mobile number.');
        // if (!error?.length) {

        if (document.getElementsByClassName('g-recaptcha-response')[0].value === '') {
            document.getElementById('g-recaptcha-error').innerHTML = '<span style="color:red;">This Field is Required.</span>';
        }
        // if (document.getElementsByClassName('recaptcha-checkbox-checkmark')) {
        else {
            document.getElementById('g-recaptcha-error').innerHTML = '';

            const configSignupData = {
                id: userId,
                mobile: umobile ? umobile : user?.mobile,
                name: user?.fullName,
                email: uemail ? uemail : user?.userEmail,
                password: user?.password,
                cpassword: user?.confirmPassword
            }
            const response = await dispatch(SignupFetchData(configSignupData))
            if (response?.status) {
                const configSignin = {
                    userid: response?.user_data?.id,
                    password: response?.user_data?.password
                }
                dispatch(SigninFetchData(configSignin))
                setMessage(response?.message)
                setTimeout(() => {
                    setMessage(undefined)
                    closeModal();
                }, 1000);
                props.success('success');
            } else {
                setMessage(response?.message)
                setTimeout(() => {
                    setMessage(undefined)
                }, 1000);
                props.success('success');
            }
        }
        // }
        // }
        // // else {
        // //     return "";
        // // }
        // else {
        //     // alert('dont match')
        //     // document.getElementById('mobile-errmsg').innerHTML = '<span style="color:red;">This Field is Required.</span>';
        //     setError('Enter a Valid Mobile No.')

        // }

        // var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

        // if (emailRegex.test(user.userEmail)) {
        //     //it's an email address
        //     setUser({ ...user, userEmail: user.userEmail })
        //     console.log("user.userEmail", user.userEmail);
        // }
        // else {
        //     //it's a phone number
        //     setUser({ ...user, mobile: user.userEmail })
        //     console.log("user.mobile", user.mobile);
        // }
        // }

        // }
        // // setRecaptchaVal(document.getElementById('g-recaptcha-response').value);
        // e.preventDefault();
        // // alert('asd' + document.getElementById('g-recaptcha-response').value)
        // // useEffect(() => {
        // //     setRecaptchaVal()
        // //     console.log('recaptchaVal', recaptchaVal)
        // // }, [])

        // // if (document.getElementById('g-recaptcha-response').value === '') {
        // //     // e.preventDefault();
        // //     document.getElementById('g-recaptcha-error').innerHTML = '<span style="color:red;">This Field is Required.</span>';
        // //     // return false;
        // // }
        // // else {
        // // e.preventDefault();
        // // document.getElementById('g-recaptcha-error').innerHTML = '';

        // const configSignupData = {
        //     id: userId,
        //     mobile: user?.mobile,
        //     name: user?.fullName,
        //     email: user?.userEmail,
        //     password: user?.password,
        //     cpassword: user?.confirmPassword
        // }
        // const response = dispatch(SignupFetchData(configSignupData))
        // if (response?.status) {
        //     const configSignin = {
        //         userid: response?.user_data?.id,
        //         password: response?.user_data?.password
        //     }
        //     dispatch(SigninFetchData(configSignin))
        //     setMessage(response?.message);
        //     setTimeout(() => {
        //         closeModal();
        //         setMessage(undefined)
        //     }, 5000);
        // } else {
        //     setMessage(response?.message)
        //     setTimeout(() => {
        //         setMessage(undefined)
        //     }, 5000);
        // }
        // // }
    };
    // var aa = document.getElementById('g-recaptcha-response').value;
    // console.log("aa", aa)
    const SigninHandler = async (e) => {
        e.preventDefault();
        const configSignin = {
            userid: userId,
            password: user?.password
        }
        setAPICall(false);
        const res = await dispatch(SigninFetchData(configSignin));
        if (res?.status) {
            $('.login-main-box').removeClass('active');
            $('.signin-section').find('.signinform').hide();
            $('.signin-section').find('.signupform').hide();
            $('.signin-section').find('.main-form').show();
            closeModal();
            props.success('success');
        } else {
            setMessage(res?.message)
            setTimeout(() => {
                setMessage(undefined)
            }, 5000);
        }
    };

    // $(document).on("click", ".contibtn", function(e) {
    //     e.preventDefault();
    //     $('.signin-section').find('.main-form').hide();
    //     $('.signin-section').find('.signupform').hide();
    //     $('.signin-section').find('.signinform').hide();
    //     $('.signin-section').find('.otpform').show();

    // });


    const OTPHandler = async (e) => {
        e.preventDefault();
        const configOtpData = {
            id: userId,
            otp: user?.otp
        }
        if (!userLoginData) {
            setUserLoginData(checkUserId?.data);
        }
        const res = await dispatch(LogingFetchData(configOtpData, 'otp'));
        if (res?.status && (userLoginData?.type === 'login' || checkUserId?.data?.type === 'login')) {
            setAPICall(true);
            $('.signin-section').find('.otpform').hide();
            $('.signin-section').find('.main-form').hide();
            $('.signin-section').find('.signupform').hide();
            $('.signin-section').find('.signinform').show();
        } else {
            if (res?.status && (userLoginData?.type === 'register' || checkUserId?.data?.type === 'register')) {
                $('.signin-section').find('.otpform').hide();
                $('.signin-section').find('.main-form').hide();
                $('.signin-section').find('.signinform').hide();
                $('.signin-section').find('.signupform').show();
                $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
                $('.signin-section').find('.signupform').find('.secondary-link').show();
                $('.signin-section').find('.signupform').find('input[name=mobile]').val(user.userEmail);
            } else {
                setAPICall(false);
                setMessage(res?.message)
                setTimeout(() => {
                    setMessage(undefined)
                }, 5000);
            }
        }
        // $('.signin-section').find('.main-form').hide();
        // $('.signin-section').find('.signupform').hide();
        // $('.signin-section').find('.signinform').show();
        // $('.signin-section').find('.signinform').find('.secondary-link').show();
        // $('.signin-section').find('.signinform').find('input[name=emailId]').val(user.userEmail);
    }

    const forgetPasswordHandler = async (e) => {
        e.preventDefault();
        const configUserForgetPassword = {
            // master: user.userEmail
            id: userId,
            otp: user.otp,
            password: user.password
        }
        const response = await dispatch(updatePassword(configUserForgetPassword))
        // setMessage(response?.message)
        if (response?.status) {
            setMessage(response?.message)
            setTimeout(() => {
                setMessage(undefined)
                $('.signin-section').find('.otpform').hide();
                $('.signin-section').find('.main-form').hide();
                $('.signin-section').find('.signupform').hide();
                // $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
                $('.signin-section').find('.signupform').hide();
                $('.signin-section').find('.forgetPasswordForm').hide();
                $('.signin-section').find('.signinform').show();
                setUser({ otp: '', password: '' });
                // setUser({ otp: '', });
            }, 1000)
        }
        else {
            setMessage(response?.message)
            setTimeout(() => {
                setMessage(undefined)
            }, 1000)
        }
    }
    const viewForgetPasswordScreen = async (e) => {
        if (uemail?.length > 8 || umobile?.length > 8 || user?.userEmail?.length > 8 || user?.mobile?.length > 8) {
            //     const loginConfig = { master: user.userEmail }
            // const response = await dispatch(LogingFetchData(loginConfig))
            // if (response?.status && response?.type === 'login') {
            // let qw = user.userEmail ? user.userEmail : user.mobile
            e.preventDefault();
            const configUserForgetPassword = {
                master: user?.userEmail
            }
            const response = await dispatch(forgetPassword(configUserForgetPassword))
            if (response?.status) {
                // setMessage(response?.message)
                // setUser({ ...user, userid: '' })
                setUserId(response?.userid);
                $('.signin-section').find('.otpform').hide();
                $('.signin-section').find('.main-form').hide();
                $('.signin-section').find('.signinform').hide();
                $('.signin-section').find('.signupform').hide();
                // $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
                $('.signin-section').find('.signupform').hide();
                $('.signin-section').find('.forgetPasswordForm').show();
                $('.signin-section').find('.userEmailforgetpassword').hide();
                // setUser({ otp: '', });   
                // setTimeout(() => {
                //     setMessage(undefined)
                // }, 2000)
            } else {
                setMessage(response?.message)
                setTimeout(() => {
                    setMessage(undefined)
                }, 2000)
            }
            // $('.signin-section').find('.otpform').hide();
            // $('.signin-section').find('.main-form').hide();
            // $('.signin-section').find('.signinform').hide();
            // $('.signin-section').find('.signupform').hide();
            // // $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
            // $('.signin-section').find('.signupform').hide();
            // $('.signin-section').find('.forgetPasswordForm').hide();
            // $('.signin-section').find('.userEmailforgetpassword').show();
            // $('.signin-section').find('.signupform').find('input[name=mobile]').val(user.userEmail);
            // }
        }
        else {
            setMessage("Please Enter Email/Password")
            setTimeout(() => {
                setMessage(undefined)
            }, 5000)
        }
    }

    const viewForgetPasswordScreenSignin = async (e) => {
        if (uemail?.length > 8 || umobile?.length > 8 || user?.userEmail?.length > 8 || user?.mobile?.length > 8) {
            //     const loginConfig = { master: user.userEmail }
            // const response = await dispatch(LogingFetchData(loginConfig))
            // if (response?.status && response?.type === 'login') {
            // let qw = user.userEmail ? user.userEmail : user.mobile
            e.preventDefault();
            const configUserForgetPassword = {
                master: umobile ? umobile : uemail
            }
            const response = await dispatch(forgetPassword(configUserForgetPassword))
            if (response?.status) {
                // setMessage(response?.message)
                // setUser({ ...user, userid: '' })
                setUserId(response?.userid);
                $('.signin-section').find('.otpform').hide();
                $('.signin-section').find('.main-form').hide();
                $('.signin-section').find('.signinform').hide();
                $('.signin-section').find('.signupform').hide();
                // $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
                $('.signin-section').find('.signupform').hide();
                $('.signin-section').find('.forgetPasswordForm').show();
                $('.signin-section').find('.userEmailforgetpassword').hide();
                // setUser({ otp: '', });   
                // setTimeout(() => {
                //     setMessage(undefined)
                // }, 2000)
            } else {
                setMessage(response?.message)
                setTimeout(() => {
                    setMessage(undefined)
                }, 2000)
            }
            // $('.signin-section').find('.otpform').hide();
            // $('.signin-section').find('.main-form').hide();
            // $('.signin-section').find('.signinform').hide();
            // $('.signin-section').find('.signupform').hide();
            // // $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
            // $('.signin-section').find('.signupform').hide();
            // $('.signin-section').find('.forgetPasswordForm').hide();
            // $('.signin-section').find('.userEmailforgetpassword').show();
            // $('.signin-section').find('.signupform').find('input[name=mobile]').val(user.userEmail);
            // }
        }
        else {
            setMessage("Please Enter Email/Password")
            setTimeout(() => {
                setMessage(undefined)
            }, 5000)
        }
    }

    const checkUserExistForgetPassword = async (e) => {
        e.preventDefault();
        const configUserForgetPassword = {
            master: user.userEmail
        }
        const response = await dispatch(forgetPassword(configUserForgetPassword))
        if (response?.status) {
            // setUser({ ...user, userid: '' })
            setUserId(response?.userid);
            $('.signin-section').find('.otpform').hide();
            $('.signin-section').find('.main-form').hide();
            $('.signin-section').find('.signinform').hide();
            $('.signin-section').find('.signupform').hide();
            // $('.signin-section').find('.heading').find('span').text('Complete Your Profile');
            $('.signin-section').find('.signupform').hide();
            $('.signin-section').find('.forgetPasswordForm').show();
            $('.signin-section').find('.userEmailforgetpassword').hide();
        }
    }
    function showPasswordsignup() {
        var x = document.getElementById("signuppassword");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }

    }
    function showconPasswordsignup() {
        var x = document.getElementById("signupconfirmpassword");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    function showPasswordlogin() {
        var x = document.getElementById("loginpassword");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    function showPasswordforget() {
        var x = document.getElementById("forgetpassword");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    // const email = user.userEmail.includes('@');
    // const mobile = !user.userEmail.includes('@');

    // if(user.userEmail.includes('@')){
    // setUser({...user, userEmail})
    // }

    const maxLengthCheck = (e) => {
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        }
    }

    return (
        <>
            <section className="login">
                <div className="container ">
                    <div className="login-main-box active uppermodel fix-top" style={{ position: 'fixed' }}>
                        <div className="row my-3">
                            <a onClick={closeModal} id="LoginModalComponent" className="closebtn">
                                <i className="fa fa-times icon-color-times" />
                            </a>
                            <button type="button" className="btn btn-primary profile" style={{ position: "fixed", top: "10px", zIndex: "99" }}>
                                <Link to="/profile">  <i className="fas fa-user" style={{ color: "#fff" }}></i> </Link>
                            </button>
                        </div>
                        <div className="inn-block">
                            <div className="signin-section">
                                {/* <h4 className="heading small"><span>or go the traditional way</span></h4> */}
                                <form onSubmit={checkUserIsExist} className="form main-form" autocomplete="off">
                                    <ul>
                                        <li className="input-field user">
                                            <p><input value={user.userEmail} onChange={(e) => setUser({ userEmail: e.target.value })} autocomplete="off" type="text" name="emailId" className="checkno" placeholder="Sign In/Sign Up with Email or Mobile No." maxlength="100" /></p>
                                            <div className="errorMsg"></div>
                                            <a href="javascript:void(0)" id="changeEmailIdDiv" className="secondary-link" style={{ display: "none" }}>Change Email Or Mobile No.</a>
                                        </li>
                                        <li id="sso-signInButtonDiv" className="submit">
                                            {/* <input type="submit" className={`submit-btn contibtn ${user.userEmail?.length >= 10 ? '' : 'disabled'}`} value="Continue" disabled={`${user.userEmail?.length >= 10 && apiCall === false ? '' : 'disabled'}`} /></li> */}
                                            <input type="submit" className={`submit-btn contibtn ${user.userEmail?.length >= 10 ? '' : 'disabled'}`} value="Continue" disabled={`${userExistClick === 'clicked' || user.userEmail?.length <= 9 ? 'disabled' : ''}`} /></li>
                                        {/* <input type="submit" className={`submit-btn contibtn ${user.userEmail?.length >= 1 ? '' : 'disabled'}`} value="Continue" disabled={`${user.userEmail?.length >= 10 ? '' : 'disabled'}`} /></li> */}
                                    </ul>
                                    <a onClick={viewForgetPasswordScreen} href="javascript:void(0)" id="sso-forgot-pass" className="forget-password">Forgot Password?</a>
                                    {message && <span className='forgotMsg'>{message}</span>}

                                    <div className="socialConnectImgDiv">
                                        <FacebookLogin
                                            className="my-3"
                                            // appId="608818120411179"
                                            appId="373101924721157"
                                            // autoLoad={true}
                                            buttonText="Sign in with Facebook"
                                            fields="name,email,picture"
                                            // onClick={componentClicked}
                                            callback={responseFacebook}
                                            onSuccess={responseFacebook}
                                            render={renderProps => (
                                                <button onClick={renderProps.onClick} type="button" id="sso-fb-login" className="fb-btn">Sign in with Facebook</button>
                                            )}
                                        >
                                        </FacebookLogin>
                                        {/* </LoginSocialFacebook> */}
                                        <span id="sso-fblogin-error" className="errorMsg"></span>
                                        <GoogleLogin
                                            className="my-3"
                                            clientId="834904142648-0i3hktukk3t4egeqp1lop4jt4nfjmo7o.apps.googleusercontent.com"
                                            buttonText="Sign in with Google"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                        >
                                            {/* <button type="button" id="sso-gplus-login" className="gplus-btn">Sign in with Google</button> */}
                                        </GoogleLogin>
                                        <span id="sso-gplus-error" className="errorMsg"></span>
                                    </div>
                                </form>
                                <form onSubmit={SigninHandler} className="form signinform" autocomplete="off" style={{ display: "none" }}>
                                    <ul>
                                        <li className="input-field email user">
                                            {/* {
                                                user.userEmail === '' ? */}
                                            <p><input required value={user.userEmail ? user.userEmail : user.mobile} onChange={(e) => setUser({ ...user, userEmail: e.target.value })} autocomplete="off" type="text" name="emailId" placeholder="Sign In/Sign Up with Email or Mobile No." maxlength="100" disabled /></p>
                                            {/* : <p><input required value={user.mobile} onChange={(e) => setUser({ ...user, userEmail: e.target.value })} autocomplete="off" type="text" name="emailId" placeholder="Sign In/Sign Up with Email or Mobile No." maxlength="100" disabled /></p>
                                            } */}
                                            <div className="errorMsg" style={{ display: "none" }}></div>
                                            {/* <a href="javascript:void(0)" id="changeEmailIdDiv" className="secondary-link">Change Email Or Mobile No.</a> */}
                                        </li>
                                        <li className="input-field password" id="sso-pwdDiv" style={{ display: "list-item" }}>
                                            <p><input required value={user.password} id="loginpassword" onChange={(e) => setUser({ ...user, password: e.target.value })} autocomplete="off" type="password" name="password" placeholder="Password" maxlength="14" /><span className="view-password" onClick={showPasswordlogin}>
                                                {visiblelogin === false ? <i className="fas fa-eye" onClick={() => setVisiblelogin(true)}></i> : ''}
                                                {visiblelogin === true ? <i className="fas fa-eye-slash" onClick={() => setVisiblelogin(false)}></i> : ''}
                                            </span></p>

                                            {/* <div className="errorMsg"></div><a id="sso-generate-otp" href="javascript:void(0)" className="secondary-link">Generate OTP to Login</a> */}
                                        </li>
                                        {message && <p style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{message}</p>}
                                        <li id="sso-login-otp-msg" className="text-field">
                                            <p></p>
                                        </li>
                                        <li className="input-field password" id="sso-otpLoginDiv">
                                            <p><input type="password" name="otplogin" maxlength="6" placeholder="Enter the Verification code" /></p>
                                            <div className="errorMsg"></div>
                                            <div className="successMsg"></div><span className="regenerate-otp">Didn't receive OTP?</span><a id="sso-regenerate-otp" href="javascript:void(0)" className="secondary-link">Re-Generate OTP</a>
                                        </li>
                                        {/* <li className="checkbox">
                                            <p className="row">
                                                <input className="col-1 js-contentCB" type="checkbox" required id="agree" name="agree" />
                                                <label className="col-11" htmlFor="agree" >I warrant that I am 18 years old, and agree with the
                                                    <Link to="/page/terms-conditions" rel="noopener noreferrer">
                                                        Terms &amp; Conditions </Link>
                                                    and
                                                    <Link to="page/privacy-policy" rel="noopener noreferrer"> Privacy Policy </Link>
                                                    of Sandesh Ltd.
                                                    <span className="mandatory-field">*</span>
                                                </label>
                                            </p>
                                            <div className="errorMsg"></div>
                                        </li>
                                        <li className="checkbox">
                                            <p className="row">
                                                <input className="col-1 js-contentCB" type="checkbox" required id="sharedDataAllowed" name="sharedDataAllowed" />
                                                <label className="col-11" htmlFor="sharedDataAllowed">I agree to a single sign on for seamless experience across Sandesh Ltd.<span className="mandatory-field">*</span></label></p>
                                            <div className="errorMsg"></div>
                                        </li> */}
                                        <li id="sso-signInButtonDiv" className="submit"><input type="submit" className="submit-btn" value="Sign In" /></li>
                                    </ul>
                                    <a onClick={viewForgetPasswordScreenSignin} href="javascript:void(0)" id="sso-forgot-pass" className="forget-password">Forgot Password?</a>
                                    <div className="socialConnectImgDiv">
                                        <button type="button" id="sso-fb-login" className="fb-btn">Sign in with Facebook</button>
                                        <span id="sso-fblogin-error" className="errorMsg"></span>
                                        <button type="button" id="sso-gplus-login" className="gplus-btn">Sign in with Google</button><span id="sso-gplus-error" className="errorMsg"></span>
                                    </div>
                                </form>
                                <form onSubmit={OTPHandler} className="form otpform" autocomplete="off" style={{ display: 'none' }}>
                                    <ul>
                                        <li className="input-field otpfiled">
                                            <p><input name="otp" onChange={(e) => setUser({ otp: e.target.value })} autocomplete="off" placeholder="OTP" type="text" value={user.otp} /></p>
                                        </li>
                                        <li className="submit"><input type="submit" className="submit-btn otpbtn" value="Submit" /></li>
                                        {message && <span style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{message}</span>}
                                    </ul>
                                </form>
                                <form onSubmit={checkUserExistForgetPassword} className="form userEmailforgetpassword" autocomplete="off" style={{ display: 'none' }}>
                                    <ul>
                                        <li className="input-field otpfiled">
                                            <p><input name="userEmail" onChange={(e) => setUser({ ...user, userEmail: e.target.value })} autocomplete="off" placeholder="Enter User Email / Password" type="text" value={user.userEmail} /></p>
                                        </li>
                                        <li className="submit"><input type="submit" className="submit-btn otpbtn" value="Submit" disabled={user.userEmail?.length > 5 ? '' : true} /></li>
                                        {message && <span style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{message}</span>}
                                    </ul>
                                </form>

                                <form onSubmit={forgetPasswordHandler} className="form forgetPasswordForm" autocomplete="off" style={{ display: 'none' }}>
                                    <ul>
                                        <li className="input-field">
                                            We Have Sent a 4 Digit Verification Code on {user?.userEmail ? user?.userEmail : uemail}{umobile}
                                        </li>
                                        <li className="input-field otpfiled">
                                            <p><input name="otp" onChange={(e) => setUser({ ...user, otp: e.target.value })} autoComplete="off" placeholder="Enter The Varification Code" type="text" value={user.otp} /></p>
                                        </li>
                                        <li className="input-field otpfiled">
                                            <p className="eyeissue"><input name="password" id="forgetpassword" onChange={(e) => setUser({ ...user, password: e.target.value })} autocomplete="off" placeholder="Enter New Password" type="password" value={user.password} /><span className="view-password" onClick={showPasswordforget}>
                                                {Visibleforget === false ? <i className="fas fa-eye" onClick={() => setVisibleforget(true)}></i> : ''}
                                                {Visibleforget === true ? <i className="fas fa-eye-slash" onClick={() => setVisibleforget(false)}></i> : ''}
                                            </span></p>
                                        </li>
                                        <li className="submit"><input disabled={`${((user.otp?.length > 0) && !message && (user.password?.length > 0)) ? '' : 'disabled'}`} type="submit" className="submit-btn otpbtn" value="Submit" /></li>
                                        {message && <span style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{message}</span>}
                                    </ul>
                                </form>
                                <form onSubmit={SignupHandler} className="form signupform" autocomplete="off" style={{ display: "none" }}>
                                    <ul>
                                        <li className={`input-field email`}>

                                            {/* <p><span className="country-code">+91 - </span><input disabled type="number" value={user.user} onChange={(e) => setUser({ ...user, mobile: e.target.value })} autocomplete="off" name="mobile" /></p> */}
                                            <p><input required disabled={uemail !== ""} value={uemail ? uemail : user.userEmail} onChange={(e) => setUser({ ...user, userEmail: e.target.value })} autocomplete="off" type="text" name="emailId" maxlength="100" placeholder="Email" /></p>
                                            {/* <a href="javascript:void(0)" id="changeRegisterEmailId" className="secondary-link">Change Email Or Mobile No.</a> */}
                                            {/* {
                                                user.mobile?.length > 10 || user.mobile?.length < 10 ?
                                                <span style={{ color: 'red' }}>Please Enter Valid Mobile No.</span> : ''
                                            } */}
                                        </li>
                                        <li className="input-field user-name">
                                            <p><input required value={user.fullName} onChange={(e) => setUser({ ...user, fullName: e.target.value })} autocomplete="off" type="text" name="fullname" placeholder="Full Name" maxlength="30" /></p>
                                            <div className="errorMsg"></div>
                                        </li>
                                        <li className="input-field password">
                                            <p className="eyeissue"><input required autocomplete="off" id="signuppassword" value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} type="password" name="registerPwd" placeholder="Password" maxlength="14" /><span className="view-password" onClick={showPasswordsignup}>
                                                {visiblesignup === false ? <i className="fas fa-eye" onClick={() => setVisiblesignup(true)}></i> : ''}
                                                {visiblesignup === true ? <i className="fas fa-eye-slash" onClick={() => setVisiblesignup(false)}></i> : ''}
                                            </span></p>

                                        </li>
                                        <li className="input-field password">
                                            <p><input required value={user.confirmPassword} id="signupconfirmpassword" onChange={(e) => setUser({ ...user, confirmPassword: e.target.value })} autocomplete="off" type="password" name="registerCnfrmPwd" placeholder="Confirm Password" maxlength="14" /><span className="view-password" onClick={showconPasswordsignup}>
                                                {visibleConsignup === false ? <i className="fas fa-eye" onClick={() => setVisibleConsignup(true)}></i> : ''}
                                                {visibleConsignup === true ? <i className="fas fa-eye-slash" onClick={() => setVisibleConsignup(false)}></i> : ''}</span></p>
                                            <div className="errorMsg"></div>
                                        </li>
                                        {/* {email ? */}
                                        <li className="input-field mobile-no">
                                            <p><span className="country-code">+91 - </span><input required disabled={umobile !== ""} value={umobile ? umobile : user.mobile} onChange={(e) => setUser({ ...user, mobile: e.target.value })} autocomplete="off" type="number" name="emailId" maxLength={10} onInput={maxLengthCheck} placeholder="Mobile No." /></p>
                                            <div className="errorMsg" id="mobile-errmsg"></div>
                                            {/* <span className='color-red text-center'>{error}</span> */}
                                        </li>
                                        {/* {
                                            user.userEmail?.length > 10 || user.mobile?.length < 10 ?
                                                <span className='my-3' style={{ color: 'red' }}>Please Enter Valid Mobile No.</span> : ''
                                        } */}
                                        {/* :
                                        <li className={`input-field email`}>
                                            <p><input value={user.userEmail} onChange={(e) => setUser({ ...user, userEmail: e.target.value })} autocomplete="off" type="text" name="emailId" maxlength="100" placeholder="Email (Optional)" /></p>
                                        </li> */}
                                        {/* } */}
                                        <li className="recaptcha-wrapper">
                                            <div id="recaptcha-container">
                                                <div style={{ width: "304px", height: "78px" }}>
                                                    {/* <div><iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LcXeh0TAAAAAO1DsEX1iEF8n8-E_hQB67bIpxIw&amp;co=aHR0cHM6Ly90aW1lc29maW5kaWEuaW5kaWF0aW1lcy5jb206NDQz&amp;hl=en&amp;v=TDBxTlSsKAUm3tSIa0fwIqNu&amp;theme=light&amp;size=normal&amp;cb=f0xllkqe7mmo" width="304" height="78" role="presentation" name="a-41wvhf103e0l" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe></div> */}
                                                    <Helmet>
                                                        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                                                    </Helmet>
                                                    {/* <div required id="recaptcha" className="g-recaptcha" data-sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR"></div> */}
                                                    <div className="g-recaptcha" data-sitekey="6LcoUMEeAAAAAOUUw6Cf5_jLHzxVb9MZjsXKGMlz" data-callback="verifyCaptcha"></div>
                                                    <div id="g-recaptcha-error"></div>
                                                    <br />
                                                    {/* <textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response" style={{ width: "250px", height: "40px", border: "1px solid rgb(193, 193, 193)", margin: "10px 25px", padding: "0px", resize: "none", display: "none" }}></textarea> */}
                                                </div>
                                                {/* <iframe style={{ display: "none" }}></iframe> */}
                                            </div>
                                            <div className="errorMsg"></div>
                                        </li>
                                        <li className="checkbox">
                                            <p className="row">
                                                <input className="col-1 js-contentCB" type="checkbox" required id="agree" name="agree" />
                                                <label className="col-11" htmlFor="agree" >I warrant that I am 18 years old, and agree with the
                                                    <Link to="/page/terms-conditions" rel="noopener noreferrer">
                                                        Terms &amp; Conditions </Link>
                                                    and
                                                    <Link to="page/privacy-policy" rel="noopener noreferrer"> Privacy Policy </Link>
                                                    of Sandesh Ltd.
                                                    <span className="mandatory-field">*</span>
                                                </label>
                                            </p>
                                            <div className="errorMsg"></div>
                                        </li>
                                        <li className="checkbox">
                                            <p className="row">
                                                <input className="col-1 js-contentCB" type="checkbox" required id="sharedDataAllowed" name="sharedDataAllowed" />
                                                <label className="col-11" htmlFor="sharedDataAllowed">I agree to a single sign on for seamless experience across Sandesh Ltd.<span className="mandatory-field">*</span></label></p>
                                            <div className="errorMsg"></div>
                                        </li>
                                        <li className="submit"><input type="submit" id="sso-registerBtn btn-validate" className="submit-btn" value="Update" /></li>
                                        {message && <span className="msg-error error" style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>{message}</span>}
                                    </ul>
                                </form>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

export default Login
