/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import CategoryTaboola from '../subcomponents/CategoryTaboola';
import HomeTaboola from '../subcomponents/HomeTaboola';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import camera from '../../assets/camera.png';
import { CategoryFetchData } from '../../redux/actions/categoryAction'
import { homePrimaryFetchData } from '../../redux/actions/homePrimaryAction';
import { Helmet } from 'react-helmet';
import InjectScript from '../CustomScript';
import '../../styles/custom.css'
import HelperScript from '../../utils/helper';
import ReactGA from "react-ga4";
import CommonContent from "../CommonContent";
import TaboolaComponent from '@taboola/taboola-react-web'
// import YouTube from 'react-youtube';
import {sportScoreFetchData} from '../../redux/actions/sportScoreAction'
import Dailymotion from 'react-dailymotion';
import Carousel from 'react-multi-carousel';
import { useRef } from 'react';


const totalNum_recored = 24; // 24

const Sport = (props) => {
    const urlParam = new URLSearchParams(window.location.search);
    const myParamPage = parseInt(urlParam.get('page'));

    const [activePage, setActivePage] = React.useState(myParamPage ? parseInt(myParamPage) : 1);
    
    const sports = useSelector((state) => state?.SportScoreReducer?.data)
    const fixtures = useSelector((state) => state?.SportScoreReducer?.data?.fixtures)
    const gallery = useSelector((state) => state?.SportScoreReducer?.data?.gallery)
    const video = useSelector((state) => state?.SportScoreReducer?.data?.video)
    const [activeTab, setActiveTab] = useState(1);
    const [sportData, setSportData] = useState();
    const [vidId, setvidId] = useState('');
    const [vidTitle, setvidTitle] = useState('');
    const [vidTime, setvidTime] = useState('');
    // console.log('activePageactivePageactivePage', activePage);
    const [pageArray, setPageArray] = React.useState([]);
    const homePrimary = useSelector((state) => state?.HomePrimaryReducer.data);
    const innerData = useSelector((state) => state?.CategoryReducer?.data?.posts)
    const pageCount = useSelector((state) => state?.CategoryReducer?.data?.count)
    const pageName = useSelector((state) => state?.CategoryReducer?.data?.name)
    const loading = useSelector((state) => state?.CategoryReducer?.loading)
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);

    const { url, country, state, city } = useParams();
    let dispatch = useDispatch();
    const config = {
        page: activePage,
        limit: totalNum_recored,
    }
    // useEffect(() => {
    //     if (state || city || country || url) {
    //         dispatch(CategoryFetchData(props.type, config, url + `sports?page=${activePage}&limit=${totalNum_recored}`))
    //     }
    // }, []);

    useEffect(() => {
        if (pageCount) {
            const checkPageSize = Math.ceil(pageCount / totalNum_recored)
            const array = Array.from(Array(checkPageSize).keys())
            setPageArray(array);
        }
        ReactGA.event({ category: 'Category Page', action: 'Category', label: innerData?.name });
    }, [innerData, pageCount, url])


    let navigate = useNavigate();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = parseInt(urlParams.get('page'));
        if (myParam !== parseInt(activePage)) {
            if (parseInt(activePage) === 1) {
                navigate(window.location.pathname);
            } else {
                navigate(window.location.pathname + '?page=' + activePage);
            }
        }
        window.scrollTo(0, 0);
        dispatch(CategoryFetchData(props.type, config, `t20-world-cup?page=${activePage}&limit=24`))
        ReactGA.event({ category: 'Category Page', action: 'Category', label: innerData?.name });
    }, [activePage]);


    const VideoModalOpen = (VidDataId, VidDataTitle, VidDataTime) => {
        setvidId(VidDataId);
        setvidTitle(VidDataTitle);
        setvidTime(VidDataTime);
        document.getElementById("videoPoPup").classList.add('active-modal');
        document.body.style.overflow = 'hidden';
    }

    const VideoModalClose = () => {
        setvidId('');
        document.body.style.overflow = 'auto';
        document.getElementById("videoPoPup").classList.remove('active-modal');
    }

    const taboolacontent = '' +
        '' +
        // '<div id="taboola-right-rail-thumbnails"></div>' +
        '' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-rr'," +
        "container: 'taboola-right-rail-thumbnails'," +
        "placement: 'Right Rail Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '';

    const businessWidget1 = '' +
        '<div style="height:433px; background-color: #FFFFFF; overflow:hidden; box-sizing: border-box; border: 1px solid #56667F; border-radius: 4px; text-align: right; line-height:14px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #56667F; padding: 0px; margin: 0px; width: 100%;"><div style="height:413px; padding:0px; margin:0px; width: 100%;"><iframe src="https://widget.coinlib.io/widget?type=full_v2&theme=light&cnt=6&pref_coin_id=1505&graph=yes" width="100%" height="409px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" style="border:0;margin:0;padding:0;"></iframe></div><div style="color: #FFFFFF; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"><a href="https://coinlib.io" target="_blank" style="font-weight: 500; color: #FFFFFF; text-decoration:none; font-size:11px">Cryptocurrency Prices</a>&nbsp;by Coinlib</div></div>' +
        '';

    const businessWidget2 = '' +
        '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div><div class="tradingview-widget-copyright"><a href="https://in.tradingview.com/markets/indices/" rel="noopener" target="_blank"><span class="blue-text">Indices</span></a> <span class="blue-text">and</span> <a href="https://in.tradingview.com/markets/currencies/" rel="noopener" target="_blank"><span class="blue-text">Forex</span></a> by TradingView</div>' +
        '<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js" async> {"colorTheme": "light","dateRange": "12M","showChart": false,"locale": "in","largeChartUrl": "","isTransparent": false,"showSymbolLogo": true,"showFloatingTooltip": false,"width": "400","height": "465","tabs": [ { "title": "Indices", "symbols": [ { "s": "FOREXCOM:SPXUSD","d": "S&P 500" }, {"s": "FOREXCOM:NSXUSD", "d": "US 100" },' +
        '{ "s": "FOREXCOM:DJI", "d": "Dow 30" }, { "s": "INDEX:NKY", "d": "Nikkei 225"  }, { "s": "INDEX:DEU40", "d": "DAX Index"},' +
        '{ "s": "FOREXCOM:UKXGBP", "d": "UK 100" },{"s": "BSE:SENSEX" }, { "s": "CAPITALCOM:NIFTY50" } ], "originalTitle": "Indices" },' +
        '{"title": "Forex","symbols": [ { "s": "FX:EURUSD", "d": "EUR/USD" }, { "s": "FX:GBPUSD", "d": "GBP/USD" }, {"s": "FX:USDJPY", "d": "USD/JPY" },' +
        '{"s": "FX:USDCHF","d": "USD/CHF"},{"s": "FX:AUDUSD","d": "AUD/USD"},{"s": "FX:USDCAD","d": "USD/CAD"}], "originalTitle": "Forex"}]}' +
        '</script> </div>' +
        '' +
        '';

    const businessWidget3 = '' +
        '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div>' +
        '<div class="tradingview-widget-copyright"><a href="https://in.tradingview.com/markets/stocks-india/" rel="noopener" target="_blank"><span class="blue-text">Stock Market Today</span></a> by TradingView</div>' +
        '<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js" async>' +
        '{ "colorTheme": "light","dateRange": "12M","exchange": "BSE","showChart": false,"locale": "in","largeChartUrl": "",' +
        ' "isTransparent": false,"showSymbolLogo": true,"showFloatingTooltip": false, "width": "400","height": "407"} </script> </div>' +
        '' +
        '';

    const Titlecapitalize = (str, lower = false) =>
        (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    ;


    const gallery57 = " <div id='div-gpt-ad-1646037902231-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646037902231-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037902231-0'); }); </script> </div>"
    const gallery58 = " <div id='div-gpt-ad-1646037931655-0' style='min-width: 300px; min-height: 250px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_CP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646037931655-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037931655-0'); }); </script> </div>"
    // const gallery201 = " <div id='div-gpt-ad-1646388561181-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388561181-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388561181-0'); }); </script> </div>"
    const gallery201 = ""+
    " <div id='div-gpt-ad-1646388561181-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388561181-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388561181-0'); }); </script> </div>"+
    // '<div id="gpt-passback">'+
    // '<script>'+
    // 'window.googletag = window.googletag || {cmd: []};'+
    // 'googletag.cmd.push(function() {'+
    // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());"+
    // 'googletag.enableServices();'+
    // "googletag.display('gpt-passback');"+
    // '});'+
    // '</script>'+
    // '</div>'+
    ''
    const gallery202 = " <div id='div-gpt-ad-1646388591019-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_CP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646388591019-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388591019-0'); }); </script> </div>"

    const afterPrimaryNewVDO  = "" +
        '<script defer id="vidcrunchJS632259138" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_ICF_Responsive_Playlist_190922.js">'+
        '</script>'+
        ''
    // const afterPrimary  = "" +
    //     '<div id = "v-sandesh-v0"></div>'+
    //     ''+
    //     '<script>'+
    //     '(function(v,d,o,ai){'+
    //     "ai=d.createElement('script');"+
    //     'ai.defer=true;'+
    //     'ai.async=true;'+
    //     'ai.src=v.location.protocol+o;'+
    //     'd.head.appendChild(ai);'+
    //     '})'+
    //     "(window, document, '//a.vdo.ai/core/v-sandesh-v0/vdo.ai.js');"+
    //     "</script>"+
    //     ''

    useEffect(() => {
        dispatch(sportScoreFetchData())
    },[])
    setTimeout(() => {
        if(!sportData && fixtures){
            setSportData(fixtures)
            setActiveTab(1);
        } 
        if(!sportData && !fixtures){
            setActiveTab(2);
            setSportData(sports.results)
        } 
    }, 10);
    // setTimeout(() => {
    // }, 20);
    // setTimeout(() => {
    //     if(!sportData && !fixtures && !sports.results){
    //         setSportData([])
    //     } 
    // }, 30);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1200, min: 991 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 991, min: 515 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 515, min: 0 },
            items: 1
        }
    };
    const responsiveGallery = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1200, min: 991 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 991, min: 515 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 515, min: 0 },
            items: 1
        }
    };
    let carousel = useRef(null);

    const sportNews = '' +
    "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>"+
    '<script>'+
    'window.googletag = window.googletag || {cmd: []};'+
    'googletag.cmd.push(function() {'+
    "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());"+
    'googletag.pubads().enableSingleRequest();'+
    'googletag.enableServices();'+
    '});'+
    '</script>'+
    '<script>'+
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });"+
    '</script>'+
    '</div>'+
    ''

    return (
        <>
            {(props.type === 'location') &&
                <Helmet>
                    <title>Location | Sandesh</title>
                </Helmet>
            }
            {pageName?.length &&
                <Helmet>
                    <title>{Titlecapitalize(pageName)} | Sandesh</title>
                </Helmet>}
            <div className="ad-section">
            </div>
            <section className="gujarat isCategoryPage" style={{ opacity: (loading) ? '0.5' : 1 }}>
                <div className="container sport">
                    <div className='row'>
                        <div className='col-lg-9'>
                            <div dangerouslySetInnerHTML={{ __html: sports.weblivescore }} />
                        </div>
                        <div className='col-lg-3'>
                            <div className="text-center">
                                <div className='ad-side-head '>
                                    <div className="ad-side m-0">
                                        <div className="ad-section">
                                            {
                                                window.innerWidth < 991 ?
                                                <InjectScript script={gallery201} />
                                                :
                                                <InjectScript script={gallery57} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            {sportData && <>
                            <div className='sport-score-head mb-4'>
                                    <p className='sport-score-title'><span class="badge badge-warning">MATCHES</span></p>
                                    <p className='sport-score-tabpane'>
                                        {sports.fixtures ? <span className={activeTab === 1 ? "active-sport-tab" : ''} onClick={() => {setActiveTab(1); setSportData(sports.fixtures); carousel.goToSlide(0)}}>
                                            Fixtures
                                        </span> : ''}
                                        {sports.results && <span className={activeTab === 2 ? "active-sport-tab" : ''} onClick={() => {setActiveTab(2);  setSportData(sports.results); carousel.goToSlide(0)}}>
                                            Results
                                        </span>}
                                    </p>
                                </div>
                                <div className='match-slider'>
                                    <Carousel
                                        swipeable={true}
                                        draggable={false}
                                        infinite={false}
                                        autoPlaySpeed={5000000}
                                        arrows={true}
                                        ref={el => (carousel = el)}
                                        renderArrowsWhenDisabled={true}
                                        showDots={false}
                                        ssr={true}
                                        // style={{ width: "100%" }}
                                        customTransition="transform 1100ms ease-in-out"
                                        responsive={responsive}
                                        
                                        >
                                            {sportData && sportData?.map((el) => (
                                                <div className={`match-card match${activeTab}`}>
                                                    <div class="match-card-top">
                                                        <h5 class="fix-text">
                                                            <span class="ng-binding">
                                                                {el?.event}
                                                            </span>
                                                        </h5>
                                                    </div>  
                                                    <div className='match-card-schedule my-3'>
                                                        <div>
                                                        {el?.date}
                                                        </div>
                                                        <div>
                                                            {el?.time}
                                                        </div>
                                                    </div>
                                                    <div className='match-card-results my-3'>
                                                        {el?.result} 
                                                    </div>
                                                    <div className='match-card-teams'>
                                                        <div className="match-card-team1">
                                                            <img className='w-100' src={assetbaseUrl?.value + el?.team1?.logo} alt="" />
                                                            <div className="text-center team-name my-3">{el?.team1?.name}</div>
                                                            {el?.team1?.score && <div className="text-center score-live">{el?.team1?.score} <span>({el?.team1?.over})</span></div>}
                                                        </div>
                                                        <b>VS</b>
                                                        <div className="match-card-team1">
                                                            <img className='w-100' src={assetbaseUrl?.value + el?.team2?.logo} alt="" />
                                                            <div className="text-center team-name my-3">{el?.team2?.name}</div>
                                                            {el?.team2?.score && <div className="text-center score-live">{el?.team2?.score} <span>({el?.team2?.over})</span></div>}
                                                        </div>
                                                    </div>
                                                    <div className='match-bottom-info my-3'>{el?.title}</div>
                                                </div>
                                            ))}
                                    </Carousel>
                                </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-9'>
                        <div class="single-blog">
                            <span class="badge badge-warning">News</span>
                            <Link class="readmore" to="/sports">Read More</Link>
                        </div>
                        {innerData?.length > 0 ? <div className="shadow p-3 mb-3 bg-white rounded">
                                <div className="row">
                                    <div className="col-xl-8 megapreView col-lg-12">
                                        {
                                            innerData?.length > 0 && innerData?.map((els, idx) => (
                                                <>
                                                    {idx === 0 &&
                                                    <>
                                                        {els?.post_type === 4 ? 
                                                            <a className="story cursor-pointer" onClick={(e) => { VideoModalOpen(els?.video?.split('/')?.reverse()[0], els?.title, els?.publish_date, e); }}>
                                                                <div className="caption mb-0">
                                                                    <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                    {els?.tagline ?
                                                                        <p className="button-hover colorspan-top-post">{els?.tagline}</p>
                                                                        : ""}
                                                                    <div className="categoty-main-post-title">
                                                                        <p className="text-white ellipsis">{els?.title}</p>
                                                                        <p className="text-white font-size-small blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        :
                                                            <Link to={`/${els?.url}`} className="story">
                                                                <div className="caption mb-0">
                                                                    <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                    {els?.tagline ?
                                                                        <p className="button-hover colorspan-top-post">{els?.tagline}</p>
                                                                        : ""}
                                                                    <div className="categoty-main-post-title">
                                                                        <p className="text-white ellipsis">{els?.title}</p>
                                                                        <p className="text-white font-size-small blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        }
                                                    </>
                                                    }
                                                </>
                                            ))
                                        }
                                    </div>
                                    <div className="col-xl-4 col-lg-12">
                                        <div className='right-top-post'>
                                            {
                                                innerData?.length > 0 && innerData?.map((els, idx) => (
                                                    <>
                                                        {idx > 0 && idx < 5 ?
                                                        <>
                                                            {els?.post_type === 4 ? 
                                                                    <a className="related-blogs cursor-pointer" onClick={(e) => { VideoModalOpen(els?.video?.split('/')?.reverse()[0], els?.title, els?.publish_date, e); }}>
                                                                        <p className='img-16-9-blogs eskeleton' >
                                                                            <div className='col p-0' style={{ width: '100px' }}>
                                                                                <span className='skeleton'>
                                                                                    <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" className="img-responsive" />
                                                                                    {els?.video ? <p className='playicon-subhome-subcategory'><i className="fas fa-play"></i></p> : ''}
                                                                                </span>
                                                                                <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{els?.publish_date}</p>
                                                                            </div>
                                                                        </p>
                                                                        <div className='col-8 p-0'>
                                                                            <div className="blog-details">
                                                                                <span><b className="color-red">{els?.tagline}</b></span>
                                                                                <p className="ellipsis">{els?.title}</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                            :
                                                                <Link to={`/${els?.url}`} className="related-blogs">
                                                                    <p className='img-16-9-blogs eskeleton' >
                                                                        <div className='col p-0' style={{ width: '100px' }}>
                                                                            <span className='skeleton'>
                                                                                <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" className="img-responsive" />
                                                                                {els?.video ? <p className='playicon-subhome-subcategory'><i className="fas fa-play"></i></p> : ''}
                                                                            </span>
                                                                            <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{els?.publish_date}</p>
                                                                        </div>
                                                                    </p>
                                                                    <div className='col-8 p-0'>
                                                                        <div className="blog-details">
                                                                            <span><b className="color-red">{els?.tagline}</b></span>
                                                                            <p className="ellipsis">{els?.title}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            }
                                                        </>
                                                        : ""}
                                                    </>
                                                ))
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div> :
                                <div>
                                    {(!loading) ?
                                        <>
                                            {
                                                window.location.href = '/'
                                            }
                                        </>
                                        : ''}
                                </div>
                            }
                        </div>
                        <div className="insta-head-responsive col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                            <div className="d-flex justify-content-center" >
                                <div className='ad-side-head'>
                                    <div className="ad-section">
                                        <InjectScript script={sportNews} />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center" >
                                <div className='ad-side-head'>
                                    <div className="ad-section">
                                            {
                                                window.innerWidth < 991 ?
                                                <InjectScript script={gallery202} />
                                                :
                                                <InjectScript script={gallery58} />
                                            } 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-12 my-2'>
                        {gallery && <>
                        <span class="badge badge-warning">Gallery</span>
                            <div className='match-slider'>
                                <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    infinite={false}
                                    autoPlaySpeed={5000000}
                                    arrows={true}
                                    renderArrowsWhenDisabled={true}
                                    showDots={false}
                                    ssr={true}
                                    customTransition="transform 1100ms ease-in-out"
                                    responsive={responsiveGallery}
                                    className=""
                                >
                                    {gallery?.length && gallery?.map((els) => (
                                        <div className="single-blog mx-2">
                                            <div className="shadow mb-3 bg-gray rounded">
                                                <Link to={`/${els?.url}`} className="main-blogs">
                                                    <img src={`${assetbaseUrl?.value}${els?.media}`} className="gallery-comp-sub-img img-responsive new_gallery_img " alt="" />
                                                    <img src={`${assetbaseUrl?.value}${els?.media}`} className="gallery-comp-sub-img img-responsive new_gallery_img_sub " alt="" />
                                                    <div className="bgcolor-div row">
                                                        <div className="row px-3">
                                                            <div className="mr-3">
                                                                <img className="sub-camera-img" src={camera} alt="" />
                                                            </div>
                                                            <div>
                                                                <p className=" text-white m-0">{els?.count} Images </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="blog-details" style={{ height: '57.2px' }}>
                                                        <p className="ellipsis">{els?.title}</p>
                                                        <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                            </>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-9'>
                            {video && <>
                                <div class="single-blog">
                                    <span class="badge badge-warning">Videos</span>
                                    <Link class="readmore" to="/video/sports">View More</Link>
                                </div>
                                <div className='row'>
                                    {video?.length && video.map((els, idx) => (
                                        <>
                                        {idx < 3 && <div className="col-lg-6 col-xl-4 my-2">
                                            <Link to={`/${els?.url}`} className="story">
                                                <div className="caption mb-0">
                                                    <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                </div>
                                                <div className="categoty-padding">
                                                    <p className="button-hover topleft colorspan-top-trending">{(els?.post_type === 1 || els?.post_type === 2) ? els?.tagline : els?.time}</p>
                                                    <p className="ellipsis">{els?.title}</p>
                                                    <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                </div>
                                            </Link>
                                        </div>}
                                        </>
                                    ))}
                                </div>
                            </>}
                            {
                                (window.innerWidth > 991) ?
                                    <HomeTaboola key={window.location.href} />
                                : ''
                            }
                        </div>
                        <div className='col-xl-3'>
                            <div className='my-2 sidebar-taboola' key={window.location.href}>
                                <div id="taboola-right-rail-thumbnails"></div>
                                <InjectScript type="taboola" script={taboolacontent} key={window.location.href} />
                                <div className='ad-sticky-top'>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-sm-12 col-md-7 col-lg-8 col-xl-9 gujarat-responsive-space-sm">
                            {innerData?.length > 0 ? <div className="shadow p-3 mb-3 bg-white rounded">
                                <div className="row">
                                    <div className="col-xl-8 megapreView col-lg-12">
                                        {
                                            innerData?.length > 0 && innerData?.map((els, idx) => (
                                                <>
                                                    {idx === 0 &&
                                                    <>
                                                        {els?.post_type === 4 ? 
                                                            <a className="story cursor-pointer" onClick={(e) => { VideoModalOpen(els?.video?.split('/')?.reverse()[0], els?.title, els?.publish_date, e); }}>
                                                                <div className="caption mb-0">
                                                                    <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                    {els?.tagline ?
                                                                        <p className="button-hover colorspan-top-post">{els?.tagline}</p>
                                                                        : ""}
                                                                    <div className="categoty-main-post-title">
                                                                        <p className="text-white ellipsis">{els?.title}</p>
                                                                        <p className="text-white font-size-small blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        :
                                                            <Link to={`/${els?.url}`} className="story">
                                                                <div className="caption mb-0">
                                                                    <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                    {els?.tagline ?
                                                                        <p className="button-hover colorspan-top-post">{els?.tagline}</p>
                                                                        : ""}
                                                                    <div className="categoty-main-post-title">
                                                                        <p className="text-white ellipsis">{els?.title}</p>
                                                                        <p className="text-white font-size-small blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        }
                                                    </>
                                                    }
                                                </>
                                            ))
                                        }
                                    </div>
                                    <div className="col-xl-4 col-lg-12">
                                        <div className='right-top-post'>
                                            {
                                                innerData?.length > 0 && innerData?.map((els, idx) => (
                                                    <>
                                                        {idx > 0 && idx < 5 ?
                                                        <>
                                                            {els?.post_type === 4 ? 
                                                                    <a className="related-blogs cursor-pointer" onClick={(e) => { VideoModalOpen(els?.video?.split('/')?.reverse()[0], els?.title, els?.publish_date, e); }}>
                                                                        <p className='img-16-9-blogs eskeleton' >
                                                                            <div className='col p-0' style={{ width: '100px' }}>
                                                                                <span className='skeleton'>
                                                                                    <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" className="img-responsive" />
                                                                                    {els?.video ? <p className='playicon-subhome-subcategory'><i className="fas fa-play"></i></p> : ''}
                                                                                </span>
                                                                                <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{els?.publish_date}</p>
                                                                            </div>
                                                                        </p>
                                                                        <div className='col-8 p-0'>
                                                                            <div className="blog-details">
                                                                                <span><b className="color-red">{els?.tagline}</b></span>
                                                                                <p className="ellipsis">{els?.title}</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                            :
                                                                <Link to={`/${els?.url}`} className="related-blogs">
                                                                    <p className='img-16-9-blogs eskeleton' >
                                                                        <div className='col p-0' style={{ width: '100px' }}>
                                                                            <span className='skeleton'>
                                                                                <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" className="img-responsive" />
                                                                                {els?.video ? <p className='playicon-subhome-subcategory'><i className="fas fa-play"></i></p> : ''}
                                                                            </span>
                                                                            <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{els?.publish_date}</p>
                                                                        </div>
                                                                    </p>
                                                                    <div className='col-8 p-0'>
                                                                        <div className="blog-details">
                                                                            <span><b className="color-red">{els?.tagline}</b></span>
                                                                            <p className="ellipsis">{els?.title}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            }
                                                        </>
                                                        : ""}
                                                    </>
                                                ))
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="row substory">
                                    {
                                        innerData?.length > 0 && innerData?.map((els, idx) => (
                                            <>
                                                {idx > 4 && idx < 8 &&
                                                    <>
                                                        {els?.post_type === 4 ?
                                                            <div className="col-lg-6 col-xl-4" onClick={(e) => { VideoModalOpen(els?.video?.split('/')?.reverse()[0], els?.title, els?.publish_date, e); }}>
                                                                <a className="story cursor-pointer">
                                                                    <div className="caption mb-0">
                                                                        <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                        {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                    </div>
                                                                    <div className="categoty-padding">
                                                                        <p className="button-hover topleft colorspan-top-trending">{(els?.post_type === 1 || els?.post_type === 2) ? els?.tagline : els?.time}</p>
                                                                        <p className="ellipsis">{els?.title}</p>
                                                                        <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            :
                                                            <div className="col-lg-6 col-xl-4">
                                                                <Link to={`/${els?.url}`} className="story">
                                                                    <div className="caption mb-0">
                                                                        <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                        {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                    </div>
                                                                    <div className="categoty-padding">
                                                                        <p className="button-hover topleft colorspan-top-trending">{(els?.post_type === 1 || els?.post_type === 2) ? els?.tagline : els?.time}</p>
                                                                        <p className="ellipsis">{els?.title}</p>
                                                                        <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        ))
                                    }
                                </div>
                            </div> :
                                <div>
                                    {(!loading) ?
                                        <>
                                            {
                                                window.location.href = '/'
                                            }
                                        </>
                                        : ''}
                                </div>
                            }

                           <InjectScript script={afterPrimaryNewVDO} />

                            {innerData?.length > 8 ? <div className="shadow p-3 mb-3 bg-white rounded">
                                <div className="row substory">
                                    {
                                        innerData?.length > 0 && innerData?.map((els, idx) => (
                                            <>
                                                {idx > 7 && idx < 14 &&
                                                    <div className="col-lg-6 col-xl-4">
                                                        <Link to={`/${els?.url}`} className="story">
                                                            <div className="caption mb-0">
                                                                <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                            </div>

                                                            <div className="categoty-padding">
                                                                <p className="button-hover topleft colorspan-top-trending">{(els?.post_type === 1 || els?.post_type === 2) ? els?.tagline : els?.time}</p>
                                                                <p className="ellipsis">{els?.title}</p>
                                                                <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                }

                                            </>
                                        ))
                                    }
                                </div>
                            </div> : ''}
                            <div className="ad-section">
                            </div>
                            {innerData?.length > 14 ? <div className="shadow p-3 mb-3 bg-white rounded">
                                <div className="row substory">
                                    {
                                        innerData?.length > 0 && innerData?.map((els, idx) => (
                                            <>
                                                {idx > 13 && idx < 17 &&
                                                    <div className="col-lg-6 col-xl-4">
                                                        <Link to={`/${els?.url}`} className="story">
                                                            <div className="caption mb-0">
                                                                <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                            </div>

                                                            <div className="categoty-padding">
                                                                <p className="button-hover topleft colorspan-top-trending">{(els?.post_type === 1 || els?.post_type === 2) ? els?.tagline : els?.time}</p>
                                                                <p className="ellipsis">{els?.title}</p>
                                                                <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                }

                                            </>
                                        ))
                                    }
                                </div>
                            </div> : ''}
                            <div className="ad-section">
                            </div>

                            {innerData?.length > 18 ?

                                <div className="shadow p-3 mb-3 bg-white rounded">
                                    <div className="row substory">
                                        {
                                            innerData?.length > 0 && innerData?.map((els, idx) => (
                                                <>
                                                    {idx > 17 ?
                                                        <div className="col-lg-6 col-xl-4">
                                                            <Link to={`/${els?.url}`} className="story">
                                                                <div className="caption mb-0">
                                                                    <img key={Math.random()} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                </div>

                                                                <div className="categoty-padding">
                                                                    <p className="button-hover topleft colorspan-top-trending">{(els?.post_type === 1 || els?.post_type === 2) ? els?.tagline : els?.time}</p>
                                                                    <p className="ellipsis">{els?.title}</p>
                                                                    <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                                </div>
                                                            </Link>
                                                        </div> : ""
                                                    }
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                                : ''}
                            <div className="ad-section">
                            </div>

                            <div>
                                <nav aria-label="Page navigation example" style={{ display: (pageCount) ? 'block' : 'none' }}>
                                    <div className='justify-content-center text-center'>
                                        <ul className={`pagination myActive${(pageArray?.length === activePage) ? `last` : activePage} myActiveFirst${(pageArray?.length === 1) ? `last` : activePage}`}>
                                            <li onClick={() => { setActivePage(1) }} className="firstpage page-item"><a className="page-link">{'<'}{'<'} First</a></li>
                                            <li onClick={() => { if (activePage > 1) { setActivePage(activePage - 1) } }} className="firstpage page-item"><a className="page-link" >Previous</a></li>

                                            {pageArray?.length > 0 && pageArray?.map((els, index) => (
                                                ((activePage - 3) <= index && activePage + 2 > index) && (
                                                    <li onClick={() => setActivePage(els + 1)} key={Math.random()} className={`page-item ${els + 1 === activePage ? 'active' : ''}`}><a className="page-link" >{els + 1}</a></li>
                                                )
                                            ))}

                                            <li onClick={() => { if (pageArray?.length !== activePage) { setActivePage(activePage + 1) } }} className="lastpage page-item"><a className="page-link">Next</a></li>
                                            <li onClick={() => setActivePage(pageArray?.length)} className="lastpage page-item"><a className="page-link" >Last {'>'}{'>'}</a></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            {
                                (window.innerWidth > 991) ?
                                    <HomeTaboola key={window.location.href} />
                                    : ''
                            }
                        </div>

                        <div className="insta-head-responsive col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                            <div className="text-center">
                                <div className='ad-side-head '>
                                    <div className="ad-side m-0">
                                        <div className="ad-section">
                                            {
                                                window.innerWidth < 991 ?
                                                <InjectScript script={gallery201} />
                                                :
                                                <InjectScript script={gallery57} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="shadow rightBar p-x2 mt-4 mb-2 bg-f7f7f7 rounded">
                                <div className="featured ">
                                    <a href="javascript:void(0)" className="title">Top 10 News</a>
                                    <div className="scroll s-story">
                                        {
                                            homePrimary?.Trending?.length > 0 && homePrimary?.Trending?.map((els, idx) => (
                                                <>
                                                    <CommonContent url={els?.url} bgcolorclass='bgcolorcard' dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                    <hr className='border-bt' />
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center" >
                                <div className='ad-side-head'>
                                    <div className="ad-section">
                                            {
                                                window.innerWidth < 991 ?
                                                <InjectScript script={gallery202} />
                                                :
                                                <InjectScript script={gallery58} />
                                            } 
                                    </div>
                                </div>
                            </div>

                            <div className='my-2 sidebar-taboola' key={window.location.href}>
                                <div id="taboola-right-rail-thumbnails"></div>
                                <InjectScript type="taboola" script={taboolacontent} key={window.location.href} />
                                <div className='ad-sticky-top'>
                                </div>
                            </div>

                        </div>
                    </div> */}
                    {
                        (window.innerWidth < 991) ?
                            <HomeTaboola key={window.location.href} />
                            : ''
                    }
                </div>
            </section>
            <div className="ad-section">
            </div>
            <div id="videoPoPup" className="videoSidebar">
                <div className="closebtn" onClick={() => VideoModalClose()} >
                    <i className="fas fa-times"></i>
                </div>
                <Dailymotion
                    className="dailymotion-videoPoPup-page"
                    video={`${vidId}`}
                    showEndScreen={true}
                    uiTheme="light"
                    autoplay
                    uiShowLogo="false"
                    origin=""
                />
            </div>
        </>
    )
}

export default Sport
