/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react';
import { Link } from 'react-router-dom';
// import ShareCommon from './component/ShareCommon';

function CommonvideoContent(props) {
    // const onShareClick = (e) => {
    //     document.getElementById('common-share-dropdown').classList.remove('d-none');
    //     e.stopPropagation();
    //     return false;
    // }
    return (
        <div className={`${props?.dataclass2 ? 'share-common-home-video' : ''}`}>
            {props?.url ?
                <Link to={`/${props?.url}`}>
                    <div className='border-video'>
                        <div className='skeleton-sub'>
                            <img src={props.src} alt="" key={Math.random()} />
                            <span className="button-hover sub-video">{props?.time} </span>
                            <p className='playicon'><i className="fas fa-play"></i></p>
                        </div>
                    </div>
                    <div className="video-content">
                        <div className="blog-details font-size-gujrat-title">
                            <p className="font-size-video m-0 pt-1 ellipsis">{props?.title}</p>
                        </div>
                    </div>
                </Link>
                :
                <a href='javascript:void(0)' className='modal'>
                    <div className='border-video'>
                        <div className='skeleton-sub'>
                            <img src={props.src} alt="" key={Math.random()} />
                            <span className="button-hover sub-video">{props?.time} </span>
                            <p className='playicon'><i className="fas fa-play"></i></p>
                        </div>
                    </div>
                    <div className="video-content">
                        <div className="blog-details font-size-gujrat-title">
                            <p className="font-size-video m-0 pt-1 ellipsis">{props?.title}</p>
                        </div>
                    </div>
                    {/* <div class="dropleft">
                        <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
                            <i class="fa fa-share-alt"></i>
                        </button>
                        <div class="dropdown-menu">
                            <ShareCommon id="common-share-dropdown" url={props.url} media={props.src} title={props.title} dataid={props.id} />
                        </div>
                    </div> */}
                </a>
            }
        </div>
    )
}

export default CommonvideoContent