/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Share from './component/Share';

const CommonHeadCardContent = (props) => {
    let navigate = useNavigate();
    const onShareClick = (e) => {
        e.preventDefault();
        document.getElementById('common-share-dropdown').classList.remove('d-none');
        // e.stopPropagation();
        return false;
    }

    return (
        <div className={`img-in-txt ${props.dataClass}`} onClick={() => { navigate(`/${props?.url}`) }}>
            <div className='skeleton-sub'>
                <img src={!props?.media ? props.sandeshske : props.mediaimg} alt="" key={Math.random()} />
            </div>
            {props?.post_type === 3 || props?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{props?.time}</p> : ""}
            <div className="content">
                <div className="blog-details pt-2 px-0 pb-0 font-size-gujrat-title common-card-content-title">
                    <h6 className="ellipsis mb-2">{props?.title}</h6>
                    <div className='cap-post-head-business px-0 mb-0 row d-flex justify-content-between'>
                        <a>
                            <div className="dropup">
                                <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2"><i className="fas fa-share"></i> Share</button>
                                <div id='common-share-dropdown' className="dropup-content">
                                    <Share id='common-share-dropdown' dataid={props?.id} url={props?.url} title={props?.title} media={props?.media} />
                                </div>
                            </div>
                        </a>
                        <a className='px-2'>
                            <a><i className="icone-color-white far fa-clock"></i> {props?.publish_date}</a>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommonHeadCardContent