import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactGA from "react-ga4";
import CommonGalleryContent from '../CommonGalleryContent';
import InjectScript from '../CustomScript';
import { useState } from 'react';
import { useEffect } from 'react';

const GallerySpotted = () => {
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);
    const GallerylistData = useSelector((state) => state?.HomeSecondaryReducer?.data?.Spottedgallery);
    const [Gallerylist, setGallerylist] = useState(GallerylistData || []);
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1200, min: 991 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 991, min: 515 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 515, min: 0 },
            items: 1
        }
    };

    // console.log('GallerylistGallerylist', Gallerylist);
    useEffect(() => {
        const arr = JSON.parse(JSON.stringify(GallerylistData || []));
        // arr.splice(2, 0, {});
        setGallerylist(arr);
    }, [GallerylistData])




    const myads =  '' +
    "<div id='div-gpt-ad-1685091035068-0' style='min-width: 240px; min-height: 400px;'>" +
    ' <script>' +
    'window.googletag = window.googletag || {cmd: []};' +
    'googletag.cmd.push(function() {' +
    "googletag.defineSlot('/22678428348/SDPL_HP_CelebDuniya_240x400', [240, 400], 'div-gpt-ad-1685091035068-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.enableServices();' +
    '});' +
    ' </script>' +
    '' +
    '<script>' +
    "  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1685091035068-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';

    const belowCelebduniya = '' +
    "<div id='div-gpt-ad-1686047749606-0' style='min-width: 728px; min-height: 90px;'>" +
    ' <script>' +
    'window.googletag = window.googletag || {cmd: []};' +
    'googletag.cmd.push(function() {' +
    "googletag.defineSlot('/22678428348/SDPL_HP_BelowCelebDuniya_728x90', [728, 90], 'div-gpt-ad-1686047749606-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.enableServices();' +
    '});' +
    ' </script>' +
    '' +
    '<script>' +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1686047749606-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';

    return (
        <>
            <section className="gallery ">
                <div className="container mb-4-half">
                    <span className="badge badge-info">Celeb Duniya</span>
                    <Link to="/celeb-duniya" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Photo Gallery', label: 'Read More' })} className="readmore">View All</Link>
                    <div className="row">
                        <div className="col-md-12">
                            <Carousel
                                swipeable={true}
                                draggable={false}
                                infinite={false}
                                showDots={false}
                                responsive={responsive}
                                autoPlaySpeed={5000000}
                                transitionDuration={10}
                                renderArrowsWhenDisabled={true}
                                customTransition="transform 500ms ease"
                                ssr={true}
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                className="gallery-sec newgallery"
                            >
                                {
                                    Gallerylist?.length > 0 && Gallerylist?.map((els, idx) => (
                                                                                   
                                                <CommonGalleryContent url={els?.url} src={`${assetbaseUrl?.value}${els?.spotted}`} playicon title={els?.title} />
                                                                             )
                                    )}


                                {/* {
                                    Gallerylist?.length > 0 && Gallerylist?.map((els, idx) => (
                                        <>
                                            {idx === 2 ?  <InjectScript script={myads} key={Math.random()} /> :
                                                <CommonGalleryContent url={els?.url} src={`${assetbaseUrl?.value}${els?.spotted}`} playicon title={els?.title} />
                                            }</>
                                    )
                                    )} */}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className='' >
                <div className="text-center">
                    <InjectScript script={galleryBody} key={galleryBody} />
                </div>
            </div> */}
            {window.innerWidth > 991 ? <div className='' >
                <div className="text-center">
                    <InjectScript script={belowCelebduniya} key={belowCelebduniya} />
                </div>
            </div> : ''
            }

        </>
    )
}

export default GallerySpotted