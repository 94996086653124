import React from 'react';
import { Link } from 'react-router-dom';
// import ShareCommon from './component/ShareCommon';


function CommonGalleryContent(props) {
    // const onShareClick = (e) => {
    //     document.getElementById('common-share-dropdown').classList.remove('d-none');
    //     e.stopPropagation();
    //     return false;
    // }
    return (
        <div className="single-photo pr-4 share-common-card-content">
            <div className="gallery_new">
                <Link to={`/${props?.url}`}>
                    <div className='skeleton-gallery'>
                        <img src={props.src} alt="" key={Math.random()} className="img-responsive" />
                        {props?.playicon && <p className='playicon-subhome'><i className="fas fa-play"></i></p>}
                        <span className="caption mr-4 ellipsis">{props?.title}</span>
                    </div>
                    {/* <div class="dropleft">
                        <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
                            <i class="fa fa-share-alt"></i>
                        </button>
                        <div class="dropdown-menu">
                            <ShareCommon id="common-share-dropdown" url={props.url} media={props.src} title={props.title} dataid={props.id} />
                        </div>
                    </div> */}
                </Link>
            </div>
        </div>
    )
}

export default CommonGalleryContent