/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import './styles/custom.css';
import './styles/fa.css';
import React, { useEffect } from 'react';
import $ from 'jquery';
import Home from './components/pages/Home';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import { menuFetchData } from './redux/actions/menuAction';
import { childmenuFetchData } from './redux/actions/childmenuAction';
import { tithiFetchData } from './redux/actions/tithiAction';
import { widgetFetchData } from './redux/actions/widgetAction';
import { horoscopeFetchData } from './redux/actions/horoscopeAction';
import { footerFetchData } from './redux/actions/footerAction';
import { settingFetchData } from './redux/actions/settingAction';
import { categoryAstroFetchData } from './redux/actions/categoryAstroAction';
import { useDispatch, useSelector } from 'react-redux';
import Gujarat from './components/pages/Gujarat';
import Rss from './components/pages/Rss';
import Post from './components/pages/Post';
import Topbar from './components/component/Topbar';
import Sitemap from './components/pages/Sitemap';
import Menu from './components/subcomponents/Menu';
import ScrollButton from './components/pages/ScrollButton';
import MenuPage from './components/subcomponents/MenuPage';
import Footer from './components/subcomponents/Footer';
import Epaper from './components/pages/Epaper/Epaper';
import BreadCrumb from './components/component/BreadCrumb';
import EpaperCategoryNewsPerDate from './components/pages/Epaper/EpaperCategoryNewsPerDate';
import Pages from './components/pages/Pages';
import ClippedImage from './components/pages/Epaper/ClippedImage';
import VideosComponent from './components/pages/VideosComponent';
import PagesInvestorRelation from './components/pages/PagesInvestorRelation';
import ScrollToTop from './ScrollToTop';
import GalleryComponent from './components/pages/GalleryComponent';
import AstrologyComponent from './components/pages/AstrologyComponent';
import SearchResult from './components/pages/SearchResult';
import Profile from './components/pages/Profile';
import EpaperMenu from './components/pages/Epaper/EpaperMenu';
import Maintenance from './components/pages/maintenance';
import HelperScript from './utils/helper';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";
import Supplement from './components/pages/Supplement';
import VideoCategoryPage from './components/pages/VideoCategoryPage';
import GujaratMetro from './components/pages/GujaratMetro';
import GujaratSamagra from './components/pages/GujaratSamagra';
import InjectScript from './components/CustomScript';
import Sport from './components/pages/Sport';
import { marqueeFetchData } from './redux/actions/marqueeAction';
import AssemblyElection from './components/pages/AssemblyElection';


function App() {
  const [mode, setMode] = React.useState('light');
  const [url, setUrl] = React.useState(window.location.href);
  const [sport, setSport] = React.useState(false);
  const { 50: sportLayout } = useSelector(
    (state) => state?.SettingReducer?.data
  );
  const onDarkMode = () => {
    document.documentElement.classList.toggle('dark-mode')
    setMode(mode === 'dark' ? 'light' : 'dark');
  }

  Sentry.init({
    dsn: "https://9fbd68e8996640c294f72b3d924bf7cf@o1158838.ingest.sentry.io/6242283",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  ReactGA.initialize('G-6YPHENN7FN', {
    titleCase: false,
  });

  useEffect(() => {
    setInterval(() => {
      setUrl(window.location.href);
    }, 300);
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(settingFetchData());
    const timer = setTimeout(() => {
      dispatch(menuFetchData());
      dispatch(childmenuFetchData());
      dispatch(tithiFetchData());
      dispatch(widgetFetchData());
      dispatch(footerFetchData());
      dispatch(horoscopeFetchData());
      dispatch(categoryAstroFetchData());
      dispatch(marqueeFetchData());
    }, 350);
    return () => {
      clearInterval(timer);
    }
  }, []);

  const routeChange = () => {
  }

  useEffect(() => {
    $("section.menu .nav-item a").each(function () {
      $(this).removeClass("act");
      if (window.location.pathname === $(this).attr("href")) {
        $(this).addClass("act");
      }
    });
    $(".navbar-toggler").addClass('collapsed').attr('aria-expanded', 'false');
    $(".navbar-collapse").removeClass('show');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.href]);


  const taboolacontent = '' +
  "<script>" +
  'window._taboola = window._taboola || [];' +
  "_taboola.push({notify:'newPageLoad'});" +
  "_taboola.push({article:'auto', url: '" + window.location.href + "'});" +
  // "_taboola.push({category:'auto', url: '" + window.location.href + "'});" +
  // "_taboola.push({homepage:'auto', url: '" + window.location.href + "'});" +
  "</script>" +
  '';

  const selectmedia = '' +
  '<script async id="tag_605cdfa6-48e7-4882-a5ce-88e59e668408" type="text/javascript" src="https://sm1.selectmedia.asia/cdn/tags/tag_605cdfa6-48e7-4882-a5ce-88e59e668408.js"></script>'+
  ''


  const headerMobile =
    '' +
    "<div id='div-gpt-ad-1646996478869-0' style='min-width: 300px; min-height: 100px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/Mweb_Header_Global_300x100', [300, 100], 'div-gpt-ad-1646996478869-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.pubads().disableInitialLoad();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    " googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646996478869-0'); });" +
    '</script>' +
    '</div>' +
    // '<div id="gpt-passback">' +
    // "<script>" +
    // "window.googletag = window.googletag || {cmd: []};" +
    // "googletag.cmd.push(function() {" +
    // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x100', [300, 100], 'gpt-passback').addService(googletag.pubads());" +
    // "googletag.enableServices();" +
    // "googletag.display('gpt-passback');" +
    // "});" +
    // "</script>" +
    // "</div>" +
    '' +
    '';

    const allPage = '' +
    '<div id="bsrv1338" data-domain="sandesh.com" data-click-tracker="%%CLICK_TRACKER%%">'+
      '<script src="https://bsrv.bidsxchange.com/1338.js"></script>'+
    '</div>'+
    '<script src="https://bsrv-qa.bidsxchange.com/1338.js"></script>'+
    '';


    
    const bottomFooterScript = "" +
    '<script>'+
  'window.googletag = window.googletag || { cmd: [] };'+
  'var ub_interstitialSlot;'+
  'googletag.cmd.push(function () {'+
  'ub_interstitialSlot = googletag.defineOutOfPageSlot("/22678428348/SDPL_Epaper_Interstitial",googletag.enums.OutOfPageFormat.INTERSTITIAL).addService(googletag.pubads());'+
  'googletag.enableServices();});'+
  'googletag.cmd.push(function () {googletag.pubads().refresh([ub_interstitialSlot]);});'+
  '</script>'+
  ''

  const homePageScript2nd = "" +
  '<script type="text/javascript">'+
  '(function(){'+
    "var o='script',"+
    's=top.document,'+
    'a=s.createElement(o),'+
    'm=s.getElementsByTagName(o)[0],'+
    'd=new Date(),'+
    'timestamp=""+d.getDate()+d.getMonth()+d.getHours();'+
    'a.async=1;'+
    "a.src='https://cdn4-hbs.affinitymatrix.com/hvrcnf/sandesh.com/'+ timestamp + '/index?t='+timestamp;"+
    'm.parentNode.insertBefore(a,m)'+
  '})();'+
  '</script>'+
  ''
  setTimeout(() => {
    setSport(sportLayout?.value);
  }, 200);

  const globalScript =
  "" +
  ''
 
  // '<script async src="https://cdn.unibots.in/genericcube/common/script.js"></script>'+
  // "<script>"+
  //   "window.unibotsDirectAds = window.unibotsDirectAds || { cmd: [] };"+
  //    "unibotsDirectAds.cmd.push('sandesh');"+
  // "</script>"+
  //   "";

  const homePageScript4th =
  "" +
  "<div id='div-gpt-ad-1666261240163-0'>"+
  '<script>'+
  "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1666261240163-0'); });"+
  '</script>'+
  ' <script>'+
  "window.googletag = window.googletag || {cmd: []};"+
  'googletag.cmd.push(function() {'+
  "googletag.defineSlot('/22678428348/Median_CanadaCampaign_1x1', [1, 1], 'div-gpt-ad-1666261240163-0').addService(googletag.pubads());"+
  'googletag.pubads().enableSingleRequest();'+
  ' googletag.enableServices();'+
  '});'+
  '</script>'+
  "</div>"+
  "";

  return (
    <>
      <div className="ad-section">
        <div className="text-center">
          <HelperScript argSmall={187} argLarge={40} />
        </div>
      </div>

      <InjectScript key={Math.random()} script={taboolacontent} />
      <InjectScript script={globalScript} />

      <Router onChange={routeChange}>
        <ScrollToTop />
        <Topbar onDarkMode={onDarkMode} mode={mode} />
        {window.location.href?.includes('/epaper') ? <EpaperMenu /> : <>
          <Menu />
          <MenuPage />
          {window.location.href?.includes('/metro') || window.location.href?.includes('/samagra') || window.location.href?.includes('/video/') ? '' : url?.split('/')?.[4] && <BreadCrumb />}
        </>
        }
        {/* <HelperScript argSmall={253} argLarge={0} /> */}
        {window.innerWidth < 991 ?
          <InjectScript
            key={window.location.pathname}
            script={headerMobile}
          /> : ''}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/video/:url" element={<VideoCategoryPage />} />
          {/* <Route exact path="/login" element={<Login />} /> */}
          {/* <Route exact path="/world" element={<World />} /> */}
          <Route exact path="/gujarat/metro" element={<GujaratMetro type="location" />} />
          <Route exact path="/gujarat/samagra" element={<GujaratSamagra type="location" />} />


          <Route exact path="/tag/:url" element={<Gujarat type="tag" />} />
          <Route exact path="/" element={<AssemblyElection type="category" />} />
          <Route exact path="/assembly-elections" element={<AssemblyElection type="category" />} />
          <Route exact path="/:url" element={<Gujarat type="category" />} />
          <Route exact path="/location" element={<Gujarat type="location" />}>
            <Route exact path=":country" element={<Gujarat type="location" />} >
              <Route exact path=":state" element={<Gujarat type="location" />} >
                <Route exact path=":city" element={<Gujarat type="location" />} />
              </Route>
            </Route>
          </Route>
          <Route exact path="/epaper" element={<Epaper />} />
          <Route exact path="/epaper/sub" element={<ClippedImage />} />
          <Route exact path="/epaper/:city" element={<EpaperCategoryNewsPerDate />} />
          <Route exact path="/video" element={<VideosComponent />} />
          <Route exact path="/videos" element={<VideosComponent />} />
          {/* <Route exact path="/:url" element={<VideosInnerComponent />} /> */}
          <Route exact path="/page/:id" element={<Pages />} />
          <Route exact path="/investor-relations" element={<PagesInvestorRelation />} />
          <Route exact path="/gallery" element={<GalleryComponent />} />
          <Route exact path="/page/rss" element={<Rss />} />
          <Route exact path="/page/sitemap" element={<Sitemap />} />
          {sport === "2" ? (
            <Route
              exact
              path="/t20-world-cup"
              element={<Sport type="category" />}
            />
          ) : (
            ""
          )}
          {/* <Route exact path="/gallery/inner/:url" element={<GalleryInnerComponents />} /> */}
          <Route exact path="/astrology" element={<AstrologyComponent />} />
          <Route exact path="/supplement" element={<Supplement />} />
          {/* <Route exact path="/astrology/inner" element={<AstrologyInner />} /> */}
          {/* <Route exact path="/location" element={<Location />} /> */}
          <Route exact path="/search" element={<SearchResult />} />
          {/* <Route exact path="/profile" element={<Profile />} /> */}
          {
            localStorage.getItem('access_token') && (
              <Route exact path="/profile" element={<Profile />} />
            )
          }
          <Route exact path="/maintenance" element={<Maintenance />} />
          {/* <Route path="/:url" element={<Post />} /> */}

          {['/:path1',
            '/:path1/:path2',
            '/:path1/:path2/:path3',
            '/:path1/:path2/:path3/:path4',
            '/:path1/:path2/:path3/:path4/:path5',
            '/:path1/:path2/:path3/:path4/:path5/:path6',
            '/:path1/:path2/:path3/:path4/:path5/:path6/:path7'].map((path) => (
              <Route path={path} element={<Post />} />
            ))}

        </Routes>
        <ScrollButton />
        <Footer mode={mode} />
        {/* <InjectScript script={allPage} /> */}
        <InjectScript script={selectmedia} key={Math.random()} />
        {/* {window.location.pathname.includes('epaper') ? <InjectScript script={bottomFooterScript} /> : ''} */}
        {/* {window.location.pathname === "/" ? <><InjectScript script={homePageScript2nd} /><InjectScript script={homePageScript4th} /></>: ''} */}
        {window.location.pathname === "/" ? <><InjectScript script={homePageScript2nd} /></>: ''}
        {/* {!url?.includes('/epaper/sub') && <Footer mode={mode} />} */}
      </Router>
    </>

  );
}

export default App;
