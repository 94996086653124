/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import readmore from '../../../assets/readmore.png'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import HelperScript from '../../../utils/helper'
import { Helmet } from 'react-helmet';
import { homePrimaryFetchData } from '../../../redux/actions/homePrimaryAction';
import { useDispatch } from 'react-redux';
import InjectScript from '../../CustomScript';
import HomeTaboola from '../HomeTaboola';
import { CategoryFetchData } from '../../../redux/actions/categoryAction'
import CommonContent from "../../CommonContent";

const Astrology = () => {
    let dispatch = useDispatch();

    // const { 51: threepost } = useSelector((state) => state?.widgetReducer?.data);
    // const { 52: sixpost } = useSelector((state) => state?.widgetReducer?.data);
    // const { 53: ninepost } = useSelector((state) => state?.widgetReducer?.data);

    // const { 233: firstright } = useSelector((state) => state?.widgetReducer?.data);
    // const { 234: secondright } = useSelector((state) => state?.widgetReducer?.data);
    // const { 240: thirdright } = useSelector((state) => state?.widgetReducer?.data);

    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);

    const homePrimary = useSelector((state) => state?.HomePrimaryReducer.data);
    const Horoscope = useSelector((state) => state?.HoroscopeReducer?.data);
    // const pageName = useSelector((state) => state?.CategoryReducer?.data?.name)

    const [tabactivecls, settabactivecls] = React.useState('મેષ');
    const [content, setContent] = React.useState('');
    const [partOneIndex, setPartOneIndex] = React.useState(['']);
    const [partTwoIndex, setPartTwoIndex] = React.useState(['']);
    const [partThreeIndex, setPartThreeIndex] = React.useState(['']);
    const [partFourIndex, setPartFourIndex] = React.useState(['']);
    const [partFiveIndex, setPartFiveIndex] = React.useState(['']);
    const [partSixIndex, setPartSixIndex] = React.useState(['']);
    const [contentURL, setContentURL] = React.useState('');

    const innerData = useSelector((state) => state?.CategoryReducer?.data?.posts)
    const pageCount = useSelector((state) => state?.CategoryReducer?.data?.count)

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 7
        },
        desktop: {
            breakpoint: { max: 1200, min: 991 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 991, min: 515 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 515, min: 0 },
            items: 3
        }
    };
    useEffect(() => {
        dispatch(homePrimaryFetchData());
        const config = {
            page: 1,
            limit: 18,
        }
        dispatch(CategoryFetchData('category', config, `astrology?page=1&limit=18`))
    }, []);
    const urlParam = new URLSearchParams(window.location.search);
    const myParamPage = parseInt(urlParam.get('page'));

    const [activePage, setActivePage] = React.useState(myParamPage ? parseInt(myParamPage) : 1);
    const [pageArray, setPageArray] = React.useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = parseInt(urlParams.get('page'));
        if (myParam !== parseInt(activePage)) {
            if (parseInt(activePage) === 1) {
                navigate(window.location.pathname);
            } else {
                navigate(window.location.pathname + '?page=' + activePage);
            }
        }
        window.scrollTo(0, 0);
        const config = {
            page: activePage,
            limit: 18,
        }
        dispatch(CategoryFetchData('category', config, `astrology?page=${activePage}&limit=18`))
    }, [activePage]);

    const tabActive = (data) => {
        settabactivecls(data);
    }

    useEffect(() => {
        settabactivecls(Horoscope?.[0]?.name_guj)
        setContent(Horoscope?.[0]?.detail)
        setContentURL(Horoscope?.[0]?.url)
        // setPartOneIndex(Horoscope?.filter((els, ind) => ind < 3));
        // setPartTwoIndex(Horoscope?.filter((els, ind) => ind > 3 && ind < 7));
        // setPartThreeIndex(Horoscope?.filter((els, ind) => ind > 7 && ind < 11));
    }, [Horoscope]);

    useEffect(() => {
        if (pageCount) {
            const checkPageSize = Math.ceil(pageCount / 18)
            const array = Array.from(Array(checkPageSize).keys())
            setPageArray(array);
        }
        setPartOneIndex(innerData?.filter((els, ind) => ind < 3));
        setPartTwoIndex(innerData?.filter((els, ind) => ind > 2 && ind < 6));
        setPartThreeIndex(innerData?.filter((els, ind) => ind > 5 && ind < 9));
        setPartFourIndex(innerData?.filter((els, ind) => ind > 8 && ind < 12));
        setPartFiveIndex(innerData?.filter((els, ind) => ind > 11 && ind < 15));
        setPartSixIndex(innerData?.filter((els, ind) => ind > 14 && ind < 18));
    }, [innerData]);



    const taboolacontent = '' +
        '' +
        '<div id="taboola-right-rail-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-rr'," +
        "container: 'taboola-right-rail-thumbnails'," +
        "placement: 'Right Rail Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '';

    const astroafter3 =
        '' +
        "<div id='div-gpt-ad-1657963484355-0' style='min-width: 300px; min-height: 250px;'>" +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/Mweb_Astro_after3news_300X250', [300, 250], 'div-gpt-ad-1657963484355-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        ' </script>' +
        '' +
        ' <script>' +
        "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657963484355-0'); });" +
        '</script>' +
        '</div>' +
        '' +
        '';
        const astroabovetop10 =
        '' +
        "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>"+
        '<script>'+
        'window.googletag = window.googletag || {cmd: []};'+
        'googletag.cmd.push(function() {'+
        "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());"+
        'googletag.pubads().enableSingleRequest();'+
        'googletag.enableServices();'+
        '});'+
        '</script>'+
        '<script>'+
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });"+
        '</script>'+
        '</div>'+
        '' +
        '';
    // const astroabovetop10 =
    //     '' +
    //     "<div id='div-gpt-ad-1657964381466-0' style='min-width: 300px; min-height: 250px;'>" +
    //     '<script>' +
    //     'window.googletag = window.googletag || {cmd: []};' +
    //     'googletag.cmd.push(function() {' +
    //     "googletag.defineSlot('/22678428348/Mweb_Astro_Abovetop10_300X250', [300, 250], 'div-gpt-ad-1657964381466-0').addService(googletag.pubads());" +
    //     'googletag.pubads().enableSingleRequest();' +
    //     'googletag.enableServices();' +
    //     '});' +
    //     '</script>' +
    //     '' +
    //     ' <script>' +
    //     "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1657964381466-0'); });" +
    //     '</script>' +
    //     '</div>' +
    //     '' +
    //     '';

        // const afterPrimary  = "" +
        // '<div id = "v-sandesh-v0"></div>'+
        // ''+
        // '<script>'+
        // '(function(v,d,o,ai){'+
        // "ai=d.createElement('script');"+
        // 'ai.defer=true;'+
        // 'ai.async=true;'+
        // 'ai.src=v.location.protocol+o;'+
        // 'd.head.appendChild(ai);'+
        // '})'+
        // "(window, document, '//a.vdo.ai/core/v-sandesh-v0/vdo.ai.js');"+
        // "</script>"+
        // ''    
        const afterPrimary  = "" +
        '<script defer id="vidcrunchJS632259138" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_ICF_Responsive_Playlist_190922.js">'+
        '</script>'+
        ''    

        const IBVcategory  = "" +
        "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>"+
        '<script>'+
        'window.googletag = window.googletag || {cmd: []};'+
        'googletag.cmd.push(function() {'+
        "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());"+
        'googletag.pubads().enableSingleRequest();'+
        'googletag.enableServices();'+
        '});'+
        '</script>'+
        '<script>'+
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });"+
        '</script>'+
        '</div>'+
        ''    
        const UniBotsplayer = ''+
        '<script async src="https://cdn.unibotscdn.com/clientdata/js/sandesh_head.js"></script>'+
        '<div id="div-ub-sandesh">'+
        '<script>'+
           'window.unibots = window.unibots || { cmd: [] };'+
           'unibots.cmd.push(()=>{ unibotsPlayer("sandesh"); });'+
       '</script>'+
    '</div>'
    //     '<script async src="https://cdn.unibotscdn.com/ubplayer/player.js"></script>'+
    //     '<style>div#unibots-video { text-align: center; margin: auto; }</style>'+
    //     '<div class="text-center">'+
    //     '<div id="div-ub-sandesh_category" class="text-center">'+
    //     '<script>'+
    //        'window.unibots = window.unibots || { cmd: [] };'+
    //        'unibots.cmd.push(()=>{ unibotsPlayer("sandesh_category"); });'+
    //    '</script>'+
    //    '</div>'+
    //    '</div>'+
    //     ''

    return (
        <>
            <Helmet>
                <title>Astro News in Gujarati | Horoscope News in Gujarati | Sandesh</title>
            </Helmet>
            <section className='tabpane-astrology'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className='shadow rounded'>
                                <div className="single-blog mt-3">
                                    <span className="badge badge-warning">Horoscope</span>
                                    {/* <a style={{ cursor: 'pointer' }} className="readmore">Read More</a> */}
                                </div>
                                <Carousel
                                    swipeable={true}
                                    draggable={false}
                                    infinite={false}
                                    autoPlaySpeed={5000000}
                                    // transitionDuration={10}
                                    renderArrowsWhenDisabled={true}
                                    showDots={false}
                                    ssr={true}
                                    customTransition="transform 1100ms ease-in-out"
                                    responsive={responsive}
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                >
                                    {
                                        Horoscope?.length > 0 && Horoscope?.map((els, idx) => (

                                            <div className='astro-carousel' onClick={() => { tabActive(els?.name_guj); setContent(els?.detail); setContentURL(els?.url) }}>
                                                <p className={`astro-carousel-title mb-1 p-0`}>{els?.name_guj}</p>
                                                <div className={`astro-carousel-img `}>
                                                    <img className='border-radius-50 text-center image-center-scroll' src={`${assetbaseUrl?.value}${els?.media}`} alt='' />
                                                </div>
                                                <p className={`astro-carousel-title ${tabactivecls === els?.name_guj ? "astro-carousel-title-active" : ""}`}>{els?.name}</p>
                                            </div>
                                        ))
                                    }

                                </Carousel>
                            </div>
                            <div className='astro-active-tab mt-3'>
                                <div className='astro-tabs-content'>
                                    <div className='astro-active-tab-content'>
                                        <b className='astro-desc-title'> <span>{tabactivecls}:</span> {content} </b>
                                        <span className='astro-active-tab-read-more'>
                                            <Link to={`/${contentURL}`} className='readmore-link'>Read More <img src={readmore} width={15} height={15} alt='' /></Link>
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="ad-side text-center  m-0">
                                {/* <p>જાહેરાત</p> */}
                                {/* {firstright?.[0]?.script && */}
                                <div className="ad-section">
                                    {/* <HelperScript argSmall={235} argLarge={233} /> */}
                                    {window.innerWidth > 991 ? <div className="text-center">
                                            {/* <InjectScript script={astroabovetop10} key={astroabovetop10} /> */}
                                            <InjectScript script={IBVcategory} />
                                        </div> : ''}
                                    {/* <InjectScript script={firstright?.[0]?.script} /> */}
                                </div>
                                {/* } */}
                                {/* <img src="https://i.imgur.com/12sog8C.png" alt="" /> */}
                            </div>
                        </div>
                    </div>
                    <div className='row my-3'>
                        <div className='col-md-9'>
                            <div className="single-blog mb-3">
                                <span className="badge badge-warning">ASTROLOGY NEWS</span>
                                {/* <a style={{ cursor: 'pointer' }} className="readmore">Read More</a> */}
                            </div>
                            <div className='shadow py-3 px-0 mb-3 bg-white rounded'>
                                <div className='row mx-0'>
                                    {partOneIndex?.length > 0 && partOneIndex?.map((els) => {
                                        return (
                                            <>
                                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 my-1">
                                                    <div className="single-blog mx-0">
                                                        <Link to={`/${els?.url}`} style={{ cursor: 'pointer' }} className="main-blogs">
                                                            <div className='skeleton-sub'>
                                                                <img key={Math.random()} src={assetbaseUrl?.value + els?.media} className="img-responsive" alt="" />
                                                                {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                            </div>
                                                            {/* <span className="button-hover colorspan-top-trending left-video">Business</span> */}
                                                            <div className="blog-details">
                                                                <span><b className="color-red">{els?.tagline}</b></span>
                                                                <p className='ellipsis'>{els?.title}</p>
                                                                <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                                {/* <span className="blog-time"><i className="far fa-clock"></i>1 કલાક પહેલા</span> */}
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                    
                                    {window.innerWidth < 991 ? <div className="text-center">
                                        <InjectScript script={astroafter3} key={astroafter3} />
                                    </div> : ''}
                                </div>
                            </div>
                            <div className='p-3'>
                            { (window.innerWidth > 991) ? <InjectScript script={UniBotsplayer} key={Math.random()} /> : '' }
                            </div>
                            {/* {threepost?.[0]?.script &&  */}
                           <InjectScript script={afterPrimary} />

                            <div className="ad-section">
                                <HelperScript argSmall={195} argLarge={51} />
                                {/* <InjectScript script={threepost?.[0]?.script} /> */}
                            </div>
                            {/* } */}
                            {partTwoIndex?.length > 0 ?
                                <div className='shadow py-3 px-0 mb-3 bg-white rounded'>
                                    <div className='row mx-0'>
                                        {partTwoIndex?.length > 0 && partTwoIndex?.map((els) => {
                                            return (
                                                <>
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 my-1">
                                                        <div className="single-blog mx-0">
                                                            <Link to={`/${els?.url}`} style={{ cursor: 'pointer' }} className="main-blogs">
                                                                <div className='skeleton-sub'>
                                                                    <img key={Math.random()} src={assetbaseUrl?.value + els?.media} className="img-responsive" alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                </div>
                                                                {/* <span className="button-hover colorspan-top-trending left-video">Business</span> */}
                                                                <div className="blog-details">
                                                                    <span><b className="color-red">{els?.tagline}</b></span>
                                                                    <p className='ellipsis'>{els?.title}</p>
                                                                    <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                                    {/* <span className="blog-time"><i className="far fa-clock"></i>1 કલાક પહેલા</span> */}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                        {window.innerWidth < 991 ? <div className="text-center">
                                            {/* <InjectScript script={astroafter3} key={astroafter3} /> */}
                                        </div> : ''}
                                    </div>
                                </div>
                                : ''}
                            {/* {sixpost?.[0]?.script && */}
                            <div className="ad-section">
                                <HelperScript argSmall={196} argLarge={52} />
                                {/* <InjectScript script={sixpost?.[0]?.script} /> */}
                            </div>
                            {/* } */}
                            {partThreeIndex?.length > 0 ?
                                <div className='shadow py-3 px-0 mb-3 bg-white rounded'>
                                    <div className='row mx-0'>
                                        {partThreeIndex?.length > 0 && partThreeIndex?.map((els) => {
                                            return (
                                                <>
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 my-1">
                                                        <div className="single-blog mx-0">
                                                            <Link to={`/${els?.url}`} style={{ cursor: 'pointer' }} className="main-blogs">
                                                                <div className='skeleton-sub'>
                                                                    <img key={Math.random()} src={assetbaseUrl?.value + els?.media} className="img-responsive" alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                </div>
                                                                {/* <span className="button-hover colorspan-top-trending left-video">Business</span> */}
                                                                <div className="blog-details">
                                                                    <span><b className="color-red">{els?.tagline}</b></span>
                                                                    <p className='ellipsis'>{els?.title}</p>
                                                                    <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                                    {/* <span className="blog-time"><i className="far fa-clock"></i>1 કલાક પહેલા</span> */}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                        {window.innerWidth < 991 ? <div className="text-center">
                                            {/* <InjectScript script={astroafter3} key={astroafter3} /> */}
                                        </div> : ''}
                                    </div>
                                </div>
                                : ''}

                            {partFourIndex?.length > 0 ?
                                <div className='shadow py-3 px-0 mb-3 bg-white rounded'>
                                    <div className='row mx-0'>
                                        {partFourIndex?.length > 0 && partFourIndex?.map((els) => {
                                            return (
                                                <>
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 my-1">
                                                        <div className="single-blog mx-0">
                                                            <Link to={`/${els?.url}`} style={{ cursor: 'pointer' }} className="main-blogs">
                                                                <div className='skeleton-sub'>
                                                                    <img key={Math.random()} src={assetbaseUrl?.value + els?.media} className="img-responsive" alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                </div>
                                                                {/* <span className="button-hover colorspan-top-trending left-video">Business</span> */}
                                                                <div className="blog-details">
                                                                    <span><b className="color-red">{els?.tagline}</b></span>
                                                                    <p className='ellipsis'>{els?.title}</p>
                                                                    <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                                    {/* <span className="blog-time"><i className="far fa-clock"></i>1 કલાક પહેલા</span> */}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                        {window.innerWidth < 991 ? <div className="text-center">
                                            {/* <InjectScript script={astroafter3} key={astroafter3} /> */}
                                        </div> : ''}
                                    </div>
                                </div>
                                : ''}


                            {partFiveIndex?.length > 0 ?
                                <div className='shadow py-3 px-0 mb-3 bg-white rounded'>
                                    <div className='row mx-0'>
                                        {partFiveIndex?.length > 0 && partFiveIndex?.map((els) => {
                                            return (
                                                <>
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 my-1">
                                                        <div className="single-blog mx-0">
                                                            <Link to={`/${els?.url}`} style={{ cursor: 'pointer' }} className="main-blogs">
                                                                <div className='skeleton-sub'>
                                                                    <img key={Math.random()} src={assetbaseUrl?.value + els?.media} className="img-responsive" alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                </div>
                                                                {/* <span className="button-hover colorspan-top-trending left-video">Business</span> */}
                                                                <div className="blog-details">
                                                                    <span><b className="color-red">{els?.tagline}</b></span>
                                                                    <p className='ellipsis'>{els?.title}</p>
                                                                    <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                                    {/* <span className="blog-time"><i className="far fa-clock"></i>1 કલાક પહેલા</span> */}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                        {window.innerWidth < 991 ? <div className="text-center">
                                            {/* <InjectScript script={astroafter3} key={astroafter3} /> */}
                                        </div> : ''}
                                    </div>
                                </div>
                                : ''}
                            {partSixIndex?.length > 0 ?
                                <div className='shadow py-3 px-0 mb-3 bg-white rounded'>
                                    <div className='row mx-0'>
                                        {partSixIndex?.length > 0 && partSixIndex?.map((els) => {
                                            return (
                                                <>
                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 my-1">
                                                        <div className="single-blog mx-0">
                                                            <Link to={`/${els?.url}`} style={{ cursor: 'pointer' }} className="main-blogs">
                                                                <div className='skeleton-sub'>
                                                                    <img key={Math.random()} src={assetbaseUrl?.value + els?.media} className="img-responsive" alt="" />
                                                                    {els?.video ? <p className='playicon-subhome'><i className="fas fa-play"></i></p> : ''}
                                                                </div>
                                                                {/* <span className="button-hover colorspan-top-trending left-video">Business</span> */}
                                                                <div className="blog-details">
                                                                    <span><b className="color-red">{els?.tagline}</b></span>
                                                                    <p className='ellipsis'>{els?.title}</p>
                                                                    <span className="text-secondary blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                                    {/* <span className="blog-time"><i className="far fa-clock"></i>1 કલાક પહેલા</span> */}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                        {window.innerWidth < 991 ? <div className="text-center">
                                            {/* <InjectScript script={astroafter3} key={astroafter3} /> */}
                                        </div> : ''}
                                    </div>
                                </div>
                                : ''}




                            <div className='isCategoryPage'>
                                <nav aria-label="Page navigation example" style={{ display: (pageCount) ? 'block' : 'none' }}>
                                    <div className='justify-content-center text-center'>
                                        <ul className={`pagination myActive${(pageArray?.length === activePage) ? `last` : activePage} myActiveFirst${(pageArray?.length === 1) ? `last` : activePage}`}>
                                            <li onClick={() => { setActivePage(1) }} className="firstpage page-item"><a className="page-link">{'<'}{'<'} First</a></li>
                                            <li onClick={() => { if (activePage > 1) { setActivePage(activePage - 1) } }} className="firstpage page-item"><a className="page-link" >Previous</a></li>

                                            {pageArray?.length > 0 && pageArray?.map((els, index) => (
                                                ((activePage - 3) <= index && activePage + 2 > index) && (
                                                    <li onClick={() => setActivePage(els + 1)} key={Math.random()} className={`page-item ${els + 1 === activePage ? 'active' : ''}`}><a className="page-link" >{els + 1}</a></li>
                                                )
                                            ))}

                                            <li onClick={() => { if (pageArray?.length !== activePage) { setActivePage(activePage + 1) } }} className="lastpage page-item"><a className="page-link">Next</a></li>
                                            <li onClick={() => setActivePage(pageArray?.length)} className="lastpage page-item"><a className="page-link" >Last {'>'}{'>'}</a></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>

                            {/* {ninepost?.[0]?.script &&  */}
                            <div className="ad-section">
                                <HelperScript argSmall={197} argLarge={53} />
                                {/* <InjectScript script={ninepost?.[0]?.script} /> */}
                            </div>
                            {/* } */}

                            <div className='row ExtraTaboola'>
                                {
                                    (window.innerWidth > 991) ?
                                        <HomeTaboola key={window.location.href} />
                                        : ''
                                }
                            </div>
                        </div>

                        <div className='col-md-3 mt-3'>
                            {/* {window.innerWidth < 991 ? <div className="text-center">
                                <InjectScript script={astroabovetop10} key={astroabovetop10} />
                            </div> : ''} */}
                            {window.innerWidth < 991 ? <div className="text-center">
                                {/* <InjectScript script={astroabovetop10} key={astroabovetop10} /> */}
                            </div> : ''}
                            {/* <div className="ad-side  text-cente"> */}
                            {/* <p>Panchang</p> */}
                            {/* <img src={panchang} alt="" /> */}
                            <div className="featured ">
                                <a style={{ cursor: 'pointer' }} className="title">Top 10 News
                                    {/*      <i className="fas fa-chevron-right"></i> */}
                                </a>
                                <div className=" scroll scroll-astro s-story">
                                    {
                                        homePrimary?.Trending?.length > 0 && homePrimary?.Trending?.map((els, idx) => (
                                            <>
                                                <CommonContent url={els?.url} dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                <hr className='border-bt' />
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                            {/* </div> */}
                            <div className="ad-side text-center">
                                {/* <iframe
                                    title='Currency Converter'
                                    src='https://s.tradingview.com/embed-widget/market-overview/'
                                    width="100%"
                                    height="300"
                                    frameBorder="0"
                                    scrolling="no"
                                /> */}
                                {/* <p>જાહેરાત</p> */}
                                {/* {secondright?.[0]?.script && */}
                                <div className="ad-section">
                                    <HelperScript argSmall={236} argLarge={234} />
                                    {/* <InjectScript script={secondright?.[0]?.script} /> */}
                                </div>
                                {/* } */}
                            </div>
                            <div className="ad-side text-center">
                                {/* {thirdright?.[0]?.script && */}
                                <div className="ad-section">
                                    <HelperScript argSmall={241} argLarge={240} />
                                    {/* <InjectScript script={thirdright?.[0]?.script} /> */}
                                </div>
                                {/* } */}
                            </div>

                            <div className='my-2 sidebar-taboola' key={window.location.href}>
                                <InjectScript type="taboola" script={taboolacontent} key={window.location.href} />
                                <HelperScript argLarge={261} />
                            </div>
                            <div className='row ExtraTaboola'>
                                {
                                    (window.innerWidth < 991) ?
                                        <HomeTaboola key={window.location.href} />
                                        : ''
                                }
                            </div>
                        </div>

                    </div>

                    {/* <AdTwo /> */}
                    {/* <div className="ad-side mt-5 text-center">
                        {thirdright?.[0]?.script && <div className="ad-section">
                            <InjectScript script={thirdright?.[0]?.script} />
                        </div>}
                    </div> */}

                </div>
            </section>
        </>
    )
}

export default Astrology
