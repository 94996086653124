import React from 'react';
// import { useSelector } from 'react-redux';
import HelperScript from '../../utils/helper'
import InjectScript from '../CustomScript';
const AdTwo_photo = () => {
    // const { 129: gujratafter } = useSelector((state) => state?.widgetReducer?.data);


    const adphotoHardcode224 =
        '' +
        " <div id='div-gpt-ad-1646387306029-0' style='min-width: 300px; min-height: 50px;'> " +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() { ' +
        "googletag.defineSlot('/22678428348/Mweb_HP_Desktop_BTF3_300X100', [[320, 50], [300, 100]], 'div-gpt-ad-1646387306029-0').addService(googletag.pubads()); " +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.pubads().disableInitialLoad(); ' +
        'googletag.enableServices();  ' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646387306029-0'); });" +
        '</script>' +
        '</div>' +
        // '' +
        // '<script async="async" src="https://cdn.unibots.in/headerbidding/adScriptSandesh_new.js">' +
        // ' </script>' +
        // '' +
        '' +
        '';
    const adphotoHardcode129 =
        '' +
        "<div id='div-gpt-ad-1646034692019-0' style='min-width: 970px; min-height: 90px;'>" +
        '<script>' +
        ' window.googletag = window.googletag || {cmd: []}; ' +
        'googletag.cmd.push(function() { ' +
        "googletag.defineSlot('/22678428348/SDPL_Trending_Desktop_970X90', [970, 90], 'div-gpt-ad-1646034692019-0').addService(googletag.pubads());  " +
        'googletag.pubads().enableSingleRequest(); ' +
        'googletag.pubads().disableInitialLoad();' +
        ' googletag.enableServices(); ' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        " googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646034692019-0'); });  " +
        '</script>' +
        '</div>' +
        // '<div id="gpt-passback">'+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_970x90', [970, 90], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        // '' +
        // '<script async="async" src="https://cdn.unibots.in/headerbidding/adScriptSandesh_new.js">' +
        // ' </script>' +
        // '' +
        '' +
        '';


    return (
        <>
            <section className="ad-2">
                <div className="container">
                    <div className="row ad-side-head text-center">
                        <div className="col-md-12  ad-side">
                            {/* {gujratafter?.[0]?.script &&  */}
                            <div className="ad-section">
                                {/* <HelperScript argSmall={224} argLarge={129} /> */}
                                <InjectScript script={window.innerWidth > 991 ? adphotoHardcode129 : adphotoHardcode224} />
                            </div>
                            {/* } */}
                            {/* <img style={{ height:'270px'}} src={fullad} alt="" /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AdTwo_photo

