/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
// import { childmenuFetchData } from '../../redux/actions/childmenuAction';

const MenuPage = () => {
  const { 28: Facebook } = useSelector((state) => state?.SettingReducer?.data);
  const { 29: Twitter } = useSelector((state) => state?.SettingReducer?.data);
  const { 30: Instagram } = useSelector((state) => state?.SettingReducer?.data);
  const { 31: Youtube } = useSelector((state) => state?.SettingReducer?.data);
  const { 24: Android } = useSelector((state) => state?.SettingReducer?.data);
  const { 25: Apple } = useSelector((state) => state?.SettingReducer?.data);
  const { 46: HeaderGIFMenus } = useSelector(
    (state) => state?.SettingReducer?.data
  );

  const Childmenu = useSelector((state) => state?.ChildmenuReducer);
  const homePrimaryLatest = useSelector((state) => state?.HomePrimaryReducer?.data?.Latest);
  const marquee = useSelector((state) => state?.MarqueeReducer?.data);
  const navigate = useNavigate();
  const menuList = useSelector((state) => state?.MenuReducer);

  // console.log("headerGIF", headerGIF)
  // const dispatch = useDispatch();
  // useEffect(() => {
  //     const timerId = setInterval(() => {
  //         dispatch(childmenuFetchData());
  //     }, 10000);
  //     return function cleanup() {
  //         clearInterval(timerId);
  //     };
  // }, []);

  const [menu, setMenuItems] = React.useState("");
  const [headerGIF, setHeaderGIF] = useState([]);
  useEffect(() => {
    setMenuItems(menuList.data);
    setHeaderGIF(
      HeaderGIFMenus?.value ? JSON.parse(HeaderGIFMenus?.value) : ""
    );
  }, [menuList]);
  // console.log("headerGIF", headerGIF)

  const marqList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
  return (
    <>
      <section className="menu-page">
        <div className="keywords">
          <div className="container" style={{ padding: '0 30px' }}>
            <div className="row">
              {headerGIF?.length >= 0 ? (
                <>
                  <div className="col-md-7 col-lg-7 col-xl-7 menu-page-fix">
                    <div className="breaking-headline">
                      <div className="row">
                        <div className="col-lg-2 name">News Flash</div>
                        <div className="col-lg-10 headline p-0">
                          <a>
                            { }
                            <marquee
                              class="marq"
                              loop={true}>

                              {marqList.map(() => {
                                return (marquee && marquee?.length > 0) ? marquee?.map((v) => {
                                  return (
                                    <>
                                      <li>
                                        <span className='cursor-pointer marquee-link' onClick={() => navigate(`/${v?.url}`)}>
                                          {v?.title}
                                        </span>
                                      </li>
                                      &nbsp; | &nbsp;
                                    </>
                                  )
                                }) : ''
                              })}


                              { }
                            </marquee>
                          </a>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div className="col-lg-5 col-xl-5 p-0">
                    <div className="icons" style={{ textAlign: "end" }}>
                      <ul>

                        {headerGIF?.length > 0 &&
                          headerGIF.map((el) => {
                            return (
                              <li>
                                <a  
                                // target='_blank'
                                  href={el?.url}
                                  onClickCapture={() =>
                                    ReactGA.event({
                                      category: "Header",
                                      action: "Icon",
                                      label: "Rathyatra",
                                    })
                                  }
                                  className="menu-page-social-gif-rathyatra"
                                >
                                  <img
                                    style={{
                                      width: "230px",
                                      height: "25px",
                                    }}
                                    src={el?.web_img}
                                    alt=""
                                  />
                                </a>
                              </li>
                            );
                          })}

                        {/* <li><a target="_blank" className='menu-page-social-icon' href={Android?.value} rel="noreferrer"><i className="fa-size-footer fab fa-android"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Apple?.value} rel="noreferrer"><i className="fa-size-footer fab fa-apple"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Facebook?.value} rel="noreferrer"><i className="fab fa-facebook"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Twitter?.value} rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Instagram?.value} rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Youtube?.value} rel="noreferrer"><i className="fab fa-youtube"></i></a></li> */}

                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Android",
                              })
                            }
                            className="menu-page-social-icon-android"
                            href={Android?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-android"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Apple",
                              })
                            }
                            className="menu-page-social-icon-apple"
                            href={Apple?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-apple"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Whatsapp",
                              })
                            }
                            className="menu-page-social-icon-whatsapp"
                            href="https://chat.whatsapp.com/DrLl02j2JZTJmnRCCmPumD"
                            rel="noreferrer"
                          >
                            <i class="fab fa-whatsapp"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Facebook",
                              })
                            }
                            className="menu-page-social-icon-facebook"
                            href={Facebook?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Twitter",
                              })
                            }
                            className="menu-page-social-icon-twitter"
                            href={Twitter?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Instagram",
                              })
                            }
                            className="menu-page-social-icon-instagram"
                            href={Instagram?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Youtube",
                              })
                            }
                            className="menu-page-social-icon-yt"
                            href={Youtube?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="time" id="demo">{thisDay}, {thisMonth} {thisDate}, {thisYear} <b className="digi-clock">{thisTime}</b> </div> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-9 col-lg-8 col-xl-9 menu-page-fix">
                    { }
                  </div>
                  <div className="col-lg-4 col-xl-3">
                    <div className="icons" style={{ textAlign: "end" }}>
                      <ul>
                        {/* <li>
                                            <Link to="gujarat/rathyatra-dummy-rathyatra-damirathyatra-damirathyatrarathyatrarathyatra" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Rathyatra' })} className='menu-page-social-gif-rathyatra'>
                                                <img style={{ width: '190px' }} src={require('../../assets/2-gif-2022.gif')} alt="" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/gujarat/rathyatra-dummy-rathyatra-damirathyatra-damirathyatrarathyatrarathyatra" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Rathyatra' })} className='menu-page-social-gif-rathyatra'>
                                                <img style={{ width: '190px' }} src={require('../../assets/1-gif-2022.gif')} alt="" />
                                            </Link>
                                        </li> */}
                        {headerGIF?.length > 0 &&
                          headerGIF.map((el) => {
                            return (
                              <li>
                                <Link
                                  to={el?.url}
                                  onClickCapture={() =>
                                    ReactGA.event({
                                      category: "Header",
                                      action: "Icon",
                                      label: "Rathyatra",
                                    })
                                  }
                                  className="menu-page-social-gif-rathyatra"
                                >
                                  <img
                                    style={{
                                      width: "230px",
                                      height: "25px",
                                    }}
                                    src={el?.web_img}
                                    alt=""
                                  />
                                </Link>
                              </li>
                            );
                          })}

                        {/* <li><a target="_blank" className='menu-page-social-icon' href={Android?.value} rel="noreferrer"><i className="fa-size-footer fab fa-android"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Apple?.value} rel="noreferrer"><i className="fa-size-footer fab fa-apple"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Facebook?.value} rel="noreferrer"><i className="fab fa-facebook"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Twitter?.value} rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Instagram?.value} rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                                        <li><a target="_blank" className='menu-page-social-icon' href={Youtube?.value} rel="noreferrer"><i className="fab fa-youtube"></i></a></li> */}

                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Android",
                              })
                            }
                            className="menu-page-social-icon-android"
                            href={Android?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-android"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Apple",
                              })
                            }
                            className="menu-page-social-icon-apple"
                            href={Apple?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-apple"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Whatsapp",
                              })
                            }
                            className="menu-page-social-icon-whatsapp"
                            href="https://chat.whatsapp.com/DrLl02j2JZTJmnRCCmPumD"
                            rel="noreferrer"
                          >
                            <i class="fab fa-whatsapp"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Facebook",
                              })
                            }
                            className="menu-page-social-icon-facebook"
                            href={Facebook?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Twitter",
                              })
                            }
                            className="menu-page-social-icon-twitter"
                            href={Twitter?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Instagram",
                              })
                            }
                            className="menu-page-social-icon-instagram"
                            href={Instagram?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Icon",
                                label: "Youtube",
                              })
                            }
                            className="menu-page-social-icon-yt"
                            href={Youtube?.value}
                            rel="noreferrer"
                          >
                            <i className="fa-size-footer fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="time" id="demo">{thisDay}, {thisMonth} {thisDate}, {thisYear} <b className="digi-clock">{thisTime}</b> </div> */}
                  </div>
                </>
              )}
              {/* <div className="col-md-9 col-lg-1 col-xl-3 menu-page-fix">
                                <ul>
                                    {
                                        Childmenu?.data?.length > 0 && Childmenu?.data?.map((els, idx) => (
                                            <li onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Tags', label: els?.tag?.name })}><Link className="menu-page-social-icons menu-page-link-hover" to={`/tag/${els?.tag?.url}`} >{els?.tag?.name}</Link></li>
                                            // <li className="menu-page-social-icons">{els?.tag?.name}</li>
                                        ))}
                                </ul>
                            </div> 
                                        {headerGIF?.length > 0 &&
                                            headerGIF.map((el) => {
                                                return (
                                                <li>
                                                    <Link
                                                    to={el?.url}
                                                    onClickCapture={() =>
                                                        ReactGA.event({
                                                        category: "Header",
                                                        action: "Icon",
                                                        label: "Rathyatra",
                                                        })
                                                    }
                                                    className="menu-page-social-gif-rathyatra"
                                                    >
                                                    <img
                                                        style={{ width: "230px", height: "25px" }}
                                                        src={el?.web_img}
                                                        alt=""
                                                    />
                                                    </Link>
                                                </li>
                                                );
                                            })}
                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Android' })} className='menu-page-social-icon-android' href={Android?.value} rel="noreferrer"><i className="fa-size-footer fab fa-android"></i></a></li>
                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Apple' })} className='menu-page-social-icon-apple' href={Apple?.value} rel="noreferrer"><i className="fa-size-footer fab fa-apple"></i></a></li>
                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Whatsapp' })} className='menu-page-social-icon-whatsapp' href="https://chat.whatsapp.com/DrLl02j2JZTJmnRCCmPumD" rel="noreferrer"><i class="fab fa-whatsapp"></i></a></li>
                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Facebook' })} className='menu-page-social-icon-facebook' href={Facebook?.value} rel="noreferrer"><i className="fa-size-footer fab fa-facebook"></i></a></li>
                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Twitter' })} className='menu-page-social-icon-twitter' href={Twitter?.value} rel="noreferrer"><i className="fa-size-footer fab fa-twitter"></i></a></li>
                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Instagram' })} className='menu-page-social-icon-instagram' href={Instagram?.value} rel="noreferrer"><i className="fa-size-footer fab fa-instagram"></i></a></li>
                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Youtube' })} className='menu-page-social-icon-yt' href={Youtube?.value} rel="noreferrer"><i className="fa-size-footer fab fa-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>*/}
            </div>
          </div>
        </div>
      </section>
      <section className="menu-page-mobile">
        <div className="keywords">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-md-10 menu-page-fix">
                <div className=" row col-lg-10 col-md-10 menu-page-fix">
                  <ul className="navbar-nav sub-category-page-menu">
                    <li className="nav-item">
                      <Link
                        to="/"
                        onClickCapture={() =>
                          ReactGA.event({
                            category: "Header",
                            action: "Tags",
                            label: "Home",
                          })
                        }
                      >
                        <i className="fas fa-home mr-1"></i>
                        {/* Home */}
                      </Link>
                    </li>
                    {menu &&
                      Object.values(menu).map((els, idx) => (
                        <>
                          {idx < 100 && (
                            <li
                              onClickCapture={() =>
                                ReactGA.event({
                                  category: "Header",
                                  action: "Tags",
                                  label: els.name,
                                })
                              }
                            >
                              {els?.open === 0 ? (
                                <Link to={`/${els?.category}`} className="">
                                  {els.name}
                                </Link>
                              ) : (
                                <a
                                  href={`${els?.url}`}
                                  target="_blank"
                                  className=""
                                  rel="noreferrer"
                                >
                                  {els.name}
                                </a>
                              )}
                            </li>
                          )}
                        </>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MenuPage;
