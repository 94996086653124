/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import camera from '../../assets/camera.png';
import Carousel from 'react-multi-carousel';
import HomeTaboola from '../subcomponents/HomeTaboola';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import { gallaryFetchData } from '../../redux/actions/gallary';
import { Helmet } from 'react-helmet';
import HelperScript from '../../utils/helper';
import InjectScript from '../CustomScript';

const Gallery = () => {
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);

    const gallary = useSelector((state) => state.GallaryReducer?.data);
    const mainIndexImage = gallary?.Latest?.[0]?.posts[0];
    const subIndexImage = gallary?.Latest?.[0]?.posts?.filter((els, index) => index > 0);
    const megaCategory = Object.entries(gallary);

    // const LatestMain = gallary?.Latest?.[0];
    // const LatestSub = gallary?.Latest?.filter((els, index) => index > 0);
    // const [homeGallaryDetails, setHomeGallaryDetails] = useState([]);
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(gallaryFetchData(5))
    }, [])
    // useEffect(() => {
    //     setHomeGallaryDetails(gallary)
    // }, [gallary])
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 1200, min: 991 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 991, min: 515 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 515, min: 0 },
            items: 1
        }
    };



    const taboolacontent = '' +
        '' +
        '<div id="taboola-right-rail-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-rr'," +
        "container: 'taboola-right-rail-thumbnails'," +
        "placement: 'Right Rail Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '';

    const aftereverycategory =
        '' +
        "<div id='div-gpt-ad-1657968349601-0' style='min-width: 300px; min-height: 250px;'>" +
        '<script>' +
        'window.googletag = window.googletag || { cmd: [] };' +
        'googletag.cmd.push(function () {' +
        "googletag.defineSlot('/22678428348/Mweb_Gallery_Aftereverycategory_300X250', [300, 250], 'div-gpt-ad-1657968349601-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '' +
        ' <script>' +
        "   googletag.cmd.push(function() { googletag.display('div-gpt-ad-1657968349601-0'); });" +
        '</script>' +
        '</div>' +
        '' +
        '';

        
    const mainpostafter =
    '' +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    " googletag.defineSlot('/22678428348/SDPL_Desktop_HP_belowphotogallery_970x66', [[970, 66], [970, 90]], 'div-gpt-ad-1672815373860-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.pubads().disableInitialLoad();'+
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    "<div id='div-gpt-ad-1672815373860-0' style='min-width: 970px; min-height: 66px;'> " +
    '' +
    ' <script>' +
    "    googletag.cmd.push(function() { googletag.display('div-gpt-ad-1672815373860-0'); }); " +
    '</script>' +
    '</div>' +
    '' +
    '';

        const gallery209 = " <div id='div-gpt-ad-1646388873872-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388873872-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388873872-0'); }); </script> </div>"
        const gallery106 = "<div id='div-gpt-ad-1646038804298-0' style='min-width: 300px; min-height: 600px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_Desktop_ATF', [300, 600], 'div-gpt-ad-1646038804298-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646038804298-0'); }); </script> </div>"
        const gallery210 = " <div id='div-gpt-ad-1646388896915-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646388896915-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script><script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388896915-0'); }); </script> </div>"
        const gallery107 = "<div id='div-gpt-ad-1646038840065-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_Desktop_BTF1', [300, 250], 'div-gpt-ad-1646038840065-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646038840065-0'); }); </script> </div>"
        const gallery211 = " <div id='div-gpt-ad-1646388921913-0' style='min-width: 300px; min-height: 250px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_Desktop_BTF2_300X250', [300, 250], 'div-gpt-ad-1646388921913-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388921913-0'); }); </script> </div>"
        const gallery109 = "<div id='div-gpt-ad-1646038870797-0' style='min-width: 300px; min-height: 250px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_Desktop_BTF2', [300, 250], 'div-gpt-ad-1646038870797-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646038870797-0'); }); </script> </div>"
        const gallery212 = " <div id='div-gpt-ad-1646388947093-0' style='min-width: 300px; min-height: 250px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Gallery_Desktop_BTF3_300X250', [300, 250], 'div-gpt-ad-1646388947093-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388947093-0'); }); </script> </div>"
        const gallery110 = " <div id='div-gpt-ad-1646038897630-0' style='min-width: 300px; min-height: 250px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_Gallery_Desktop_BTF3', [300, 250], 'div-gpt-ad-1646038897630-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646038897630-0'); }); </script> </div>"

        
        // const afterPrimary  = "" +
        // '<div id = "v-sandesh-v0"></div>'+
        // ''+
        // '<script>'+
        // '(function(v,d,o,ai){'+
        // "ai=d.createElement('script');"+
        // 'ai.defer=true;'+
        // 'ai.async=true;'+
        // 'ai.src=v.location.protocol+o;'+
        // 'd.head.appendChild(ai);'+
        // '})'+
        // "(window, document, '//a.vdo.ai/core/v-sandesh-v0/vdo.ai.js');"+
        // "</script>"+
        // ''    
        const afterPrimary  = "" +
        '<script defer id="vidcrunchJS632259138" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_ICF_Responsive_Playlist_190922.js">'+
        '</script>'+
        ''    

    return (
        <>
            <Helmet>
                <title>Gujarat Gallery | Gallery of Gujarat | Sandesh</title>
            </Helmet>
            <section className='gallery-component'>
                <div className='container'>
                    <div className='row my-3'>
                        <div className='col-md-9 gujarat-responsive-space-sm rounded'>
                            {/* {gallary?.India?.[0]?.posts?.length > 0 && gallary?.India?.[0]?.posts?.map((els, idx) => ( */}
                            {mainIndexImage &&
                                <div className="shadow mb-3 bg-gray rounded">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Link to={`/${mainIndexImage?.url}`} className="story">
                                                <div className="caption">
                                                    <img className="gallery-comp-top-img new_gallery_img" src={`${assetbaseUrl?.value}${mainIndexImage?.media}`} alt="" />
                                                    <img className="gallery-comp-top-img new_gallery_img_sub" src={`${assetbaseUrl?.value}${mainIndexImage?.media}`} alt="" />
                                                    {/* {mainIndexImage?.tagline !== null ? <p className="button-hover colorspan-top-post-gallery">{mainIndexImage?.tagline}</p> : '' } */}

                                                    <div className="gallery-main-post-title">
                                                        <div className="row px-3">
                                                            <div className="mr-3 bg-color-camera">
                                                                <img className="camera-size mr-1" src={camera} alt="" />{mainIndexImage?.title} | {mainIndexImage?.count} Images
                                                            </div>
                                                            {/* <div>
                                                                <p className=" right-side-border text-white m-0 "> </p> <span className="ml-2"></span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            }


                            <Carousel
                                swipeable={true}
                                draggable={false}
                                infinite={false}
                                showDots={false}
                                responsive={responsive}
                                autoPlaySpeed={5000000}
                                transitionDuration={10}
                                renderArrowsWhenDisabled={true}
                                ssr={true}
                                customTransition="transform 500ms ease"
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                className="gallery-sec"
                            >
                                {
                                    subIndexImage?.length > 0 && subIndexImage?.map((els, idx) => (
                                        <div className="single-blog mx-2">
                                            <div className="shadow mb-3 bg-gray rounded">

                                                <Link to={`/${els?.url}`} className="main-blogs">
                                                    <img src={`${assetbaseUrl?.value}${els?.media}`} className="gallery-comp-sub-img img-responsive new_gallery_img " alt="" />
                                                    <img src={`${assetbaseUrl?.value}${els?.media}`} className="gallery-comp-sub-img img-responsive new_gallery_img_sub " alt="" />
                                                    {/* {els?.tagline !== null ? <span className="button-hover colorspan-top-trending">{els?.tagline}</span> : ''} */}
                                                    <div className="bgcolor-div row">
                                                        <div className="row px-3">
                                                            <div className="mr-3">
                                                                <img className="sub-camera-img" src={camera} alt="" />
                                                            </div>
                                                            <div>
                                                                <p className=" text-white m-0">{els?.count} Images </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="blog-details">
                                                        {/* <span><b className="color-red">{els?.tagline}</b></span> */}
                                                        <p className="ellipsis">{els?.title}</p>
                                                        <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                }

                            </Carousel>
                            {window.innerWidth > 991 ? <div className="text-center">
                                <InjectScript script={mainpostafter} key={mainpostafter} />
                            </div> : ''}
                            {window.innerWidth < 991 ? <div className="text-center">
                                <InjectScript script={aftereverycategory} key={aftereverycategory} />
                            </div> : ''}
                            <InjectScript script={afterPrimary} />
                            {
                                megaCategory?.length > 0 && megaCategory?.map((els, idx) => (
                                    (els?.[0] !== 'Latest') ?
                                        <div className="megaList row">
                                            <div className='col-md-12 gujarat-responsive-space-sm rounded'>
                                                <span className="badge badge-info">{els?.[1]?.[0]?.name}</span>
                                                <Link to={`/${els?.[1]?.[0]?.url}`} className="readmore">Read More</Link>
                                                <div className='row'>
                                                    {
                                                        els?.[1]?.[0]?.posts?.length > 0 && els?.[1]?.[0]?.posts?.map((els, idx) => (
                                                            <div className='col-md-6 col-lg-4 mb-2 p-0'>
                                                                <div className="single-blog mx-2">
                                                                    <div className="shadow mb-3 bg-gray rounded">
                                                                        <Link to={`/${els?.url}`} className="main-blogs">
                                                                            <img src={`${assetbaseUrl?.value}${els?.media}`} className=" gallery-comp-sub-img img-responsive new_gallery_img" alt="" />
                                                                            <img src={`${assetbaseUrl?.value}${els?.media}`} className=" gallery-comp-sub-img img-responsive new_gallery_img_sub" alt="" />
                                                                            {/* <span className="button-hover colorspan-top-trending">એન્ટરટેઇનમેન્ટ</span> */}
                                                                            <div className="entertainment-bgcolor-div row">
                                                                                <div className="row px-3">
                                                                                    <div className="mr-3">
                                                                                        <img className="sub-camera-img" src={camera} alt="" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className=" text-white m-0">{els?.count} Images </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="blog-details">
                                                                                {/* {els?.tagline !== null ? <span><b className="color-red">{els?.tagline}</b></span> : ''} */}
                                                                                <p className="ellipsis">{els?.title}</p>
                                                                                <span className="blog-time"><i className="far fa-clock"></i>{els.publish_date}</span>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                ))
                            }

                            <div className='row ExtraTaboola'>
                                {
                                    (window.innerWidth > 991) ?
                                        <HomeTaboola key={window.location.href} />
                                        : ''
                                }
                            </div>
                        </div>
                        <div className='col-md-3 mt-4 text-center'>

                            <div className="ad-section">
                                <HelperScript argSmall={209} argLarge={106} />
                                {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={gallery209} />
                                    :
                                    <InjectScript script={gallery106} />
                                } */}
                            </div>


                            <div className="ad-section">
                                <HelperScript argSmall={210} argLarge={107} />
                                {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={gallery210} />
                                    :
                                    <InjectScript script={gallery107} />
                                } */}
                            </div>




                            <div className="ad-section">
                                <HelperScript argSmall={211} argLarge={109} />
                                {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={gallery211} />
                                    :
                                    <InjectScript script={gallery109} />
                                } */}
                            </div>


                            <div className="ad-section">
                                <HelperScript argSmall={212} argLarge={110} />
                                {/* {
                                    window.innerWidth < 991 ?
                                    <InjectScript script={gallery212} />
                                    :
                                    <InjectScript script={gallery110} />
                                } */}
                            </div>

                            <div className='my-4 sidebar-taboola' key={window.location.pathname}>
                                <InjectScript type="taboola" script={taboolacontent} key={window.location.pathname} />
                                <div className='ad-sticky-top'>
                                    {/* <HelperScript argLarge={261} /> */}
                                </div>
                            </div>

                        </div>
                    </div>


                    {
                        (window.innerWidth < 991) ?
                            <HomeTaboola key={window.location.href} />
                            : ''
                    }
                </div>
            </section>
        </>
    )
}

export default Gallery
